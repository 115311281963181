import Typography from '@mui/material/Typography'

export const ValueWithHeader = (props: {
  header: string
  value: React.ReactNode
}) => {
  return (
    <>
      <Typography fontWeight="bold">{props.header}</Typography>
      <Typography sx={{ paddingLeft: '10px' }}>{props.value}</Typography>
    </>
  )
}

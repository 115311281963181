import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export type ConfirmationDialogProps = DialogProps & {
  id?: string
  keepMounted?: boolean
  title?: string
  open: boolean
  confirmationContent?: React.ReactNode
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { onClose, onConfirm, confirmationContent, title, open, ...other } =
    props

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      {props.title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>
        {confirmationContent
          ? confirmationContent
          : "Are you sure you'd like to perform this action?"}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

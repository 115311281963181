import { IncidentDisplay } from '.'
import {
  ActionTaken,
  District,
  Incident,
  LearnedFromSource,
  TriggerEvent,
} from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'
import { SelectOption } from '../../forms/select-with-label'

type Props = {
  incident: Incident
  type: 'Add' | 'Edit'
}
const serverUrl = process.env.REACT_APP_SERVER_URL

export function IncidentEdit(props: Props) {
  const { response: riskResponse } = useFetch(
    `${serverUrl}/RiskLevel/GetLookup`
  )
  const riskOptions = (riskResponse ?? []) as SelectOption<number>[]

  const { response: acts } = useFetch(`${serverUrl}/ActionTaken/GetLookup`)
  const actOptions = (acts ?? []) as ActionTaken[]

  const { response: events } = useFetch(`${serverUrl}/TriggerEvent/GetLookup`)
  const eventOptions = (events ?? []) as TriggerEvent[]

  const { response: srcs } = useFetch(
    `${serverUrl}/LearnedFromSource/GetLookup`
  )
  const srcOptions = (srcs ?? []) as LearnedFromSource[]

  const districtid = props.incident.user?.districtID ?? 0
  const { response: users, isLoading } = useFetch(
    `${serverUrl}/User/UsersByDistrict?districtID=${districtid}`
  )

  let userOptions = (users ?? []) as SelectOption<number>[]
  if (userOptions.filter((o) => o.id === 0).length === 0)
    userOptions.push({ id: 0, name: 'Other' })
  const userOpts = userOptions.map((o) => {
    return { id: o.id.toString(), name: o.name }
  })

    const { response: responderRoles } = useFetch(
    `${serverUrl}/InitialResponderRole/GetLookup`
  )
  

  const roleOptions = (responderRoles ?? []) as SelectOption<number>[]
  const initialResponderRoles = roleOptions.map((o) => {
    return { id: o.id.toString(), name: o.name }
  })
  if (initialResponderRoles.filter((o) => o.id === '0').length === 0)
  initialResponderRoles.push({ id: '0', name: 'Other' })



  const { response: accommodations } = useFetch(
    `${serverUrl}/Accommodation/GetLookup`
  )
  const accommodationOptions = (accommodations ?? []) as SelectOption<number>[]

  let schoolURL = 'Incident/GetSchools'
  if (props.type === 'Edit') schoolURL += `?id=${props.incident.id}`

  const { response: schools } = useFetch(`${serverUrl}/${schoolURL}`)
  const schoolOptions = (schools ?? []) as SelectOption<number>[]

  let integrationEnabled = false
  const { response: district } = useFetch(
    `${serverUrl}/District/GetById?id=${districtid}`
  )
  if (district != null) {
    const d = district as unknown as District
    integrationEnabled = d.integrationEnabled && d.useIntegratedStudentData
  }

  return (
    <IncidentDisplay
      type={props.type}
      incident={props.incident}
      actionItems={actOptions}
      accommodationOptions={accommodationOptions}
      l2ResponderOptions={userOpts}
      initialResponderRoleOptions={initialResponderRoles}
      riskLevelOptions={riskOptions}
      sourceItems={srcOptions}
      triggerEvents={eventOptions}
      integrationEnabled={integrationEnabled}
      schoolOptions={schoolOptions}
    />
  )
}

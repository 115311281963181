// import { Box } from "@material-ui/core";
import AppBar from '@mui/material/AppBar'
import Link from '@mui/material/Link'
import { QuestionInCircle } from './questionInCircle'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import { Accessibe } from './accessibe'

export const Footer = () => {
  const theYear = new Date().getFullYear()

  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        top: 'auto',
        height: 30,
        bottom: 0,
        alignItems: 'center',
        background: 'white',
      }}
    >
      <Toolbar
        disableGutters
        sx={{ minHeight: 30, height: 30 }}
        variant="dense"
      >
        <Box id="copyright">© Copyright {theYear} Ottawa Area ISD</Box>
        <Link sx={{ pl: 3 }} href={'mailto:misafestudent@oaisd.org'}>
          <QuestionInCircle color="green" size={15} />
          Support
        </Link>
        <Accessibe />
      </Toolbar>
    </AppBar>
  )
}

import { Typography, Dialog, Tabs, Tab } from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { IncidentView } from '../../components/incident/detailsDisplay/detail-display'
import { SafetyPlanList } from '../../components/incident/safety-plan'
import { FollowUpGrid } from '../../components/incident/follow-up'
import { TabPanel } from '../../components/tab-panel'
import { Incident } from '../../core/types'
import { useAuth } from '../../hooks/use-auth'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import { IncidentHistory } from './history'

export function ViewIncident() {
  const { state } = useLocation()
  const { user, loading } = useAuth()
  const [error, setError] = useState('')
  const [recentlyCreated] = useState(true) //state?.updated ?? false)
  const [page, setPage] = useState(state?.page ?? 0)
  const [canRequestSharing, setCanRequestSharing] = useState(false)

  const { id } = useParams()
  const includes =
    'Student,School,School.District,User,RiskLevel,TriggerEvents,ActionsTaken,FollowUps,Accommodation,L2Responder,LearnedFromSources'
  const { response } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/Get?id=${id}&include=${includes}`
  )
  const incident = response as Incident | null
  if (incident == null || loading) return <></>

  let showNotice = false
  if (
    recentlyCreated &&
    !incident.anonymousStudent &&
    incident.school &&
    incident.school.district
  ) {
    sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/Incident/CanRequestSharing?incidentID=${id}`
    ).then(async ({ response }) => {
      setCanRequestSharing(await response?.json())
    })

    let canShare =
      user?.id === incident.userID &&
      incident.school.district.privateIncidentAccess &&
      incident.school.district.allowIncidentSharing
    showNotice = canRequestSharing && canShare
  }

  incident.accommodationId = incident.accommodation?.id ?? 0
  incident.riskLevelId = incident.riskLevel?.id ?? 0
  incident.l2ResponderUserID = incident.l2Responder?.id ?? 0
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(newValue)
  }

  return (
    <Box sx={{ width: '75vw', minWidth: '650px' }}>
      <Typography variant="h4">
        Incident Details -{' '}
        {incident.anonymousStudent
          ? 'Anonymous Student'
          : `${incident.student?.firstName} ${incident.student?.lastName}`}
      </Typography>
      {error && <Typography>{error}</Typography>}

      {incident && (
        <Box sx={{ width: '100%' }}>
          {showNotice && (
            <Typography>
              This student has other incidents recorded for the same school
              district, select Incident History to Request Access to all student
              incidents
            </Typography>
          )}

          <Tabs
            value={page}
            onChange={handleChange}
            aria-label="incident tabs"
            sx={{
              borderBottom: '1px solid black',
              marginBottom: 1,
              paddingBottom: 0,
            }}
          >
            <Tab value={0} label="Details" />
            <Tab value={1} label="Safety Plan" />
            <Tab value={2} label="Follow Up" />
            <Tab value={3} label="Incident History" />
          </Tabs>
          <Box maxWidth={'100vw'} width="650px">
            <TabPanel value={page} index={0}>
              <IncidentView incident={incident} />
            </TabPanel>
            <TabPanel value={page} index={1}>
              <SafetyPlanList id={incident.id} />
            </TabPanel>
            <TabPanel value={page} index={2}>
              <FollowUpGrid followUps={incident.followUps} />
            </TabPanel>
            <TabPanel value={page} index={3}>
              <IncidentHistory id={incident.id} />
            </TabPanel>
          </Box>
        </Box>
      )}
    </Box>
  )
}

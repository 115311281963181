import CircularProgress from '@mui/material/CircularProgress'
import { dateTimeRenderer } from '../../components/paged-table/renderers'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../components/paged-table/simple-paged-table'
import { useAuth } from '../../hooks/use-auth'
import { useFetch } from '../../hooks/use-fetch'
type UserReportResult = {
  id: number
  schoolList: string
  district: string
  firstName: string
  lastName: string
  email: string
  roleID: string
  lastLoginDate: Date
  inactive: boolean
  functionList: string
}

type UserResults = Pick<
  UserReportResult,
  | 'id'
  | 'schoolList'
  | 'district'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'roleID'
  | 'lastLoginDate'
  | 'inactive'
  | 'functionList'
>
type UserColumn = SimpleColumn<UserResults>

const columns: UserColumn[] = [
  {
    field: 'district',
    headerName: 'District',
  },
  {
    field: 'schoolList',
    headerName: 'Schools',
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
  },
  {
    field: 'firstName',
    headerName: 'First Name',
  },
  {
    field: 'email',
    headerName: 'Email',
  },
  {
    field: 'roleID',
    headerName: 'Role',
  },
  {
    field: 'lastLoginDate',
    headerName: 'Last Login',
    renderField: dateTimeRenderer,
  },
  {
    field: 'inactive',
    headerName: 'Inactive',
  },
  {
    field: 'functionList',
    headerName: 'Function List',
  },
]

export function UserSettingsReport() {
  const { user } = useAuth()
  let path = `${process.env.REACT_APP_SERVER_URL}/User/Settings`
  if (user?.roleID === 'District Admin')
    path += `?districtId=${user.districtID}`

  const { response, isLoading, error } = useFetch(path)
  if (isLoading) return <CircularProgress />

  const rows = (response ?? []) as UserReportResult[]
  let i = 1
  const idRows = rows.map((r) => {
    return { ...r, id: i++ }
  })

  return (
    <SimplePagedTable
      columns={columns}
      rows={idRows}
      disableSearch
      enablePrint
    />
  )
}

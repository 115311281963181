import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { generatePath } from 'react-router'
import { District } from '../../core/types'
import { usePermissions } from '../../hooks/use-permissions'
import { DISTRICT_EDIT_SECURITY_OPTIONS_PATH } from '../../routes'
import { ValueWithHeader } from '../header-with-value'

type Props = {
  district: District
}
export const DataSecurity = (props: Props) => {
  const { district } = props
  const permissions = usePermissions()

  return (
    <Box sx={{ m: '1rem 0' }}>
      {permissions?.canEditDistrictsDataSecurity && (
        <Button
          variant="contained"
          href={generatePath(DISTRICT_EDIT_SECURITY_OPTIONS_PATH, {
            id: district.id.toString(),
          })}
          sx={{ marginBottom: '1rem' }}
        >
          Edit
        </Button>
      )}
      <ValueWithHeader
        header="Private Incident Access"
        value={district.privateIncidentAccess ? 'Yes' : 'No'}
      />
      <ValueWithHeader
        header="Enforce Anonymous Logging"
        value={district.enforceAnonymousLogging ? 'Yes' : 'No'}
      />

      {district.privateIncidentAccess && (
        <ValueWithHeader
          header="Allow Incident to be shared with permission"
          value={district.allowIncidentSharing ? 'Yes' : 'No'}
        />
      )}
    </Box>
  )
}

import { Box, Button, Checkbox, FormLabel, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { District, User } from '../core/types'
import { useAuth } from '../hooks/use-auth'
import { sendRequest } from '../hooks/use-fetch'

export function TermsAndConditions() {
  const nav = useNavigate()
  const { user } = useAuth()

  const save = async () => {
    if (user) {
      const updatedUser = {
        ...user,
        whenAgreedToTerms: new Date().toDateString(),
        functions: user.authorizedFunctions.map((f) => f.name),
      }
      const { authorizedFunctions, district, ...rest } = updatedUser
      const { response, success } = await sendRequest(
        `${process.env.REACT_APP_SERVER_URL}/User/Update`,
        {
          method: 'PUT',
          body: JSON.stringify(rest),
        }
      )
      if (success) {
        nav('/')
      }
    }
  }

  const [accepted, setAccepted] = useState(false)
  return (
    <Box>
      <Typography variant="h4" sx={{ pt: '10px' }}>
        Accept Invitation - {`${user?.firstName} ${user?.lastName}`}
      </Typography>
      <Typography variant="body1" sx={{ pt: '10px' }}>
        You have been invited to participate in the MISafeStudent application.
        Please review the Terms and Conditions, check the box that you agree to
        them and hit Accept.
      </Typography>
      <Typography variant="h5" sx={{ pt: '10px' }}>
        Terms And Conditions
      </Typography>
      <Typography variant="body2" sx={{ pt: '10px' }}>
        Mi SAFE Student System Usage. Please Read Carefully.{' '}
      </Typography>
      <Typography variant="body2" sx={{ pt: '10px' }}>
        The Mi SAFE Student is a software system provided to districts and
        supporting organizations. Data within the system is included for the
        purpose of enhancing suicide prevention via the School Blue Envelope
        program developed by Corewell Health. As such, the system contains
        sensitive information and care should be taken by users of the system to
        maintain their personal responsibilities for protecting any Personally
        Identifiable Information (PII), Personal Health Information (PHI),
        and/or information protected under the Family Educational Rights &
        Privacy Act (FERPA) or any other controlling regulations and statutes.{' '}
      </Typography>
      <Typography variant="body2" sx={{ pt: '10px' }}>
        ANYONE USING THIS SYSTEM FOUND TO BE IN NON-COMPLIANCE SHALL BE DENIED
        ACCESS.
      </Typography>
      <Typography variant="body2" sx={{ pt: '10px' }}>
        Unauthorized use of this system is prohibited. Clicking the button below
        certifies that you have read and agree to comply with this notice, you
        are an authorized user, and you will use this system for authorized
        purposes only. This software application may be monitored and
        information examined, recorded, copied, and used for authorized audit
        purposes. There is no presumption of privacy when using this software
        application.{' '}
      </Typography>
      <Typography variant="body2" sx={{ pt: '10px' }}>
        ANYONE USING THIS SYSTEM EXPRESSLY CONSENTS TO SUCH MONITORING.
      </Typography>
      <Box>
        <FormLabel>I have read and understand the Terms & Conditions</FormLabel>
        <Checkbox
          checked={accepted}
          onChange={(_e: any, checked: boolean) => setAccepted(checked)}
        />
      </Box>
      <Box>
        <Button onClick={save} disabled={!accepted} variant="contained">
          Accept
        </Button>
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { User } from '../core/types'
import { useAuth } from '../hooks/use-auth'
import { redirect } from 'react-router-dom'
import { Container } from '@mui/system'

type Props = {
  allow: (user: User) => boolean
  noContainer?: boolean
}

export const ProtectedRoute = (props: Props) => {
  const auth = useAuth()
  const nav = useNavigate()
  const route = useLocation()
  if (auth.loading) return <></>
  if (auth.user === null) {
    nav('/', { state: { returnUrl: route.pathname } })
  }
  let outlet = <Outlet />
  let cnt = outlet
  if (props.noContainer === undefined || !props.noContainer)
    cnt = outlet = <Container maxWidth={'lg'}>{outlet}</Container>
  return auth.user && props.allow(auth.user) ? cnt : <NotAllowed />
}

const NotAllowed = () => {
  return (
    <Box justifyContent="center" display={'flex'}>
      You are not authorized to access this page
    </Box>
  )
}

import { useParams } from 'react-router'
import { SafetyPlanDocument } from '../../components/incident/safety-plan/document'
import { useFetch } from '../../hooks/use-fetch'
import { Typography, Box } from '@mui/material'

const serverUrl = process.env.REACT_APP_SERVER_URL

export function AddSafetyPlanDocument() {
  const { id } = useParams()
  let num: number = parseInt(id ?? '0')
  const { response: studentNameResponse } = useFetch(
    `${serverUrl}/Incident/GetStudentName?incidentID=${num}`
  )
  const studentName = (studentNameResponse ?? {}) as { name: string }

  return (
    <Box>
      <Typography variant="h4">
        <>Add Safety Plan Document - {studentName.name}</>
      </Typography>
      <SafetyPlanDocument
        isEdit={false}
        plan={{
          incidentID: num,
          dateCompleted: new Date(),
          fileName: '',
          fileObject: null,
          id: 0,
          status: '',
          type: 'Upload',
          notes: '',
        }}
      />
    </Box>
  )
}

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Role, RoleInfo, School } from '../../../core/types'
import { useAuth } from '../../../hooks/use-auth'
import { sendRequest, useFetch } from '../../../hooks/use-fetch'
import { usePermissions } from '../../../hooks/use-permissions'
import * as AppRoutes from '../../../routes'
import { ConfirmationDialog } from '../confirmation-dialog'
import { FormActionButton } from '../form-action-button'
import { SelectOption, SelectWithLabel } from '../select-with-label'
import { TextFieldWithLabel } from '../text-field-with-label'
import { permittedRoleOptionsForCurrentUser } from './helpers'
import { UserFunctionCheckboxes } from './user-functions-checkboxes'
import { UserSchoolCheckboxes } from './user-school-checkboxes'

const serverUrl = process.env.REACT_APP_SERVER_URL
type UserFormFields = {
  id?: number
  firstName: string
  lastName: string
  email: string
  districtID: number | undefined
  roleID: Role | undefined
  inactive: boolean
  functions: string[]
  schools: { id: number; name: string }[]
  integrationEnabled: boolean
  picCode?: string
}

type Props =
  | { mode: 'add'; initialValues?: Partial<UserFormFields> }
  | {
      mode: 'edit'
      userToEdit: UserFormFields
    }
export const UserForm = (props: Props) => {
  const { user, login, impersonate } = useAuth()
  const perms = usePermissions()
  const { response: roleOptionResponse } = useFetch(
    `${serverUrl}/StaticObject/GetRoles`
  )

  const roleInfo = (roleOptionResponse ?? []) as RoleInfo[]

  const roleOptions = permittedRoleOptionsForCurrentUser(user, roleInfo)

  const initialState =
    props.mode === 'edit'
      ? props.userToEdit
      : {
          firstName: '',
          lastName: '',
          email: '',
          districtID: undefined,
          roleID: 'District User' as Role,
          functions:  ["IncidentManagement"],
          inactive: false,
          schools: [],
          integrationEnabled: false,
          ...props.initialValues,
        }
  const [formState, setFormState] = useState<UserFormFields>(initialState)

  const [formErrors, setFormErrors] = useState<string[] | undefined>()

  useEffect(() => {
    if (props.mode === 'edit') setFormState(props.userToEdit)
  }, [props])

  const { response: districtResponse } = useFetch(
    `${serverUrl}/District/GetLookup`
  )
  const districtOptions = (districtResponse ?? []) as SelectOption<number>[]


  const { response: schoolOptionResponse } = useFetch(
    `${serverUrl}/School/GetByDistrict${
      formState.districtID ? `?districtID=${formState.districtID}` : ''
    }`
  )
  const schoolOptions = (schoolOptionResponse ?? []) as School[]

  const handleRoleChange = (event: SelectChangeEvent<Role>) => {
    const roleID = event.target.value as Role
    const relevantRoleInfo = roleInfo.find((role) => role.id === roleID)
    setFormState({
      ...formState,
      roleID,
      functions: [...(relevantRoleInfo?.implicitFunctions ?? [])],
    })
  }

  const navigate = useNavigate()
  const onCancel = () => navigate(-1)

  const saveDisabled =
    !formState.firstName || !formState.lastName || !formState.email
  const onSave = async () => {
    setFormErrors(undefined)
    const url = `${serverUrl}/User/${
      props.mode === 'edit' ? 'Update' : 'Insert'
    }`

    const { response, success } = await sendRequest(url, {
      method: props.mode === 'edit' ? 'PUT' : 'POST',
      body: JSON.stringify(formState),
    })
    if (success) {
      navigate(-1)
    } else {
      const result = await response?.json()
      if (result) {
        const errors = (result as { errors: { [k: string]: string[] } }).errors
          ? Object.keys(result.errors)
              .map((k) => result.errors[k])
              .flat()
          : [result as string]

        setFormErrors(errors)
      }
    }
  }
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

  const onImpersonate = async () => {
    if (props.mode === 'edit' && props.userToEdit.id) {
      impersonate(props.userToEdit.id)
    }
  }

  const onDelete = async () => {
    if (props.mode === 'edit') {
      setFormErrors(undefined)
      const url = `${serverUrl}/User/Delete?id=${props.userToEdit.id}`

      const { response, success } = await sendRequest(url, { method: 'DELETE' })
      if (success) {
        setDeleteConfirmationOpen(false)
        navigate(AppRoutes.ADMIN_USER_LIST_PATH)
      } else {
        const result = await response?.json()
        result && setFormErrors([result].flatMap((er) => er))
      }
    }
  }

  const handleChange = (event: SelectChangeEvent<string | number>) =>
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })

  const onToggleAllSchoolCheckbox = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) =>
    setFormState({
      ...formState,
      schools: checked ? schoolOptions : [],
    })

  const onToggleSchoolCheckbox = (
    checked: boolean,
    opt: SelectOption<number>
  ) => {
    let newSchoolState = formState.schools
    if (checked) {
      newSchoolState.push(opt)
    } else {
      newSchoolState = formState.schools.filter(
        (school) => school.id !== opt.id
      )
    }
    setFormState({
      ...formState,
      schools: newSchoolState,
    })
  }

  const onToggleFunctionCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const value = event?.target.value
    let newFnState = formState.functions
    if (checked) {
      newFnState.push(value)
    } else {
      newFnState = formState.functions.filter((fn) => fn !== value)
    }
    setFormState({
      ...formState,
      functions: newFnState,
    })
  }

  return (
    <Box component="form">
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '360px' }}>
        <TextFieldWithLabel
          label="First Name"
          name="firstName"
          required
          onChange={handleChange}
          value={formState.firstName}
        />
        <TextFieldWithLabel
          label="Last Name"
          name="lastName"
          required
          onChange={handleChange}
          value={formState.lastName}
        />
        <TextFieldWithLabel
          label="Email"
          name="email"
          required
          onChange={handleChange}
          value={formState.email}
        />

        <SelectWithLabel
          label="Role"
          name="roleID"
          width="350px"
          value={formState.roleID}
          onChange={handleRoleChange}
          options={roleOptions}
          FormControlProps={{ sx: { margin: '10px 0', width: '100%' } }}
        />

        <TextFieldWithLabel
          label="PIC Code"
          name="picCode"
          onChange={handleChange}
          value={formState.picCode}
        />

        <SelectWithLabel
          label="District"
          name="districtID"
          value={formState.districtID}
          width="350px"
          onChange={handleChange}
          options={districtOptions}
          FormControlProps={{ sx: { margin: '10px 0' } }}
        />

        <UserSchoolCheckboxes
          selectedSchools={formState.schools}
          schoolOptions={schoolOptions}
          onToggleAllSchoolCheckbox={onToggleAllSchoolCheckbox}
          onToggleSchoolCheckbox={onToggleSchoolCheckbox}
        />

        <UserFunctionCheckboxes
          onChange={onToggleFunctionCheckbox}
          roleInfo={roleInfo}
          selectedFunctions={formState.functions}
          selectedRole={formState.roleID}
        />

        <FormControl>
          <FormLabel id="login-method-label">Login Method</FormLabel>
          <RadioGroup
            aria-labelledby="login-method-label"
            defaultValue="MiCredentials"
            name="integrationEnabled"
            value={formState.integrationEnabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFormState({
                ...formState,
                integrationEnabled: event.target.value === 'true',
              })
            }
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="MiCredentials"
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="MiLaunchPad"
            />
          </RadioGroup>
        </FormControl>

        <Box>
          <FormLabel htmlFor="inactive">Inactive</FormLabel>
          <Checkbox
            sx={{ justifyContent: 'left' }}
            id="inactive"
            value={formState.inactive}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFormState({
                ...formState,
                inactive: event.target.checked,
              })
            }}
          />
        </Box>
      </Box>

      {formErrors &&
        formErrors.map((err) => (
          <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
            {err}
          </li>
        ))}

      <Box sx={{ marginTop: '14px' }}>
        <FormActionButton
          onClick={onSave}
          variant="contained"
          disabled={saveDisabled}
        >
          Save
        </FormActionButton>
        <FormActionButton onClick={onCancel}>Cancel</FormActionButton>
        {perms?.canImpersonate && props.mode === 'edit' && (
          <FormActionButton onClick={onImpersonate}>
            Impersonate
          </FormActionButton>
        )}
        {props.mode === 'edit' && (
          <>
            <ConfirmationDialog
              open={deleteConfirmationOpen}
              id="delete-user-confirmation"
              keepMounted={false}
              onClose={() => setDeleteConfirmationOpen(false)}
              onConfirm={onDelete}
              confirmationContent="Are you sure you want to delete this user?"
            />
            <FormActionButton onClick={() => setDeleteConfirmationOpen(true)}>
              Delete
            </FormActionButton>
          </>
        )}
      </Box>
    </Box>
  )
}

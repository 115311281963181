export function getAge(incidentDate: Date, birthDate: Date) {
  let ageAsOfIncident = 0
  if (birthDate && incidentDate) {
    let bdate = new Date(birthDate)
    ageAsOfIncident = incidentDate.getFullYear() - bdate.getFullYear()
    var thisYearsBirthday = new Date(
      incidentDate.getFullYear(),
      bdate.getMonth(),
      bdate.getDate(),
      bdate.getHours(),
      bdate.getMinutes()
    )
    if (incidentDate < thisYearsBirthday) ageAsOfIncident--
  }
  if (ageAsOfIncident < 1) return null
  return ageAsOfIncident
}

import React, { useState } from 'react'
import { User } from '../core/types'
import { useNavigate } from 'react-router'
import { sendRequest } from './use-fetch'

export type AuthType = {
  loading: boolean
  user: User | null
  isImpersonating: () => boolean
  endImpersonation: () => Promise<void>
  login: () => Promise<void>
  logout: () => Promise<void>
}
export function useAuth() {
  const [user, setUser] = React.useState<User | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const nav = useNavigate()
  const login = React.useCallback(async () => {

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/User/CurrentUser`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    try {
      const data = await response.json()
      setUser(data)
      if (
        data?.whenAgreedToTerms === '' ||
        data?.whenAgreedToTerms === null ||
        data?.whenAgreedToTerms === undefined
      ) {
        nav('/Home/TermsAndConditions')
      }
    } catch (e) {
      setUser(null)
    } 
  }, [user])

  const impersonate = async (id: number) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/Account/ImpersonateUser`
    const { response, success } = await sendRequest(url, {
      body: JSON.stringify(id),
      credentials: 'include',
      method: 'POST',
    })
    nav('/')
    window.location.reload()
    login()
  }

  const endImpersonation = React.useCallback(async () => {
    await fetch(
      `${process.env.REACT_APP_SERVER_URL}/Account/EndImpersonation`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    nav('/')
    window.location.reload()
    await login()
  }, [])
  const isImpersonating = () => {
    return user?.isImpersonating ?? false
  }


  React.useEffect(() => {
    const fetchUser = async () => {
      setLoading(true)
      await login()
      setLoading(false)
    }

    if (user === null) {
      fetchUser()
    }
  }, [user, login])

  return {
    loading,
    user,
    login,
    logout: async () => {
      setLoading(true)
      await fetch(`${process.env.REACT_APP_SERVER_URL}/Account/Logout`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      nav('/')
      window.location.reload()

      new Promise(() => {
        setUser(null)
        setLoading(false)
      })
    },
    impersonate,
    endImpersonation,
    isImpersonating,
  }
}

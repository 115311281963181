import { Typography } from '@mui/material'
import { InitialResponderRoleForm } from '../../../components/forms/add-edit-responder-role'

export const AddResponderRole = () => {
  return (
    <div>
      <Typography variant="h2">Add Initial Responder Role</Typography>

      <InitialResponderRoleForm mode="add" />
    </div>
  )
}
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { TrainingForm } from '../../components/training'
import { Training } from '../../core/types'
import { useAuth } from '../../hooks/use-auth'

export function AddTraining() {
  const [error, setError] = useState('')
  const auth = useAuth()
  if (!auth.user) return <></>

  return (
    <>
      <Typography variant="h4">Add Training Event</Typography>
      {error && <Typography>{error}</Typography>}

      <Box sx={{ width: '100%' }}>
        <TrainingForm mode={'add'} />
      </Box>
    </>
  )
}

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { type } from 'os'
import { useState } from 'react'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import { CheckList } from '../check-list'
import { DatePickerWithLabel } from '../forms/date-picker-with-label'
import { FormActionButton } from '../forms/form-action-button'
import { SelectOption, SelectWithLabel } from '../forms/select-with-label'

type CriteriaFields = {
  startDate: Date
  endDate: Date
  isdID?: number
  districtID?: number
  countyID?: number
  grades: SelectOption<number>[]
}

type Props = {
  districts: SelectOption<number>[]
}

export function IncidentExportView(props: Props) {
  const [district, setDistrict] = useState(props.districts)

  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    isdid: 0,
    districtID: 0,
    countyID: 0,
    grades: [],
  } as CriteriaFields)

  const [data, setData] = useState<CriteriaFields[]>([] as CriteriaFields[])
  const serverUrl = process.env.REACT_APP_SERVER_URL

  const { response: isds, isLoading: isdLoading } = useFetch(
    `${serverUrl}/ISD/GetLookup`
  )
  const isdOptions = (isds ?? []) as SelectOption<number>[]

  const { response: counties, isLoading: countyLoading } = useFetch(
    `${serverUrl}/County/GetLookup`
  )
  const countyOptions = (counties ?? []) as SelectOption<number>[]

  const gradeOptions = [{ id: 0, name: 'KG' }]
  for (let x = 1; x <= 12; x++) {
    gradeOptions[x] = Object.assign({
      id: x,
      name: x,
    })
  }
  gradeOptions.push({id:14, name:'14'})

  function setDate(value: Date | null, type: string) {
    setFilters({ ...filters, [type]: value })
  }

  function setNumber(value: number | null, type: string) {
    setFilters({ ...filters, [type]: value })
  }

  const setISD = async (value: number) => {
    setFilters({ ...filters, isdID: value })
    const { response, success } = await sendRequest(
      `${serverUrl}/District/GetByISD?isdID=${value}`
    )
    if (success) {
      let responseJson = await response?.json()
      let districts = (responseJson ?? []) as SelectOption<number>[]
      setDistrict(districts)
    }
  }

  function setCollection(value: SelectOption<number>[], type: string) {
    setFilters({ ...filters, [type]: value })
  }

  const onExport = async (data: CriteriaFields) => {
    //build up the parameters
    let querystring = `StartDate=${data.startDate
      .toISOString()
      .slice(0, 10)}&EndDate=${data.endDate.toISOString().slice(0, 10)}`
    if (data.isdID != null) {
      querystring += `&ISDID=${data.isdID}`
    }
    if (data.districtID != 0) {
      querystring += `&DistrictID=${data.districtID}`
    }
    if (data.countyID != 0) {
      querystring += `&CountyID=${data.countyID}`
    }
    let grades = ''
    data.grades.forEach((element) => {
      if (grades != '') grades += ','
      grades += element.id
    })
    if (grades == '') {
      grades = '-1'
    }
    querystring += `&Grade=${grades}`

    //call the endpoint and download the file
    const { response: resp } = await sendRequest(
      `${serverUrl}/Incident/IncidentExport?${querystring}`
    )
    let blob = await resp?.blob()
    if (blob !== undefined) {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'IncidentExport.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    }
  }

  return (
    <Box>
      <Typography variant="h2">Incident Export Report</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <DatePickerWithLabel
          label="Start Date"
          name="startDate"
          required
          onChange={(d) => setDate(d, 'startDate')}
          value={filters.startDate}
        />
        <DatePickerWithLabel
          label="End Date"
          name="endDate"
          required
          onChange={(d) => setDate(d, 'endDate')}
          value={filters.endDate}
        />
        <SelectWithLabel
          width="600px"
          options={isdOptions}
          value={filters.isdID}
          onChange={(e) => setISD(e.target.value as number)}
          name={'isdID'}
          label={'ISD'}
        />
        <SelectWithLabel
          options={district}
          width="600px"
          value={filters.districtID}
          onChange={(e) => setNumber(e.target.value as number, 'districtID')}
          name={'districtID'}
          label={'District'}
        />
        <SelectWithLabel
          options={countyOptions}
          width="600px"
          value={filters.countyID}
          onChange={(e) => setNumber(e.target.value as number, 'countyID')}
          name={'countyID'}
          label={'County'}
        />
        <CheckList
          flex
          allOption
          fullWidth
          items={gradeOptions}
          selectedItems={filters.grades}
          setSelectedItems={(newList: SelectOption<number>[]) =>
            setCollection(newList, 'grades')
          }
          label="Grades"
        />
      </Box>
      <FormActionButton
        onClick={async () => {
          await onExport(filters)
        }}
      >
        Preview
      </FormActionButton>
    </Box>
  )
}

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { School } from '../../../core/types'

export const UserSchoolCheckboxes = (props: {
  schoolOptions: School[]
  onToggleSchoolCheckbox: (checked: boolean, school: School) => void
  onToggleAllSchoolCheckbox: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  selectedSchools: Pick<School, 'id' | 'name'>[]
}) => {
  const {
    schoolOptions,
    onToggleAllSchoolCheckbox,
    onToggleSchoolCheckbox,
    selectedSchools,
  } = props

  return (
    <FormControl component="fieldset" sx={{ margin: '10px 0' }}>
      <FormLabel component="legend">School</FormLabel>
      <FormGroup>
        <FormControlLabel
          key="all"
          control={
            <Checkbox
              checked={schoolOptions.every(
                (sch) =>
                  !!selectedSchools.find((selected) => selected.id === sch.id)
              )}
              value="school"
              onChange={onToggleAllSchoolCheckbox}
            />
          }
          label="All"
        />
        {schoolOptions.map((opt) => {
          return (
            <FormControlLabel
              key={opt.id}
              control={
                <Checkbox
                  checked={!!selectedSchools.find((sch) => sch.id === opt.id)}
                  onChange={(_e, checked) =>
                    onToggleSchoolCheckbox(checked, opt)
                  }
                  value={opt.id}
                />
              }
              label={opt.name}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}

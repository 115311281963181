import { CircularProgress, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { TriggerEventForm } from '../../../components/forms/add-edit-triggerevent'
import { TriggerEvent } from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'

export const EditTriggerEvent = () => {
    const { id } = useParams()

    const { response, isLoading } = useFetch(        
      `${process.env.REACT_APP_SERVER_URL}/TriggerEvent/Get?id=${id}`
    )
    const action = (response ?? {}) as TriggerEvent
    if (isLoading)
    {
        return(<CircularProgress/>)
    }

  return (
    <div>
      <Typography variant="h2">Edit Action Taken</Typography>       

      <TriggerEventForm mode="edit" itemToEdit={action} />
    </div>
  )
}
import { Container } from '@mui/system'
import { RaceObject, Student } from '../../core/types'
import { CheckList } from '../check-list'
import { SelectOption } from '../forms/select-with-label'

type Props = {
  raceObject: RaceObject
  setRaceObject: (studentRace: RaceObject) => void
  displayRace?: boolean
}
export function getRaceDisplay(raceObject: RaceObject) {
  if (raceObject.hispanicOrLatino === 1) return 'Hispanic or Latino'
  else {
    let values: string[] = getArray(raceObject).map((r) => r.name)
    if (values.length > 1) return 'Multiple Races'
    else if (values.length == 0) return ''
    else return values[0]
  }
}
function getArray(raceObject: RaceObject) {
  let raceArray: SelectOption<number>[] = []
  if (raceObject.asian === 1) raceArray.push({ name: 'Asian', id: 2 })
  if (raceObject.white === 1) raceArray.push({ name: 'White', id: 5 })
  if (raceObject.africanAmericanOrBlack === 1)
    raceArray.push({ name: 'African American or Black', id: 3 })
  if (raceObject.americanIndianOrAlaskan === 1)
    raceArray.push({ id: 1, name: 'American Indian or Alaskan Native' })
  if (raceObject.nativeHawaiianOrPacificIslander === 1)
    raceArray.push({ name: 'Native Hawaiian or Pacific Islander', id: 4 })
  if (raceObject.hispanicOrLatino === 1)
    raceArray.push({ name: 'Hispanic or Latino', id: 6 })
  return raceArray
}
export function getRaceFromStudent(student: Student) {
  let raceObject: RaceObject = {
    asian: student.asian ? 1 : 0,
    africanAmericanOrBlack: student.africanAmericanOrBlack ? 1 : 0,
    americanIndianOrAlaskan: student.americanIndianOrAlaskan ? 1 : 0,
    hispanicOrLatino: student.hispanicOrLatino ? 1 : 0,
    nativeHawaiianOrPacificIslander: student.nativeHawaiianOrPacificIslander
      ? 1
      : 0,
    white: student.white ? 1 : 0,
  }

  return raceObject
}
export function RaceSelect(props: Props) {
  let raceArray: SelectOption<number>[] = getArray(props.raceObject)
  const raceDisplay = getRaceDisplay(props.raceObject)

  function setRace(newSelections: SelectOption<number>[]) {
    let selections = newSelections.map((s) => s.name)
    let newRaceObject: RaceObject = {
      asian: selections.includes('Asian') ? 1 : 0,
      americanIndianOrAlaskan: selections.includes(
        'American Indian or Alaskan Native'
      )
        ? 1
        : 0,
      africanAmericanOrBlack: selections.includes('African American or Black')
        ? 1
        : 0,
      nativeHawaiianOrPacificIslander: selections.includes(
        'Native Hawaiian or Pacific Islander'
      )
        ? 1
        : 0,
      white: selections.includes('White') ? 1 : 0,
      hispanicOrLatino: selections.includes('Hispanic or Latino') ? 1 : 0,
    }
    let raceDescriptor = getRaceDisplay(newRaceObject)

    newRaceObject.raceDescriptor = raceDescriptor

    props.setRaceObject(newRaceObject)
  }
  return (
    <>
      {props.displayRace && <Container>{raceDisplay}</Container>}

      <Container>Race - Select all that apply</Container>
      <CheckList
        items={[
          { id: 1, name: 'American Indian or Alaskan Native' },
          { name: 'Asian', id: 2 },
          { name: 'African American or Black', id: 3 },
          { name: 'Native Hawaiian or Pacific Islander', id: 4 },
          { name: 'White', id: 5 },
        ]}
        selectedItems={raceArray}
        setSelectedItems={setRace}
      />
      <Container>Ethnicity</Container>
      <CheckList
        items={[{ name: 'Hispanic or Latino', id: 6 }]}
        selectedItems={raceArray}
        setSelectedItems={setRace}
      />
    </>
  )
}

import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { IncidentEdit } from '../../components/incident/detailsDisplay/edit-display'
import { Incident } from '../../core/types'
import { useAuth } from '../../hooks/use-auth'

export function AddIncident() {
  const [error, setError] = useState('')
  const auth = useAuth()
  if (!auth.user) return <></>
  if ((auth.user.district?.id ?? 0) === 0)
    return (
      <Typography>
        User must be assigned to a district to enter an incident
      </Typography>
    )

  const incident: Incident = {
    ageAtTimeOfIncident: null,
    actionsTaken: [],
    anonymousStudent: false,
    dateOf: new Date(),
    followUps: [],
    grade: '',
    id: 0,
    studentID: 0,
    student: {
      id: 0,
      firstName: auth.user.district?.enforceAnonymousLogging ? 'Anonymous' : '',
      lastName: auth.user.district?.enforceAnonymousLogging ? 'Student' : '',
      dateOfBirth: '',
      uic: '',
      gender: auth.user.district?.enforceAnonymousLogging ? 'N' : '',
      americanIndianOrAlaskan: 0,
      asian: 0,
      africanAmericanOrBlack: 0,
      nativeHawaiianOrPacificIslander: 0,
      white: 0,
      hispanicOrLatino: 0,
      isAnonymous: auth.user.district?.enforceAnonymousLogging ?? false,
    },
    schoolID: 0,
    school: {},
    triggerEvents: [],
    otherTriggerEvent: '',
    otherActionTaken: '',
    learnedFromSources: [],
    otherLearnedFromSource: '',
    notes: '',
    user: auth.user ?? {},
    userID: 0,
    whenEntered: new Date(),
  }

  return (
    <Box>
      <Typography variant="h4">Report Incident</Typography>
      {error && <Typography>{error}</Typography>}

      <IncidentEdit incident={incident} type={'Add'} />
    </Box>
  )
}

import {
  Box,
  Button,
  Typography,
  Input,
  SelectChangeEvent,
  FormLabel,
} from '@mui/material'
import React, { useState } from 'react'
import { UploadSafetyPlan, YesNoUnknown } from '../../../core/types'
import { sendRequest, useFetch } from '../../../hooks/use-fetch'
import { DatePickerWithLabel } from '../../forms/date-picker-with-label'
import { SelectWithLabel } from '../../forms/select-with-label'
import { TextFieldWithLabel } from '../../forms/text-field-with-label'
import * as AppRoutes from '../../../routes'
import { generatePath, useNavigate } from 'react-router'
import Paper from '@mui/material/Paper'

const serverUrl = process.env.REACT_APP_SERVER_URL

type Props = {
  plan: UploadSafetyPlan
  isEdit: boolean
}

export function SafetyPlanDocument(props: Props) {
  const [form, setForm] = useState(props.plan)
  const [formError, setFormError] = useState<string | undefined>()
  const [file, setFile] = useState<File | undefined>()
  const yesNoUnknownOptions = [
    { id: 'false', name: YesNoUnknown[0] },
    { id: 'true', name: YesNoUnknown[1] },
    { id: '', name: YesNoUnknown[2] },
  ]

  const handleChange = (e: SelectChangeEvent<string | number>) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  const handleDayChanged = (newValue: Date | null) => {
    if (newValue) {
      setForm({ ...props.plan, dateCompleted: newValue })
    }
  }
  const fileInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0]
      setFile(file)
    }
  }
  const navigate = useNavigate()
  const onCancel = () => navigate(-1)
  const onDelete = async () => {
    const url = `${serverUrl}/SafetyPlanDocument/Delete?id=${form.id}`
    let id = form.incidentID.toString()
    const { response, success } = await sendRequest(url, {
      method: 'DELETE',
      body: JSON.stringify(form),
    })
    if (success) {
      navigate(
        generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
          id: id,
        })
      )
    } else {
      const result: string = await response?.json()
      result && setFormError(result)
    }
  }

  const onSave = async () => {
    if (!file) return
    const url = `${serverUrl}/SafetyPlanDocument/${
      props.isEdit ? 'Update' : 'UploadFile'
    }`
    let id = form.incidentID.toString()
    var data = new FormData()
    if (!props.isEdit) data.append('file', file)
    data.append('safetyPlan', JSON.stringify(form))
    const { response, success } = await sendRequest(url, {
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
      method: props.isEdit ? 'PUT' : 'POST',
      body: data,
    })
    if (success) {
      navigate(
        generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
          id: id,
        })
      )
    } else {
      const result: string = await response?.json()
      result && setFormError(result)
    }
  }

  return (
    <Box component="form">
      {formError && (
        <li style={{ color: 'red', padding: '10px 0' }}>{formError}</li>
      )}
      <Box maxWidth={'400px'}>
        <DatePickerWithLabel
          label="Date Completed"
          name="dateCompleted"
          value={form.dateCompleted}
          onChange={handleDayChanged}
        />
      </Box>
      {props.isEdit && (
        <Box maxWidth={'400px'}>
          <DownloadFile id={form.id} fileName={form.fileName} />
        </Box>
      )}
      {!props.isEdit && (
        <Box maxWidth={'400px'}>
          <Paper variant="outlined">
            <Input
              type="file"
              onChange={fileInputChanged}
              disabled={props.isEdit}
            />
          </Paper>
        </Box>
      )}

      {props.isEdit && (
        <TextFieldWithLabel
          label="Safety Plan Document"
          disabled
          name="fileName"
          value={form.fileName}
        />
      )}
      <TextFieldWithLabel
        label="Notes"
        name="notes"
        onChange={handleChange}
        value={form.notes ?? ''}
      />

      <SelectWithLabel
        label="Copy Kept At School"
        name="copyKeptAtSchool"
        width="200px"
        FormControlProps={{ fullWidth: true }}
        value={form.copyKeptAtSchool ? 'true' : 'false' ?? ''}
        onChange={handleChange}
        options={yesNoUnknownOptions}
      />
      <SelectWithLabel
        label="Given to Parent/Guardian"
        name="givenToGuardian"
        width="200px"
        FormControlProps={{ fullWidth: true }}
        value={form.givenToGuardian ? 'true' : 'false'}
        onChange={handleChange}
        options={yesNoUnknownOptions}
      />
      <SelectWithLabel
        label="Given to Student"
        name="givenToStudent"
        width="200px"
        value={form.givenToStudent ? 'true' : 'false' ?? ''}
        FormControlProps={{ fullWidth: true }}
        onChange={handleChange}
        options={yesNoUnknownOptions}
      />
      <Box sx={{ marginTop: '14px' }}>
        <Button onClick={onSave} variant="contained">
          Save
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        {props.isEdit && (
          <Button variant="outlined" onClick={onDelete}>
            Delete
          </Button>
        )}
      </Box>
    </Box>
  )
}

function DownloadFile(props: { id: number; fileName: string }) {
  const getFile = React.useCallback(async () => {
    const { response: resp } = await sendRequest(
      `${serverUrl}/SafetyPlanDocument/DownloadFile?id=${props.id}`
    )
    let blob = await resp?.blob()
    if (blob !== undefined) {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', props.fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    }
  }, [props])

  return (
    <Button onClick={getFile} variant="contained">
      Download File
    </Button>
  )
}

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Button, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { generatePath } from 'react-router-dom'
import {
  PagedTable,
  Props as PagedTableProps,
} from '../../../components/paged-table'
import { dateTimeRenderer } from '../../../components/paged-table/renderers'
import { User } from '../../../core/types'
import { usePermissions } from '../../../hooks/use-permissions'
import * as AppRoutes from '../../../routes'

type UserColumns = PagedTableProps<User>['columns']
const columns: UserColumns = [
  {
    field: 'lastName',
    headerName: 'Last Name',
    sortBy: 'LastName',
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    sortBy: 'FirstName',
  },
  { field: 'email', headerName: 'Email', sortBy: 'Email' },
  {
    field: 'district',
    headerName: 'District',
    sortBy: 'District.Name',
    renderField: (d) => d && d.name,
  },
  { field: 'roleID', headerName: 'Role', sortBy: 'Role.ID' },
  {
    field: 'lastLoginDate',
    headerName: 'Last Login',
    sortBy: 'LastLoginDate',
    renderField: dateTimeRenderer,
  },
  {
    field: 'whenAgreedToTerms',
    headerName: 'Accepted Agreement On',
    sortBy: 'WhenAgreedToTerms',
    renderField: dateTimeRenderer,
  },
  {
    field: 'inactive',
    headerName: 'Inactive',
    sortBy: 'Inactive',
    renderField: (inactive: boolean) =>
      inactive && <CloseIcon sx={{ color: 'red', paddingTop: '7px' }} />,
  },
]

export const UserManagement = () => {
  const permissions = usePermissions()

  return (
    <div>
      <Typography variant="h2">User Management</Typography>
      {permissions?.canAddEditUser && (
        <Box sx={{ paddingTop: '1rem' }}>
          <Button variant="contained" href={AppRoutes.ADMIN_USER_ADD_PATH}>
            Log New User
          </Button>
        </Box>
      )}
      <PagedTable
        columns={columns}
        queryFor="User"
        rowActionCell={
          permissions?.canAddEditUser
            ? (row) => <EditUserButton id={row.id} />
            : undefined
        }
      />
    </div>
  )
}

const EditUserButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(AppRoutes.ADMIN_USER_EDIT_PATH, {
      id: props.id.toString(),
    })}
  >
    <EditIcon />
  </IconButton>
)

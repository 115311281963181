import EditIcon from '@mui/icons-material/Edit'
import { Button, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { generatePath } from 'react-router-dom'
import {
  PagedTable,
  Props as PagedTableProps,
} from '../../components/paged-table'
import { District } from '../../core/types'
import { usePermissions } from '../../hooks/use-permissions'
import * as AppRoutes from '../../routes'

type DistrictColumns = PagedTableProps<District>['columns']
const columns: DistrictColumns = [
  { field: 'name', headerName: 'Name', sortBy: 'Name' },
  { field: 'adminUsers', headerName: 'Admin', sortBy: 'AdminUsers' },
  {
    field: 'usersOnboard',
    headerName: 'Users Onboard',
    sortBy: 'UsersOnboard',
  },
]

export const Districts = () => {
  const permissions = usePermissions()

  return (
    <div>
      <Typography variant="h2">Manage Districts</Typography>
      {permissions?.canManageDistricts && (
        <Box sx={{ mt: '1rem' }}>
          <Button
            variant="contained"
            href={AppRoutes.DISTRICT_IMPORT_SCHOOL_PATH}
            sx={{ marginRight: '1rem' }}
          >
            Add School District
          </Button>
          <Button variant="contained" href={AppRoutes.DISTRICT_ADD_PATH}>
            Add Other
          </Button>
        </Box>
      )}
      <PagedTable
        columns={columns}
        queryFor="District"
        rowActionCell={(row) => <ViewDistrictButton id={row.id} />}
      />
    </div>
  )
}

const ViewDistrictButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(AppRoutes.DISTRICT_DETAILS_PATH, {
      id: props.id.toString(),
    })}
  >
    <EditIcon />
  </IconButton>
)

import { Input, CircularProgress } from '@mui/material'
import { useState } from 'react'
import { sendRequest } from '../../hooks/use-fetch'

export function ImportIncident() {
  const [formMessages, setFormMessages] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const onUpload = async (file: File) => {
    setLoading(true)
    setFormMessages([])

    const url = `${process.env.REACT_APP_SERVER_URL}/Incident/UploadIncidents   `
    var data = new FormData()
    data.append('file', file)
    const { response, success, error, result } = await sendRequest(url, {
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
      method: 'POST',
      body: data,
    })
    if (success) {
      const stringResponse: string = await response?.json()
      console.log(stringResponse)
      if (stringResponse !== 'File loaded') {
        setIsError(true)
        setFormMessages(stringResponse.split('<br />'))
      } else {
        setIsError(false)
        setFormMessages([stringResponse])
      }
    } else {
      const result: string = await response?.json()
      setIsError(true)
      result && setFormMessages([result])
    }
    setLoading(false)
  }

  const fileInputChanged = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0]
      await onUpload(file)
    }
  }

  return (
    <>
      {loading && <CircularProgress />}
      {formMessages &&
        formMessages.map((m) => (
          <li
            key={m}
            style={{ color: isError ? 'red' : 'green', padding: '10px 0 0' }}
          >
            {m}
          </li>
        ))}
      <Input type="file" onChange={fileInputChanged} />
    </>
  )
}

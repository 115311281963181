import {
  Checkbox,
  ListItemText,
  ListItem,
  ListItemIcon,
  ListItemButton,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { TextAreaWithLabel } from '../forms/text-area-with-label'
type Props = {
  name: string
  onClick: () => void
  checked: boolean
  text: string
  hasTextArea?: boolean
  textAreaValue?: string
  setTextAreaValue?: (e: any) => void
  disabled?: boolean
  flex?: boolean
}

export function CheckListItem(props: Props) {
  return (
    <>
      <ListItem
        key={props.name}
        disablePadding
        dense
        sx={{ height: '30px', maxWidth: props.flex ? '100px' : '100%' }}
      >
        <ListItemButton
          role={undefined}
          onClick={props.onClick}
          dense
          disabled={props.disabled}
          sx={{ height: '30px' }}
        >
          <ListItemIcon sx={{ height: '30px' }}>
            {props.disabled && <CircleIcon sx={{ height: '10px', mt: 1 }} />}
            {!props.disabled && (
              <Checkbox
                edge="start"
                checked={props.checked}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': props.name }}
                sx={{ height: '30px' }}
              />
            )}
          </ListItemIcon>
          <ListItemText
            id={props.name}
            primary={props.text}
            sx={{ height: '30px' }}
          />
        </ListItemButton>
      </ListItem>
      {props.hasTextArea && props.checked && props.setTextAreaValue && (
        <TextAreaWithLabel
          label=""
          name="other"
          disabled={props.disabled}
          value={props.textAreaValue}
          onChange={props.setTextAreaValue}
        />
      )}
    </>
  )
}

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  SelectOption,
  SelectWithLabel,
} from '../../components/forms/select-with-label'
import { CheckList } from '../../components/check-list'
import React, { useEffect, useState } from 'react'
import { DatePickerWithLabel } from '../../components/forms/date-picker-with-label'
import { usePermissions } from '../../hooks/use-permissions'
import { useAuth } from '../../hooks/use-auth'
import { Incident } from '../../core/types'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import {
  ClearCharts,
  CreateTable,
  ConfigureColumnChart,
} from './chart-functions'
import { AccordionProps } from '@mui/material/Accordion'
import { AccordionSummaryProps } from '@mui/material/AccordionSummary'

const NoShadowAccordion = styled((props: AccordionProps) => (
  <Accordion {...props} disableGutters />
))({
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  borderTop: 'none',
  '&:before': {
    display: 'none',
  },
})
const Summary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'green',
    transform: 'rotate(90deg)',
    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
}))

type ReportFilters = DateTypes & ArrayTypes
type DateTypes = {
  startDate: Date | null
  endDate: Date | null
}
type ArrayTypes = {
  selectedGenders: SelectOption<number>[]
  selectedDistricts: SelectOption<number>[]
  selectedBuildings: SelectOption<number>[]
  selectedRaces: SelectOption<number>[]
  selectedCounties: SelectOption<number>[]
  selectedReports: SelectOption<number>[]
}
type IncidentRecord = {
  accommodation: { id: number; name: string }
  actionsTaken: string[]
  ageAtTimeOfIncident: number
  alcoholDrugUse: number
  anonymousPreferedGender: string
  countyName: string
  dateOf: string
  districtName: string
  grade: number
  identifiesASLGBTQ: number
  previousKnowledge: number
  otherRiskLevel: string | null
  outsidePlanInitiated: number
  riskLevel: { id: number; name: string }
  studentRaces: {
    africanAmericanOrBlack: number
    americanIndianOrAlaskan: number
    asian: number
    ethnicityCode: string
    hispanicOrLatino: number
    nativeHawaiianOrPacificIslander: number
    none: boolean
    reportableEthnicity: string
    white: number
  }
  _studentIdentifier: number
}

export function IncidentAggregateReport() {
  const [chart, setChart] = useState<JSX.Element[]>([])
  const [count, setCount] = useState(0)
  const [schools, setSchools] = useState<SelectOption<number>[]>([])
  const [schoolsLoaded, setSchoolsLoaded] = useState(false)
  const genderOptions: SelectOption<number>[] = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' },
    { id: 3, name: 'Unknown' },
  ]
  const perms = usePermissions()
  const { user } = useAuth()
  const print = () => {
    window.print()
  }
  const search = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/Incident/Search?`
    let paramList = `beginDate=${filters.startDate?.toISOString()}&endDate=${filters.endDate?.toISOString()}`
    if (filters.selectedRaces.length > 0) {
      const raceNames = filters.selectedRaces.map((r) => r.name)
      paramList += `&race=${raceNames.join()}`
    }
    if (filters.selectedGenders.length > 0)
      paramList += `&gender=${filters.selectedGenders
        .map((g) => g.name.charAt(0))
        .join(',')}`

    if (perms?.canManageReports) {
      if (filters.selectedDistricts.length > 0)
        paramList += `&districts=${filters.selectedDistricts
          .map((d) => d.name)
          .join()}`
      if (filters.selectedCounties.length > 0)
        paramList += `&counties=${filters.selectedCounties
          .map((c) => c.name)
          .join()}`


    } else {
      paramList += `&districts=${user?.district?.name}`
    }
        if(filters.selectedBuildings.length > 0){
      paramList += `&schools=${filters.selectedBuildings
        .map((c) => c.name)
        .join()}`
      }

          const { success, response } = await sendRequest(url + paramList)
    if (success) {
      const results = await response?.json()
      setCount(results.length)
      loadCharts(results)
    }
  }
  const loadCharts = (data: IncidentRecord[]) => {
    let newCharts = []
    const reportNames = filters.selectedReports.map((r) => r.name)

    if (perms?.canManageReports && reportNames.includes('District')) {
      newCharts.push(districtChart(data))
    }
    if (reportNames.includes('Month')) newCharts.push(monthReport(data))
    if (reportNames.includes('Grade')) newCharts.push(gradeReport(data))
    if (reportNames.includes('Age')) newCharts.push(ageReport(data))
    if (reportNames.includes('Gender')) newCharts.push(genderReport(data))
    if (reportNames.includes('LGBTQ')) newCharts.push(lGBTQReport(data))
    if (reportNames.includes('Race')) newCharts.push(raceReport(data))
    if (reportNames.includes('Drug Involvement'))
      newCharts.push(drugInvolementReport(data))
    if (reportNames.includes('Plan Initiation'))
      newCharts.push(planInitiationReport(data))
    if (reportNames.includes('Risk Level'))
      newCharts.push(riskLevelReport(data))
    if (reportNames.includes('Accommodation'))
      newCharts.push(accommodationReport(data))
    if (reportNames.includes('Incident Count'))
      newCharts.push(incidentCountChart(data))
    if (reportNames.includes('Risk')) newCharts.push(loadRiskChart(data))
    if (reportNames.includes('Safety Plan'))
      newCharts.push(safetyPlanChart(data))
    if (reportNames.includes('Previous Knowledge'))
      newCharts.push(previousKnowledgeReport(data))
    setChart(newCharts)
  }
  const raceOptions: SelectOption<number>[] = [
    { id: 1, name: 'American Indian or Alaskan Native' },
    { id: 2, name: 'Asian' },
    { id: 3, name: 'African American or Black' },
    { id: 4, name: 'Native Hawaiian or Pacific Islander' },
    { id: 5, name: 'White' },
    { id: 6, name: 'Hispanic or Latino' },
    { id: 7, name: 'Two or more races' },
    { id: 8, name: 'Unknown' },
  ]

  const { response, isLoading } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/GetActiveDistricts`
  )

  const { response: response2, isLoading: isLoading2 } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/GetActiveCounties`
  )
  let counties: SelectOption<number>[] = []
  let districts: SelectOption<number>[] = []
  if (perms?.canManageReports) {
    districts = (response ?? []) as SelectOption<number>[]
    counties = (response2 ?? []) as SelectOption<number>[]
  }
  const reportOptions: SelectOption<number>[] = [
    { name: 'District', id: 1 },
    { name: 'Month', id: 2 },
    { name: 'Age', id: 3 },
    { name: 'Grade', id: 4 },
    { name: 'Gender', id: 5 },
    { name: 'Accommodation', id: 6 },
    { name: 'Drug Involvement', id: 7 },
    { name: 'Plan Initiation', id: 8 },
    { name: 'Risk Level', id: 9 },
    { name: 'Race', id: 10 },
    { name: 'LGBTQ', id: 11 },
    { name: 'Incident Count', id: 12 },
    { name: 'Safety Plan', id: 13 },
    { name: 'Risk', id: 14 },
    { name: 'Previous Knowledge', id: 15 },
  ]
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    selectedReports: reportOptions,
    selectedCounties: [],
    selectedDistricts: [],
    selectedGenders: [],
    selectedRaces: [],
    selectedBuildings:[]
  } as ReportFilters)


  useEffect(() => {
    
    const  loadSchools = async (): Promise<void> => {
      let districtString = filters.selectedDistricts.map((d) => `${d.id}`).join(',')
      const querystring = districtString === '' ? '' : `?districts=${districtString}`
      const schoolQuerystring = perms?.canManageReports === true ?querystring : ''
      const { response:schoolResponse } = await sendRequest(
        `${process.env.REACT_APP_SERVER_URL}/Incident/GetActiveSchools` + schoolQuerystring
      )
      const results = await schoolResponse?.json()
      setSchools( (results ?? []) as SelectOption<number>[])
      setSchoolsLoaded(true)
    }
    if (!schoolsLoaded || perms?.canManageReports)
      void loadSchools()

  }, [filters.selectedDistricts, perms?.canManageReports])

  if (isLoading || isLoading2) return <CircularProgress />

  function selectOption(value: SelectOption<number>[], type: string) {
    setFilters({ ...filters, [type]: value })
  }
  function setDate(value: Date | null, type: string) {
    setFilters({ ...filters, [type]: value })
  }
  function groupData(data: string[]) {
    let grouped: { [key: string]: any } = {}
    data.forEach((d) => {
      let val = d
      if (grouped[val] === undefined) grouped[val] = [d]
      else grouped[val].push(d)
    })
    let keys = Object.keys(grouped).sort()
    let retObject = keys.map((k) => {
      return {
        label: k,
        value: grouped[k].length,
        toolText: `${k} - ${grouped[k].length} (${Math.round(
          100 * (grouped[k].length / data.length)
        )}%)`,
      }
    })
    return retObject
  }
  function districtChart(data: IncidentRecord[]) {
    const dat = groupData(data.map((d) => d.districtName))
    let ch = CreateTable('districtChart', 'Incidents by District', dat)
    return ch
  }
  function loadRiskChart(data: IncidentRecord[]) {
    const risks = ['high', 'moderate', 'low']
    const filteredData = data.filter((d) =>
      risks.includes(d.riskLevel.name.toLowerCase())
    )
    const groupedDat = groupData(filteredData.map((d) => d.riskLevel.name))
    var ch = ConfigureColumnChart(
      'Incidents by Risk Level',
      groupedDat,
      '',
      'column2d'
    )
    return ch
  }

  function incidentCountChart(data: IncidentRecord[]) {
    const students = data.filter((s) => s._studentIdentifier !== -1)
    let incidentCounts: { id: string; count: number }[] = []
    students.forEach((s) => {
      let studentId = s._studentIdentifier.toString()
      let record = incidentCounts.filter((f) => studentId === f.id)[0]
      if (record === undefined) {
        incidentCounts.push({ id: studentId, count: 1 })
      } else record.count++
    })
    let groups: { incidentCount: number; total: number }[] = []
    incidentCounts.forEach((s) => {
      let recordCount = s.count
      let record = groups.filter((f) => recordCount === f.incidentCount)[0]
      if (record === undefined) {
        groups.push({ incidentCount: recordCount, total: 1 })
      } else record.total++
    })
    const labels = groups.map((g) => {
      return {
        label: `${g.incidentCount} Incidents`,
        value: g.total,
        toolText: `${g.total} - ${students.length} (${Math.round(
          100 * (g.total / students.length)
        )}%)`,
      }
    })
    var ch = ConfigureColumnChart(
      'Incidents per Student',
      labels,
      '',
      'column2d'
    )
    return ch
  }
  function safetyPlanChart(data: IncidentRecord[]) {
    let actions: { [key: string]: number } = {}
    data.forEach((student) => {
      var plans = student.actionsTaken.filter((a) =>
        a.toLowerCase().includes('safety plan')
      )
      plans.forEach((p) => {
        if (actions[p] === undefined) actions[p] = 1
        else actions[p] = actions[p] + 1
      })
    })
    let sum = 0
    let vals = Object.values(actions)
    vals.map((v) => (sum = sum + v))
    let keys = Object.keys(actions).map((a) => {
      return {
        label: a,
        value: actions[a],
        toolText: `${a} - ${actions[a]} (${Math.round(
          100 * (actions[a] / sum)
        )}%)`,
      }
    })
    return ConfigureColumnChart('Safety Plan Generated', keys, '', 'column2d')
  }
  function monthReport(data: IncidentRecord[]) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let dates = data.map((d) => {
      let date = new Date(d.dateOf)
      return `${monthNames[date.getMonth()]} ${date.getFullYear()}`
    })
    const groupedDat = groupData(dates)
    if (data.length > 0) {
      let ch = CreateTable('monthChart', 'Incidents by Month', groupedDat)
      return ch
    }
    return <></>
  }
  function gradeReport(data: IncidentRecord[]) {
    const groupedDat = groupData(data.map((d) => d.grade.toString()))

    return ConfigureColumnChart(
      'Incidents by Grade',
      groupedDat,
      '',
      'column2d'
    )
  }
  function ageReport(data: IncidentRecord[]) {
    const dat = groupData(data.map((d) => d.ageAtTimeOfIncident.toString()))
    return ConfigureColumnChart('Incidents by Age', dat, '', 'column2d')
  }
  function genderReport(data: IncidentRecord[]) {
    const mapped = data.map((d) => {
      let opi = 'Unknown'
      if (d.anonymousPreferedGender === 'M') opi = 'Male'
      else if (d.anonymousPreferedGender === 'F') opi = 'Female'
      return opi
    })
    const dat = groupData(mapped)

    return CreateTable('genderChart', 'Incidents by Gender', dat)
  }
  function accommodationReport(data: IncidentRecord[]) {
    const dat = groupData(data.map((d) => d.accommodation.name))

    return ConfigureColumnChart('Incidents by Student Accommodation', dat, '')
  }
  function drugInvolementReport(data: IncidentRecord[]) {
    const mapped = data.map((d) => {
      let opi = 'No'
      if (d.alcoholDrugUse === 1) opi = 'Yes'
      else if (d.alcoholDrugUse === 2) opi = 'Unknown'
      return opi
    })
    const dat = groupData(mapped)

    return ConfigureColumnChart(
      'Incidents Involving Alcohol and Drug Use',
      dat,
      ''
    )
  }
  function planInitiationReport(data: IncidentRecord[]) {
    const mapped = data.map((d) => {
      let opi = 'No'
      if (d.outsidePlanInitiated === 1) opi = 'Yes'
      else if (d.outsidePlanInitiated === 2) opi = 'Unknown'
      return opi
    })
    const dat = groupData(mapped)
    return ConfigureColumnChart(
      'Incidents with a Suicide Plan Initiated in the community (outside of school)?',
      dat,
      ''
    )
  }
  function riskLevelReport(data: IncidentRecord[]) {
    const dat = groupData(data.map((d) => d.riskLevel.name))
    return ConfigureColumnChart('Incidents by CSSRS Risk Level', dat)
  }

  function raceReport(data: IncidentRecord[]) {
    const dat = groupData(data.map((d) => d.studentRaces.reportableEthnicity))

    return ConfigureColumnChart('Incidents by Race/Ethnicity', dat, '')
  }
  function lGBTQReport(data: IncidentRecord[]) {
    const mapped = data.map((d) => {
      let opi = 'No'
      if (d.identifiesASLGBTQ === 1) opi = 'Yes'
      else if (d.identifiesASLGBTQ === 2) opi = 'Unknown'
      return opi
    })
    const dat = groupData(mapped)

    return ConfigureColumnChart('Incidents by LGBTQA+ Status', dat, '')
  }
  function previousKnowledgeReport(data:IncidentRecord[]){
        const mapped = data.map((d) => {
      let opi = 'No'
      if (d.previousKnowledge === 1) opi = 'Yes'
      else if (d.previousKnowledge === 2) opi = 'Unknown'
      return opi
    })
    const dat = groupData(mapped)

    return ConfigureColumnChart('Had Previous Incident this year', dat, '')

  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant={'h2'}>Incident Report</Typography>

      <Box sx={{ pl: 5 }}>
        <Typography variant={'h5'}>Filters</Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ minWidth: '600px' }}>
            <Box
              sx={{
                backgroundColor: '#f7f7f7',
                mr: '20px',
                p: '20px',
                verticalAlign: 'top',
              }}
            >
              <Box>
                <Box>
                  <Typography variant={'h2'}>{count} Incidents</Typography>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Typography>Date Range</Typography>
                  <Box sx={{ display: 'flex' }}>
                    <DatePickerWithLabel
                      label=""
                      name="startDate"
                      onChange={(d) => setDate(d, 'startDate')}
                      value={filters.startDate}
                    />
                    <DatePickerWithLabel
                      label=""
                      name="endDate"
                      onChange={(d) => setDate(d, 'endDate')}
                      value={filters.endDate}
                    />
                  </Box>
                </Box>
              </Box>
              <NoShadowAccordion>
                <Summary id="county-filter">
                  <Typography>County</Typography>
                </Summary>
                <AccordionDetails>
                  <CheckList
                    items={counties}
                    selectedItems={filters.selectedCounties}
                    setSelectedItems={(e) =>
                      selectOption(e, 'selectedCounties')
                    }
                    allOption
                  />
                </AccordionDetails>
              </NoShadowAccordion>

              <NoShadowAccordion>
                <Summary id="district-filter">
                  <Typography>Districts</Typography>
                </Summary>
                <AccordionDetails>
                  <CheckList
                    items={districts}
                    selectedItems={filters.selectedDistricts}
                    setSelectedItems={(e) =>
                      selectOption(e, 'selectedDistricts')
                    }
                    allOption
                  />
                </AccordionDetails>
              </NoShadowAccordion>
              <NoShadowAccordion>
                <Summary id="district-filter">
                  <Typography>Schools</Typography>
                </Summary>
                <AccordionDetails>
                  <CheckList
                    items={schools}
                    selectedItems={filters.selectedBuildings}
                    setSelectedItems={(e) =>
                      selectOption(e, 'selectedBuildings')
                    }
                    allOption
                  />
                </AccordionDetails>
              </NoShadowAccordion>
              <NoShadowAccordion>
                <Summary id="race-filter">
                  <Typography>Race/Ethnicity</Typography>
                </Summary>
                <AccordionDetails>
                  <CheckList
                    items={raceOptions}
                    selectedItems={filters.selectedRaces}
                    setSelectedItems={(e) => selectOption(e, 'selectedRaces')}
                    allOption
                  />
                </AccordionDetails>
              </NoShadowAccordion>
              <NoShadowAccordion>
                <Summary id="gender-filter">
                  <Typography>Gender</Typography>
                </Summary>
                <AccordionDetails>
                  <CheckList
                    items={genderOptions}
                    selectedItems={filters.selectedGenders}
                    setSelectedItems={(e) => selectOption(e, 'selectedGenders')}
                    allOption
                  />
                </AccordionDetails>
              </NoShadowAccordion>
              <NoShadowAccordion>
                <Summary id="components-filter">
                  <Typography>Report Components</Typography>
                </Summary>
                <AccordionDetails>
                  <CheckList
                    items={reportOptions}
                    selectedItems={filters.selectedReports}
                    setSelectedItems={(e) => selectOption(e, 'selectedReports')}
                    allOption
                  />
                </AccordionDetails>
              </NoShadowAccordion>
              <Box>
                <Button variant="contained" onClick={search}>
                  Search
                </Button>
                <Button variant="outlined" onClick={print}>
                  Print
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%%', pl: 5 }}>{chart}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import { TextFieldWithLabel } from '../forms/text-field-with-label'
import { Box, SelectChangeEvent } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import * as AppRoutes from '../../routes'
import { District, Training, TrainingType } from '../../core/types'
import { DatePickerWithLabel } from '../forms/date-picker-with-label'
import { ConfirmationDialog } from '../forms/confirmation-dialog'
import { FormActionButton } from '../forms/form-action-button'
import { SelectWithLabel } from '../forms/select-with-label'
import { TimePickerWithLabel } from '../forms/time-picker-with-label'
import { TextAreaWithLabel } from '../forms/text-area-with-label'

const serverUrl = process.env.REACT_APP_SERVER_URL

type Props =
  | { mode: 'add' }
  | {
      mode: 'edit'
      itemToEdit: Training
    }

export const TrainingForm = (props: Props) => {
  const initialState =
    props.mode === 'edit'
      ? props.itemToEdit
      : {
          id: 0,
          date: new Date(),
          startTime: '00:00:00',
          location: '',
          avNeeds: '',
          food: '',
          notes: '',
          districtID: 0,
          district: {} as District | null,
          trainingTypeID: 0,
          trainingType: {} as TrainingType | null,
        }

  const [formState, setFormState] = useState<Training>(initialState)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [formErrors, setFormErrors] = useState<string[] | undefined>()

  const handleChange = (event: SelectChangeEvent<string | number | Date>) =>
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })

  const { response: types } = useFetch(`${serverUrl}/TrainingType/GetLookup`)
  const typeOptions = (types ?? []) as TrainingType[]
  const { response: districts } = useFetch(`${serverUrl}/District/GetLookup`)
  const districtOptions = (districts ?? []) as District[]

  function setDate(value: Date | null) {
    if (value) setFormState({ ...formState, date: value })
  }
  function setTime(value: Date | null) {
    if (value) {
      let x = `${value?.getHours().toString().padStart(2, '0')}:${value
        ?.getMinutes()
        .toString()
        .padStart(2, '0')}:${value
        ?.getMilliseconds()
        .toString()
        .padStart(2, '0')}`
      setFormState({ ...formState, startTime: x })
    }
  }

  const navigate = useNavigate()
  const onCancel = () => navigate(-1)

  const onSave = async () => {
    setFormErrors(undefined)
    const url = `${serverUrl}/Training/${
      props.mode === 'edit' ? 'Update' : 'Insert'
    }`

    let { district, trainingType, ...rest } = formState

    const { response, success } = await sendRequest(url, {
      method: props.mode === 'edit' ? 'PUT' : 'POST',
      body: JSON.stringify(rest),
    })
    if (success) {
      navigate(AppRoutes.TRAINING_LIST_PATH)
    } else {
      const result = await response?.json()
      if (result) {
        const errors = (result as { errors: { [k: string]: string[] } }).errors
          ? Object.keys(result.errors)
              .map((k) => result.errors[k])
              .flat()
          : [result as string]

        setFormErrors(errors)
      }
    }
  }

  const onDelete = async () => {
    if (props.mode === 'edit') {
      setFormErrors(undefined)
      const url = `${serverUrl}/Training/Delete?id=${formState.id}`

      const { response, success } = await sendRequest(url, { method: 'DELETE' })
      if (success) {
        setDeleteOpen(false)
        navigate(AppRoutes.TRAINING_LIST_PATH)
      } else {
        const result = await response?.json()
        result && setFormErrors([result].flatMap((er) => er))
      }
    }
  }

  return (
    <Box component="form">
      {formErrors &&
        formErrors.map((err) => (
          <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
            {err}
          </li>
        ))}

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <DatePickerWithLabel
          label="Date"
          name="date"
          required
          onChange={(d) => setDate(d)}
          value={formState?.date}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TimePickerWithLabel
          label="Start Time"
          name="startTime"
          required
          onChange={(d) => setTime(d)}
          value={new Date('1970-01-01T' + formState?.startTime)}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <SelectWithLabel
          options={districtOptions}
          name="districtID"
          required
          label="District"
          value={formState?.districtID}
          onChange={handleChange}
          FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <SelectWithLabel
          options={typeOptions}
          name="trainingTypeID"
          width="200px"
          required
          label="Training Type"
          value={formState?.trainingTypeID}
          onChange={handleChange}
          FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TextFieldWithLabel
          label="Location"
          name="location"
          required
          onChange={handleChange}
          value={formState?.location}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TextAreaWithLabel
          label="AV Needs"
          name="aVNeeds"
          multiline
          onChange={handleChange}
          value={formState?.avNeeds}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TextAreaWithLabel
          label="Food"
          name="food"
          multiline
          onChange={handleChange}
          value={formState?.food}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TextAreaWithLabel
          label="Notes"
          name="notes"
          multiline
          onChange={handleChange}
          value={formState?.notes}
        />
      </Box>

      <Box sx={{ marginTop: '14px' }}>
        <FormActionButton
          onClick={onSave}
          variant="contained"
          //disabled={saveDisabled}
        >
          Save
        </FormActionButton>
        <FormActionButton onClick={onCancel}>Cancel</FormActionButton>

        {props.mode === 'edit' && (
          <>
            <ConfirmationDialog
              open={deleteOpen}
              id="delete-user-confirmation"
              keepMounted={false}
              onClose={() => setDeleteOpen(false)}
              onConfirm={onDelete}
              confirmationContent="Are you sure you want to delete this user?"
            />
            <FormActionButton onClick={() => setDeleteOpen(true)}>
              Delete
            </FormActionButton>
          </>
        )}
      </Box>
    </Box>
  )
}

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import React, { useCallback } from 'react'
import { generatePath } from 'react-router'
import { District } from '../../core/types'
import { sendRequest } from '../../hooks/use-fetch'
import { usePermissions } from '../../hooks/use-permissions'
import { DISTRICT_ADD_DISTRICT_ADMIN_PATH } from '../../routes'
import { ConfirmationDialog } from '../forms/confirmation-dialog'
import { ValueWithHeader } from '../header-with-value'

type Props = {
  district: District
  refetch: () => void
}
export const DistrictInformation = (props: Props) => {
  const { district } = props
  const permissions = usePermissions()
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false)

  const [useIntegratedStudentData, setUseIntegratedStudentData] =
    React.useState(district.useIntegratedStudentData)
  const [miLaunchPadEnabled, setMiLaunchPadEnabled] = React.useState(
    district.integrationEnabled
  )

  const adminUsers = district.adminUsers.split(',')

  const resendInviteEmails = () => {
    sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/User/SendInviteEmail?districtID=${district.id}`
    )
  }
  const updateIntegration = useCallback(async () => {
    const { success } = await sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/District/UpdateIntegration`,
      {
        method: 'POST',
        body: JSON.stringify({ districtId: district.id, enabled: true }),
      }
    )
    if (success) {
      setMiLaunchPadEnabled(true)
      setConfirmationDialogOpen(false)
      props.refetch()
    }
  }, [district.id, props])

  const updateStudentIntegration = useCallback(
    async (_e: any, enabled: boolean) => {
      const { success } = await sendRequest(
        `${process.env.REACT_APP_SERVER_URL}/District/UpdateStudentIntegration`,
        {
          method: 'POST',
          body: JSON.stringify({ districtId: district.id, enabled }),
        }
      )
      if (success) {
        setUseIntegratedStudentData(enabled)
        props.refetch()
      }
    },
    [district.id, props]
  )

  return (
    <Box sx={{ m: '1rem 0' }}>
      <ValueWithHeader header="District Name" value={district.name} />

      <ConfirmationDialog
        open={confirmationDialogOpen}
        keepMounted={false}
        confirmationContent="This action will change the way users are created and how incidents are logged. Do you wish to continue?"
        onConfirm={updateIntegration}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <ValueWithHeader
        header="Enable data exchange integration via MiLaunchPad"
        value={
          <Checkbox
            disabled={
              !permissions?.canEditDistrictInformation || miLaunchPadEnabled
            }
            checked={miLaunchPadEnabled}
            onClick={() => setConfirmationDialogOpen(true)}
          />
        }
      />
      <ValueWithHeader
        header="Enable data exchange integration for student information"
        value={
          <Checkbox
            disabled={
              !permissions?.canEditDistrictInformation || !miLaunchPadEnabled
            }
            checked={useIntegratedStudentData}
            onChange={updateStudentIntegration}
          />
        }
      />
      <ValueWithHeader
        header="District Admins"
        value={adminUsers.map((user) => (
          <li key={user}>{user}</li>
        ))}
      />

      {permissions?.canAddDistrictAdmin && (
        <Box sx={{ marginTop: '1rem' }}>
          <Button
            variant="contained"
            href={generatePath(DISTRICT_ADD_DISTRICT_ADMIN_PATH, {
              id: district.id.toString(),
            })}
            sx={{ marginRight: '1rem' }}
          >
            Add District Admin
          </Button>
          <Button
            variant="contained"
            disabled={adminUsers.length < 1}
            onClick={resendInviteEmails}
          >
            Resend Invite Email to District Admins
          </Button>
        </Box>
      )}
    </Box>
  )
}

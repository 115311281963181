import VisibilityIcon from '@mui/icons-material/Visibility'
import { Button, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { generatePath } from 'react-router'
import { PagedTable, Props as PagedTableProps } from '../components/paged-table'
import { dateRenderer } from '../components/paged-table/renderers'
import { usePermissions } from '../hooks/use-permissions'
import * as AppRoutes from '../routes'

export type IncidentColumn = {
  id: number
  studentID: number | null
  isAnonymous: boolean
  studentLastName: string | null
  studentFirstName: string | null
  grade: number
  dateOf: string
  schoolName: string
  location: string
  userName: string
  riskLevelName: string
  districtName: string
  importDate: string | null
}

type IncidentColumns = PagedTableProps<IncidentColumn>['columns']

export const Incidents = () => {
  const perms = usePermissions()
  let columns: IncidentColumns = [
    {
      field: 'studentLastName',
      headerName: 'Last Name',
      sortBy: 'Student.LastName',
    },
    {
      field: 'studentFirstName',
      headerName: 'First Name',
      sortBy: 'Student.FirstName',
    },
    { field: 'grade', headerName: 'Grade', sortBy: 'Grade' },
    {
      field: 'dateOf',
      headerName: 'Incident Date',
      sortBy: 'DateOf',
      renderField: dateRenderer,
    },
    { field: 'schoolName', headerName: 'School', sortBy: 'School.Name' },
    { field: 'location', headerName: 'Location', sortBy: 'Location' },
    { field: 'userName', headerName: 'Logged By', sortBy: 'User.LastName' },
    {
      field: 'riskLevelName',
      headerName: 'Risk Level',
      sortBy: 'RiskLevel.Name',
    },
  ]

  if (perms?.canViewIncidentDistrictInfo) {
    columns.push(
      {
        field: 'districtName',
        headerName: 'School District',
        sortBy: 'School.District.Name',
      },
      {
        field: 'importDate',
        headerName: 'Import Date',
        sortBy: 'ImportDate',
        renderField: dateRenderer,
      }
    )
  }

  return (
    <div>
      <Typography variant="h2">Incidents</Typography>
      <Box sx={{ paddingTop: '1rem' }}>
        <Button variant="contained" href={AppRoutes.ADD_INCIDENT_PATH}>
          Log New Incident
        </Button>
      </Box>
      <PagedTable
        columns={columns}
        queryFor="Incident"
        rowActionCell={(row) => <ViewIncidentButton id={row.id} />}
      />
    </div>
  )
}

const ViewIncidentButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
      id: props.id.toString(),
    })}
  >
    <VisibilityIcon />
  </IconButton>
)

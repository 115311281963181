import { Button, IconButton, Typography, Box } from '@mui/material'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../../components/paged-table/simple-paged-table'
import { TriggerEvent } from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'
import * as AppRoutes from '../../../routes'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
type TriggerColumn = SimpleColumn<TriggerEvent>

export function TriggerEventList() {
  const serverUrl = process.env.REACT_APP_SERVER_URL

  let { response: resp } = useFetch(`${serverUrl}/TriggerEvent/GetAll`)
  const plans = (resp ?? []) as TriggerEvent[]
  plans.sort((a, b) => a.order - b.order)
  const columns: TriggerColumn[] = [
    { field: 'name', headerName: 'Name' },
    { field: 'order', headerName:'Order'},
    {
      field: 'closed',
      headerName: 'Closed',

      renderField: (closed: boolean) => (closed ? 'Yes' : 'No'),
    },
  ]
  const nav = useNavigate()
  const createTrigger = () => {
    nav(generatePath(AppRoutes.ADMIN_TRIGGER_EVENT_ADD))
  }
  const editTrigger = (id: number) => {
    nav(
      generatePath(AppRoutes.ADMIN_TRIGGER_EVENT_EDIT, {
        id: id.toString(),
      })
    )
  }

  return (
    <Box>
      <Typography variant="h2">Trigger Events</Typography>

      <Button variant="contained" onClick={createTrigger}>
        Add
      </Button>

      <SimplePagedTable
        columns={columns}
        rows={plans}
        disableSearch
        rowActionCell={(row) => (
          <EditTriggerEventButton id={row.id} onClick={editTrigger} />
        )}
      />
    </Box>
  )
}
const EditTriggerEventButton = (props: {
  id: number
  onClick: (id: number) => void
}) => {
  const handleClick = React.useCallback(() => props.onClick(props.id), [props])
  return (
    <IconButton onClick={handleClick}>
      <EditIcon />
    </IconButton>
  )
}

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'

import React, { useState } from 'react'
import { Student } from '../../core/types'
import { sendRequest } from '../../hooks/use-fetch'
import { TextFieldWithLabel } from '../forms/text-field-with-label'
import { dateRenderer } from '../paged-table/renderers'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../paged-table/simple-paged-table'

type DataHubStudentResult = Pick<
  Student,
  'id' | 'lastName' | 'firstName' | 'dateOfBirth' | 'uic'
>
type StudentColumn = SimpleColumn<DataHubStudentResult>
type Props = {
  selectStudentFunction: (student: Student) => void
}

export function DataHubStudentSearchModal(props: Props) {
  const [open, setOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [uic, setUic] = useState('')
  const [studentList, setStudentList] = useState<
    DataHubStudentResult[] | undefined
  >()
  const [loading, setLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const columns: StudentColumn[] = [
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'dateOfBirth',
      headerName: 'DOB',
      renderField: dateRenderer,
    },
    { field: 'uic', headerName: 'UIC' },
  ]
  async function onSelectStudent(id: number) {
    if (studentList) {
      const st = studentList.find((s) => s.id === id)
      if (st) {
        const { response, success } = await sendRequest(
          `${process.env.REACT_APP_SERVER_URL}/Student/Store`,
          {
            method: 'POST',
            body: JSON.stringify(st),
          }
        )

        if (success) {
          let newStudentId = await response?.json()
          st.id = (newStudentId ?? 0) as number
          props.selectStudentFunction(st as Student)
          setOpen(false)
        }
      }
    }
  }

  const getStudents = async () => {
    setLoading(true)
    try {
      const { response, success } = await sendRequest(
        `${process.env.REACT_APP_SERVER_URL}/Student/Search?firstName=${firstName}&lastName=${lastName}&UIC=${uic}`
      )
      if (success) {
        const data = await response?.json()
        setStudentList(data)
      } else {
        setStudentList([])
      }
    } catch {
      setStudentList([])
    }

    setLoading(false)
    setHasLoaded(true)
  }
  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        sx={{ fontWeight: 'bold', height: '40px', width: '120px' }}
      >
        Search
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
        <DialogTitle sx={{ display: 'flex' }}>
          Student Search
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            id="manualStudentForm"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Grid container>
              <Grid item sx={{ pl: 2, pr: 2 }}>
                <TextFieldWithLabel
                  label={'Legal First Name'}
                  name={'legalFirstName'}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                <TextFieldWithLabel
                  label={'Legal Last Name'}
                  name={'legalLastName'}
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
                <TextFieldWithLabel
                  label={'UIC'}
                  name={'uic'}
                  onChange={(e) => setUic(e.target.value)}
                  value={uic}
                />
              </Grid>
              <Box>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Collapse orientation="horizontal" in={hasLoaded}>
                    {studentList && studentList.length > 0 ? (
                      <SimplePagedTable
                        disableSearch
                        columns={columns}
                        rows={studentList}
                        rowActionCell={(row) => (
                          <SelectStudentButton
                            id={row.id}
                            onClick={onSelectStudent}
                          />
                        )}
                      />
                    ) : (
                      <Typography>
                        No one was found with that search criteria
                      </Typography>
                    )}
                  </Collapse>
                )}
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            form="manualStudentForm"
            variant="contained"
            autoFocus
            onClick={getStudents}
          >
            Search
          </Button>
          <Button autoFocus variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
const SelectStudentButton = (props: {
  id: number
  onClick: (id: number) => void
}) => {
  const handleClick = React.useCallback(() => props.onClick(props.id), [props])
  return (
    <IconButton onClick={handleClick}>
      <EditIcon />
    </IconButton>
  )
}


import CloseIcon from '@mui/icons-material/Close'
export const dateRenderer = (date: string | null) => {
  if (date) {
    let d = new Date(date)
    const month = d.getUTCMonth() + 1
    const day = d.getUTCDate()
    const year = d.getUTCFullYear()

    return `${month}/${day}/${year}`
  }
}

export const dateTimeRenderer = (dateString: string | null) => {
  if (dateString) {
    const date = new Date(dateString)
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()
    const hour = date.getHours()
    const minute = date.getMinutes()
    return `${month}/${day}/${year} ${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`
  }
}
export const booleanRenderer = (boolValue: boolean | null) => {
  return boolValue ?? false ? 'True' : 'False'
}
export const disabledRecordRenderer = (inactive: boolean) =>
  {
    return inactive && <CloseIcon sx={{ color: 'red', paddingTop: '7px' }} />
  }
export const timeRenderer = (timeString: string | null) => {
  if (timeString) {
    const date = new Date(`1/1/2000 ${timeString}`)
    return date.toLocaleTimeString(undefined, {
      timeStyle: 'short',
    })
  }
}

import { Container, Box } from '@mui/material'
import React from 'react'
import { AuthType } from '../../hooks/use-auth'
import { Footer } from '../footer'
import { NavigationBar } from '../navigation-bar'

export const Layout = (props: {
  auth: AuthType
  children: React.ReactNode
}) => {
  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <NavigationBar auth={props.auth} />
      <Container
        maxWidth={false}
        sx={{
          flexGrow: 1,
          padding: '1rem 1.5rem',
          width: '100%',
          paddingTop: '110px',
          paddingBottom: '40px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'start',
        }}
      >
        {props.children}
      </Container>
      <Footer />
    </Box>
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import {
  PagedTableUI,
  SortDirection,
  toggleSortDirection,
} from './paged-table-ui'

export type SimpleColumn<T> = {
  field: keyof T
  headerName: string
  renderField?: (value: any) => React.ReactNode
  colType?:string
}
type Props<T extends { id: number }> = {
  columns: SimpleColumn<T>[]
  rows: T[]
  rowActionCell?: (row: T) => React.ReactNode
  disableSearch?: boolean
  disableSort?: boolean
  enablePrint?: boolean
  customOnSort?:(sortCol:string, sortDir:string, sortType:string) => void
  initialSort? :keyof T
} & (
  | {
      enableSelect: true
      selectedIds: number[]
      setSelectedIds: Function
    }
  | {
      enableSelect?: false
    }
)

export function SimplePagedTable<T extends { id: number }>(props: Props<T>) {
  const { rows } = props
  const [colType, setColType] = useState('string')

  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortField, setSortField] = useState<keyof T>(props.initialSort ?? 'id')
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc')

  const recordCount = props.rows.length

  const handleChangePage = (_e: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }
  const print = () => {
    if (rows.length === 0) return
    let { id, ...rest } = rows[0]
    var keys = Object.keys(rest).join()
    let newRows = [...rows]
    newRows.sort(sortFunction)
    var values = newRows
      .map((e) => {
        let { id, ...rest } = e
        return Object.values(rest)
          .map((v) => {
            let str = v?.toString()
            if (str) {
              str = str.replaceAll(',', ';')
              return str
            }
            return v
          })
          .join()
      })
      .join('\n')
    const fileData = [
      ['data:text/csv;charset=utf-8', keys].join(),
      values,
    ].join('\n')
    var encodedUri = encodeURI(fileData)
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'data.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const onSort = React.useCallback(
    (headerId: keyof T, colType:string) => {
      const sortingBy = sortField === headerId
      setColType(colType)
      const newOrder = sortingBy ? toggleSortDirection(sortDirection) : 'desc'
      setSortDirection(newOrder)
      setSortField(headerId)
      if (props.customOnSort)
      props.customOnSort(headerId.toString(), newOrder, colType)
    },
    [sortDirection, sortField]
  )

  const [internalRows, setInternalRows] = useState(rows)

  const sortFunction = useCallback(
    (a: T, b: T) => {
      if (Reflect.has(a, sortField)) {
        const aField = Reflect.get(a, sortField)
        const bField = Reflect.get(b, sortField)
        if (colType === 'date'){
          const aDate = new Date(aField as string)
          const bDate = new Date(bField as string)
          if (sortDirection === 'asc') {
            if (aDate < bDate) return 1
            else return -1
          } else {
            if (aDate > bDate) return 1
            else return -1
          }

        }
        else{
          if (sortDirection === 'asc') {
            if (aField < bField) return 1
            else return -1
          } else {
            if (aField > bField) return 1
            else return -1
          }
        }
      } else return 0
    },
    [sortDirection, sortField, colType]
  )

  useEffect(() => {
    let newRows = [...rows]
    newRows.sort(sortFunction)

    if (!props.disableSearch && searchValue) {
      newRows = newRows.filter((r) => {
        const keys = Reflect.ownKeys(r)
        const hasValidValues = keys.some((key) => {
          const value = Reflect.get(r, key)
          return `${value}`
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        })
        return hasValidValues
      })
    }

    const current = page * pageSize
    const max = (page + 1) * pageSize
    const newRowSlice = newRows.slice(current, max)

    setInternalRows(newRowSlice)
  }, [
    sortDirection,
    sortField,
    pageSize,
    page,
    searchValue,
    props.disableSearch,
    rows,
    sortFunction,
  ])

  const onSearch = React.useCallback(
    (value: string) => setSearchValue(value),
    []
  )

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.enableSelect) {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.id)
        props.setSelectedIds(newSelected)
        return
      }
      props.setSelectedIds([])
    }
  }

  const handleClick = (id: number) => {
    if (props.enableSelect) {
      const selectedIndex = props.selectedIds.indexOf(id)
      let newSelected: number[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(props.selectedIds, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(props.selectedIds.slice(1))
      } else if (selectedIndex === props.selectedIds.length - 1) {
        newSelected = newSelected.concat(props.selectedIds.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          props.selectedIds.slice(0, selectedIndex),
          props.selectedIds.slice(selectedIndex + 1)
        )
      }

      props.setSelectedIds(newSelected)
    }
  }
  const isSelected = (id: number) => {
    if (props.enableSelect) return props.selectedIds.indexOf(id) !== -1
    return false
  }
  const enableSelect = props.enableSelect
  const selectProps = enableSelect
    ? {
        enableSelect: enableSelect,
        handleSelectAllClick: handleSelectAllClick,
        onSelect: handleClick,
        selectedItems: props.selectedIds,
        isSelected: isSelected,
        numSelected: props.selectedIds.length,
      }
    : { enableSelect }
  return (
    <PagedTableUI
      data={internalRows}
      onPageChange={handleChangePage}
      onPageSizeChange={handleChangeRowsPerPage}
      onSort={onSort}
      onSearch={onSearch}
      sortDirection={sortDirection}
      sortField={sortField}
      page={page}
      pageSize={pageSize}
      recordCount={recordCount}
      print={print}
      {...props}
      {...selectProps}
    />
  )
}

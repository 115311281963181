import { List, FormLabel, FormControl } from '@mui/material'
import { useState } from 'react'
import { SelectOption } from '../forms/select-with-label'
import { CheckListItem } from './check-list-item'

type Props = {
  label?: string
  disabled?: boolean
  allOption?: boolean
  items: SelectOption<number>[]
  selectedItems: SelectOption<number>[]
  fullWidth?: boolean
  flex?: boolean
  setSelectedItems?: (arg: SelectOption<number>[]) => void
} & (
  | { otherOption?: false }
  | {
      otherOption: true
      otherValue: string
      setOtherValue: (arg: string) => void
    }
)
export function CheckList(props: Props) {
  const [otherItemEnabled, setOtherItemEnabled] = useState(
    props.otherOption ? props.otherValue !== '' : false
  )

  function toggleOther() {
    setOtherItemEnabled(!otherItemEnabled)
  }
  function selectOption(value: SelectOption<number>) {
    if (!props.selectedItems || !props.setSelectedItems) return
    if (!selectedItems.includes(value.id))
      props.setSelectedItems([...props.selectedItems, value])
    else {
      var newArray = props.selectedItems.filter((a) => a.id !== value.id)
      props.setSelectedItems([...newArray])
    }
  }
  function toggleAll() {
    var selectAll = props.selectedItems?.length !== props.items.length
    if (!props.setSelectedItems) return
    if (selectAll) props.setSelectedItems(props.items)
    else props.setSelectedItems([])
  }

  const selectedItems = props.selectedItems.map((i) => i.id)

  return (
    <FormControl variant="filled" fullWidth={props.fullWidth} sx={{ mt: 1 }}>
      <FormLabel>{props.label}</FormLabel>
      <List
        sx={{
          width: '100%',
          maxWidth: '100%',
          bgcolor: 'transparent',
          display: props.flex ? 'inline-flex' : 'block',
          flexWrap: props.flex ? 'wrap' : 'nowrap',
        }}
      >
        {props.allOption && (
          <CheckListItem
            key={'All'}
            flex={props.flex}
            disabled={props.disabled}
            name="All"
            checked={props.selectedItems?.length === props.items.length}
            onClick={toggleAll}
            text="All"
          />
        )}
        {props.items.map((value) => (
          <CheckListItem
            key={value.name}
            flex={props.flex}
            disabled={props.disabled}
            name={`checkbox-list-label-${value.name}`}
            onClick={() => selectOption(value)}
            checked={selectedItems.includes(value.id) ?? false}
            text={value.name}
          />
        ))}

        {props.otherOption && (
          <CheckListItem
            key={'Other'}
            flex={props.flex}
            disabled={props.disabled}
            name="Other"
            checked={otherItemEnabled}
            onClick={toggleOther}
            text="Other"
            hasTextArea={true}
            textAreaValue={props.otherValue}
            setTextAreaValue={props.setOtherValue}
          />
        )}
      </List>
    </FormControl>
  )
}

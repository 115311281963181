import { Typography } from '@mui/material'
import { useParams } from 'react-router'
import { AddEditSchoolForm } from '../../components/forms/add-edit-school'
import { School } from '../../core/types'
import { useFetch } from '../../hooks/use-fetch'

export const EditDistrictSchool = () => {
  const { id: schoolId } = useParams()

  const { response } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/School/Get?id=${schoolId}`
  )

  const school = response as School | null
  if (!schoolId || !school) return <></>

  return (
    <div>
      <Typography variant="h2">Edit School</Typography>

      <AddEditSchoolForm mode="edit" school={school} />
    </div>
  )
}

import { useNavigate, useParams } from 'react-router'
import { FollowUp } from '../../../core/types'
import { Button, IconButton, Box } from '@mui/material'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../paged-table/simple-paged-table'
import * as AppRoutes from '../../../routes'
import { generatePath } from 'react-router'
import EditIcon from '@mui/icons-material/Edit'
import { dateRenderer } from '../../paged-table/renderers'

type FollowUpResults = Pick<FollowUp, 'id' | 'incidentID' | 'dateOf' | 'notes'>
type FollowUpColumn = SimpleColumn<FollowUpResults>

const columns: FollowUpColumn[] = [
  {
    field: 'dateOf',
    headerName: 'Date Of',
    renderField: dateRenderer,
  },
  {
    field: 'notes',
    headerName: 'Notes',
  },
]
const serverUrl = process.env.REACT_APP_SERVER_URL

type Props = {
  followUps: FollowUp[]
}
export function FollowUpGrid(props: Props) {
  const { id } = useParams()
  const nav = useNavigate()

  const addFollowUp = () => {
    if (!id) return <></>
    nav(
      generatePath(AppRoutes.INCIDENT_FOLLOW_UP_ADD_PATH, {
        id: id,
      })
    )
  }

  return (
    <Box maxWidth={'400px'}>
      <Button variant="contained" onClick={addFollowUp}>
        Add
      </Button>
      <SimplePagedTable
        columns={columns}
        rows={props.followUps}
        disableSearch
        rowActionCell={(row) => <ViewFollowUpButton id={row.id} />}
      />
    </Box>
  )
}

const ViewFollowUpButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(AppRoutes.INCIDENT_FOLLOW_UP_EDIT_PATH, {
      id: props.id.toString(),
    })}
  >
    <EditIcon />
  </IconButton>
)

import Box from '@mui/material/Box'
import { useParams } from 'react-router'
import { EmailSentLog, User } from '../../core/types'
import { useFetch } from '../../hooks/use-fetch'
import { dateTimeRenderer } from '../paged-table/renderers'
import { SimplePagedTable } from '../paged-table/simple-paged-table'

export const EmailLog = () => {
  const { id } = useParams()
  const { response } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/EmailSentLog/GetByDistrict?districtID=${id}`
  )

  const emailLog = response as EmailSentLog[] | null
  if (!emailLog) return <></>

  return (
    <Box sx={{ marginTop: '1rem', maxWidth: '600px' }}>
      <SimplePagedTable
        disableSearch
        disableSort
        rows={emailLog}
        columns={[
          {
            headerName: 'When Sent',
            field: 'whenSent',
            renderField: dateTimeRenderer,
          },
          {
            headerName: 'Sent To',
            field: 'user',
            renderField: (u: User) => (
              <>
                {u.firstName} {u.lastName}
              </>
            ),
          },
          { headerName: 'Type', field: 'type' },
        ]}
      />
    </Box>
  )
}

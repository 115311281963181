import { PermissionsService } from '../services/permissions-service'
import { useAuth } from './use-auth'

export function usePermissions() {
  const { user, loading } = useAuth()
  if (!loading && user) {
    return {
      canAddEditUser: PermissionsService.canAddEditUser(user),
      canViewAdminPages: PermissionsService.canViewAdminPages(user),
      canViewTrainingPages: PermissionsService.canViewTraining(user),
      canViewIncidentManagementAndReports:
        PermissionsService.canViewIncidentManagementAndReports(user),
      canViewReports: PermissionsService.canViewReports(user),
      canManageReports: PermissionsService.canManageReports(user),
      canImpersonate: PermissionsService.canImpersonate(user),
      canViewDistricts: PermissionsService.canViewDistricts(user),
      canManageDistricts: PermissionsService.canManageDistricts(user),
      canSeeOtherDistrictReports: PermissionsService.canManageDistricts(user),
      canAddDistrictAdmin: PermissionsService.canManageDistricts(user),
      canEditDistrictInformation:
        PermissionsService.canEditDistrictInformation(user),
      canViewUserSummaryReport:
        PermissionsService.canViewUserSummaryReport(user),
      canEditDistrictsDataSecurity:
        PermissionsService.canEditDistrictsDataSecurity(user),
      canEditDistrictsUsers: PermissionsService.canEditDistrictsUsers(user),
      canEditDistrictsSchools: PermissionsService.canEditDistrictsSchools(user),
      canAddDistrictsSchools: PermissionsService.canAddDistrictsSchools(user),
      canViewIncidentDistrictInfo: PermissionsService.canViewDistricts(user),
      canImportIncidents: PermissionsService.canConfigureDistricts(user),
    }
  }
  return null
}

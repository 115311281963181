import { Button, IconButton, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { SafetyPlan } from '../../../core/types'
import { sendRequest, useFetch } from '../../../hooks/use-fetch'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../paged-table/simple-paged-table'
import * as AppRoutes from '../../../routes'
import EditIcon from '@mui/icons-material/Edit'
import PrintIcon from '@mui/icons-material/Print'
import { dateRenderer } from '../../paged-table/renderers'

type Props = {
  id: number
}
type PlanColumn = SimpleColumn<SafetyPlan>

export function SafetyPlanList(props: Props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL

  let { response: resp } = useFetch(
    `${serverUrl}/Incident/SuccessPlanSummary?id=${props.id}`
  )
  const plans = (resp ?? []) as SafetyPlan[]
  let x = plans.sort((p1, p2) => {
    if (p1.dateCompleted && p2.dateCompleted) {
      if (p1.dateCompleted > p2.dateCompleted) return 1
      else if (p1.dateCompleted < p2.dateCompleted) return -1
    }
    return 0
  })

  const columns: PlanColumn[] = [
    {
      field: 'dateCompleted',
      headerName: 'Date Of',
      renderField: dateRenderer,
    },
    { field: 'type', headerName: 'Type' },

    {
      field: 'status',
      headerName: 'Status',
      renderField: (g) => (g === plans[0] ? 'Current' : 'Previous'),
    },
  ]
  const nav = useNavigate()
  const createPlan = () => {
    nav(
      generatePath(AppRoutes.SAFETY_PLAN_ADD_PATH, { id: props.id.toString() })
    )
  }
  const createDocument = () => {
    nav(
      generatePath(AppRoutes.SAFETY_PLAN_DOCUMENT_ADD_PATH, {
        id: props.id.toString(),
      })
    )
  }
  const onSelectPlan = (id: number, type: string) => {
    let path =
      type === 'Form'
        ? AppRoutes.SAFETY_PLAN_EDIT_PATH
        : AppRoutes.SAFETY_PLAN_DOCUMENT_EDIT_PATH
    nav(
      generatePath(path, {
        id: id.toString(),
      })
    )
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Button variant="contained" onClick={createPlan}>
        Enter Form
      </Button>
      <Button variant="contained" onClick={createDocument} sx={{ ml: 2 }}>
        Upload Document
      </Button>
      <Box sx={{ maxWidth: '400px' }}>
        <SimplePagedTable
          columns={columns}
          rows={plans}
          disableSearch
          rowActionCell={(row) => {
            const downloadbutton = (
              <DownloadButton id={row.id} fileName={row.fileName} />
            )
            const editButton = (
              <EditSafetyPlanButton
                id={row.id}
                onClick={() => onSelectPlan(row.id, row.type)}
              />
            )

            return (
              <Box display={'inline-flex'}>
                {row.type == 'Upload' && downloadbutton}
                {editButton}
              </Box>
            )
          }}
        />
      </Box>
    </Box>
  )
}
const DownloadButton = (props: { id: number; fileName: string }) => {
  const getFile = React.useCallback(async () => {
    const { response: resp } = await sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/SafetyPlanDocument/DownloadFile?id=${props.id}`
    )
    const { response: resp2 } = await sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/SafetyPlanDocument/GetFileName?id=${props.id}`
    )

    let blob = await resp?.blob()
    let fName = await resp2?.json()
    if (blob !== undefined) {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fName)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    }
  }, [props])
  return (
    <IconButton onClick={getFile}>
      <PrintIcon />
    </IconButton>
  )
}
const EditSafetyPlanButton = (props: {
  id: number
  onClick: (id: number) => void
}) => {
  const handleClick = React.useCallback(() => props.onClick(props.id), [props])
  return (
    <IconButton onClick={handleClick}>
      <EditIcon />
    </IconButton>
  )
}

import { useAuth } from '../../../hooks/use-auth'
import { useFetch } from '../../../hooks/use-fetch'
import { IconButton, Typography } from '@mui/material'
import { PermissionRequest } from '../../../core/types'
import {
  PagedTable,
  Props as PagedTableProps,
} from '../../../components/paged-table'
import { generatePath } from 'react-router'
import * as AppRoutes from '../../../routes'
import EditIcon from '@mui/icons-material/Edit'

type PermissionColumn = PagedTableProps<PermissionRequest>['columns']
const columns: PermissionColumn = [
  { field: 'incidentID', headerName: 'Incident', sortBy: 'incidentID' },
  {
    field: 'incident',
    headerName: 'Student',
    sortBy: 'student',
    renderField: (s) =>
      s && s.student && `${s.student.firstName} ${s.student.lastName}`,
  },
  {
    field: 'user',
    headerName: 'Requesting User',
    sortBy: 'userID',
    renderField: (u) => u && `${u.firstName} ${u.lastName}`,
  },
  { field: 'requestedOn', headerName: 'Date', sortBy: 'requestedOn' },
  { field: 'details', headerName: 'Details', sortBy: 'details' },
  {
    field: 'granted',
    headerName: 'Request Status',
    sortBy: 'granted',
    renderField: (g) => (g != null ? (g ? 'Approved' : 'Denied') : 'Pending'),
  },
]
export function PermissionRequestList() {
  return (
    <>
      <Typography variant="h2">Data Sharing Requests</Typography>
      <PagedTable
        columns={columns}
        queryFor={'PermissionRequest'}
        disableSearch
        rowActionCell={(row) => <ViewRequestButton id={row.id} />}
      />
    </>
  )
}
const ViewRequestButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(AppRoutes.INCIDENT_PERMISSION_REVIEW_PATH, {
      id: props.id.toString(),
    })}
  >
    <EditIcon />
  </IconButton>
)

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableProps,
  TableRow,
  TableSortLabel,
  TextField,
  Checkbox,
  IconButton,
} from '@mui/material'
import React, { ChangeEvent } from 'react'
import PrintIcon from '@mui/icons-material/Print'
export type SortDirection = 'asc' | 'desc'
export const toggleSortDirection = (
  currentDir: SortDirection
): SortDirection => {
  return currentDir === 'asc' ? 'desc' : 'asc'
}
type SortableColumn<T> = {
  field: keyof T
  headerName: string
  sortBy?: string
  renderField?: (value: any) => React.ReactNode
  colType?: string
}
type Props<T extends { id: number }> = {
  name?: string
  data: T[]
  columns: SortableColumn<T>[]
  recordCount: number
  page: number
  pageSize: number
  sortField: keyof T
  sortDirection: SortDirection
  onSort: (headerId: keyof T, colType:string) => void
  onPageChange: (event: unknown, newPage: number) => void
  onPageSizeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  rowActionCell?: (row: T) => React.ReactNode
  onSearch: (searchValue: string) => void
  disableSearch?: boolean
  disableSort?: boolean
  enablePrint?: boolean
  print?: () => void
} & (
  | { enableSelect?: false }
  | {
      enableSelect: true
      handleSelectAllClick: (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
      ) => void
      onSelect: (id: number) => void
      selectedItems: number[]
      isSelected: (id: number) => boolean
      numSelected: number
    }
)

export function PagedTableUI<T extends { id: number }>(props: Props<T>) {
  const {
    columns,
    data,
    name,
    sortDirection,
    rowActionCell,
    enablePrint,
    print,
  } = props

  return (
    <div>
      {enablePrint && (
        <Box>
          <IconButton onClick={print}>
            <PrintIcon />
          </IconButton>
        </Box>
      )}
      {!props.disableSearch && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '1rem 0',
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            onChange={(event) => props.onSearch(event?.target.value)}
          />
        </Box>
      )}
      <TableContainer>
        <Table size={'small'} aria-label={`${name} list`}>
          <TableHead>
            <TableRow>
              {props.enableSelect && (
                <TableCell>
                  <Checkbox
                    color="primary"
                    indeterminate={
                      props.numSelected > 0 &&
                      props.numSelected < props.recordCount
                    }
                    checked={
                      props.recordCount > 0 &&
                      props.numSelected === props.recordCount
                    }
                    onChange={props.handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all',
                    }}
                  />
                </TableCell>
              )}

              {columns.map((col) => {
                const headerId = col.field
                const colType = col.colType ?? 'string'
                const sortingBy = props.sortField === headerId
                return (
                  <TableCell
                    key={col.headerName}
                    align="left"
                    sx={{ verticalAlign: 'bottom' }}
                    sortDirection={sortingBy ? sortDirection : false}
                  >
                    {props.disableSort ? (
                      col.headerName
                    ) : (
                      <TableSortLabel
                        active={sortingBy}
                        direction={sortingBy ? sortDirection : 'desc'}
                        onClick={() => props.onSort(headerId, colType)}
                      >
                        {col.headerName}
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              })}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => {
              const isSelected = props.enableSelect
                ? props.isSelected(row.id)
                : false
              return (
                <TableRow key={row.id}>
                  {props.enableSelect && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected}
                        onClick={() => {
                          return props.onSelect(row.id)
                        }}
                        inputProps={{
                          'aria-labelledby': row.id?.toString() ?? '',
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map((col) => {
                    const value = row[col.field] as React.ReactNode
                    return (
                      <TableCell align="left" key={col.field.toString()}>
                        {col.renderField ? col.renderField(value) : value}
                      </TableCell>
                    )
                  })}

                  {rowActionCell && (
                    <TableCell align="center">{rowActionCell(row)}</TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.recordCount}
        rowsPerPage={props.pageSize}
        page={props.page}
        onPageChange={props.onPageChange}
        onRowsPerPageChange={props.onPageSizeChange}
      />
    </div>
  )
}

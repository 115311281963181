import { useParams } from 'react-router'
import { FollowUpDisplay } from '../../../components/incident/follow-up/display'
import { FollowUp } from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'

export function FollowUpEdit() {
  const { id } = useParams()

  const { response } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/FollowUp/Get?id=${id}`
  )
  const followUp = response as FollowUp | null

  const { response: nameResponse } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/GetStudentName?incidentID=${
      followUp?.incidentID ?? 0
    }`
  )
  const studentName = nameResponse as unknown as { name: string }
  if (!followUp || !studentName) return <></>

  return (
    <FollowUpDisplay
      type="Edit"
      followUp={followUp}
      studentName={studentName.name}
    />
  )
}

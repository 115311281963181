import React from 'react'
import ReactDOM from 'react-dom'

// Include the react-fusioncharts component
import ReactFC from 'react-fusioncharts'

// Include the fusioncharts library
import FusionCharts from 'fusioncharts'

// Include the chart type
import Column2D from 'fusioncharts/fusioncharts.charts'

// Include the theme as fusion
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'

import {Box} from '@mui/material'
// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme)



export function ConfigureColumnChart(
  caption,
  data,
  options,
  chartType = 'bar2d'
) {
  // chartDiv.style.display = 'inline-block'
  const chartConfigs = {
    type: chartType, // The chart type
    width: '700', // Width of the chart
    height: '400', // Height of the chart
    dataFormat: 'json', // Data type
    dataSource: {
      chart: {
        caption: caption,
        theme: 'fusion',
      },
      data: data,
    },
  }
  return <ReactFC {...chartConfigs} />


}
export function CreateTable(renderAt, caption, data) {

  var dat = data
  var rows = []
  dat.forEach((element) => {
    var cell = <td className='d-8'>{element.label}</td>
    var cell2 = <td className='d-2'>{element.value}</td>
    let row = <tr>{cell}{cell2}</tr>
    rows.push(row)
  })


  var tab = <table className='container-fluid'><tbody>{rows}</tbody></table>

  var header = <h3>{caption}</h3>
  var tableDiv = <div className='tableDiv'>{tab}</div>
  var overallDiv = <div className='chartContianerDiv'>{header}{tableDiv}</div>

  var chartDiv = <Box component="div" id={renderAt}>{overallDiv}</Box>

  return chartDiv;
}
function PrintCharts() {
  window.print() //maybe dont even use the fancy thing, just do window print
}

export function ClearCharts() {
  for (var chart in FusionCharts.items) {
    chart = FusionCharts.items[chart]
    chart.options.containerElement.style.display = 'None'
    chart.dispose()
  }
  var x = document.getElementsByClassName('chart')
  var y = document.getElementsByClassName('doubleChart')
  Array.from(x).forEach((c) => {
    c.innerHTML = ''
    c.style.display = 'none'
  })
  Array.from(y).forEach((c) => {
    c.innerHTML = ''
    c.style.display = 'none'
  })
}

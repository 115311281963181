import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { generatePath } from 'react-router'
import { County, District } from '../../core/types'
import { usePermissions } from '../../hooks/use-permissions'
import {
  DISTRICT_ADD_DISTRICT_SCHOOL_PATH,
  DISTRICT_EDIT_SCHOOL_PATH,
} from '../../routes'
import { SimplePagedTable } from '../paged-table/simple-paged-table'

type Props = {
  district: District
}
export const DistrictSchools = (props: Props) => {
  const { district } = props
  const permissions = usePermissions()

  return (
    <Box sx={{ m: '1rem 0', maxWidth: '600px' }}>
      {permissions?.canEditDistrictsSchools && (
        <Button
          variant="contained"
          href={generatePath(DISTRICT_ADD_DISTRICT_SCHOOL_PATH, {
            id: district.id.toString(),
          })}
        >
          Add
        </Button>
      )}

      <SimplePagedTable
        rows={district.schools}
        rowActionCell={
          permissions?.canEditDistrictsSchools
            ? (row) => <EditSchoolButton id={row.id} />
            : undefined
        }
        columns={[
          { field: 'code', headerName: 'Code' },
          { field: 'name', headerName: 'Name' },
          {
            field: 'county',
            headerName: 'County',
            renderField: (c: County | null) => c && c.name,
          },
          {
            field: 'closed',
            headerName: 'Closed',
            renderField: (closed: boolean) => (closed ? 'Yes' : 'No'),
          },
        ]}
      />
    </Box>
  )
}
const EditSchoolButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(DISTRICT_EDIT_SCHOOL_PATH, {
      id: props.id.toString(),
    })}
  >
    <EditIcon />
  </IconButton>
)

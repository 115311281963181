import { Box, Button, Dialog, Typography } from '@mui/material'
import { useState } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { IncidentDisplay } from '.'
import { Incident } from '../../../core/types'
import { useAuth } from '../../../hooks/use-auth'
import * as AppRoutes from '../../../routes'
import { ShareIncident } from '../history/share-incident'
import { useFetch } from '../../../hooks/use-fetch'
import { SelectOption } from '../../forms/select-with-label'

type Props = {
  incident: Incident
}
export function IncidentView(props: Props) {
  const triggerEvents = props.incident.triggerEvents ?? []
  const actions = props.incident.actionsTaken ?? []
  const role = props.incident.initialResponderRoleID?.toString() ?? ''
  const sources = props.incident.learnedFromSources ?? []
  const { response: responderRoles } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/InitialResponderRole/GetLookup`
    )
  const roleOptions = (responderRoles ?? []) as SelectOption<number>[]
  const initialResponderRoles = roleOptions.map((o) => {
    return { id: o.id.toString(), name: o.name }
  })
  if (initialResponderRoles.filter((o) => o.id === '0').length === 0)
  initialResponderRoles.push({ id: '0', name: 'Other' })
  const roleDisplay = initialResponderRoles.filter((o) => o.id === role)[0]?.name ??''



  const { loading, user } = useAuth()
  const canShare =
    !props.incident.anonymousStudent &&
    user?.id === props.incident.user?.id &&
    props.incident.school?.district?.privateIncidentAccess &&
    props.incident.school?.district?.allowIncidentSharing

  const navigate = useNavigate()
  if (loading) return <></>
  function Edit() {
    if (props.incident.id.toString() !== '')
      navigate(
        generatePath(AppRoutes.INCIDENT_EDIT_PATH, {
          id: props.incident.id.toString(),
        })
      )
  }
const l2ResponderName = props.incident.l2ResponderUserID === 0 ? 'Other' : `${props.incident.l2Responder?.firstName} ${props.incident.l2Responder?.lastName}`
  return (
    <>
      <Button onClick={Edit} variant="contained">
        Edit
      </Button>
      {canShare && (
        <ShareIncident
          districtId={props.incident.school?.district?.id ?? 0}
          incidentId={props.incident.id}
        />
      )}
      <IncidentDisplay
        accommodationOptions={[
          props.incident.accommodation ?? { id: 0, name: 'none' },
        ]}
        actionItems={actions}
        l2ResponderOptions={[
          {
            id: props.incident.l2Responder?.id?.toString() ?? '0',
            name: l2ResponderName,
          },
        ]}
        riskLevelOptions={[props.incident.riskLevel ?? { id: 0, name: 'none' }]}
        initialResponderRoleOptions={[  { id: role, name: roleDisplay }]}
        sourceItems={sources}
        triggerEvents={triggerEvents}
        type={'View'}
        incident={props.incident}
        integrationEnabled={false}
      />
    </>
  )
}

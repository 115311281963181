import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ValueWithHeader } from '../../components/header-with-value'
import { District } from '../../core/types'
import { sendRequest } from '../../hooks/use-fetch'

type DataSecurity = {
  privateIncidentAccess: boolean
  enforceAnonymousLogging: boolean
  allowIncidentSharing: boolean
}
export const EditDistrictDataSecurity = () => {
  const navigation = useNavigate()
  const { id } = useParams()
  const url = `${process.env.REACT_APP_SERVER_URL}/District/Get?id=${id}`

  const [district, setDistrict] = useState<District | null>(null)
  const [dataSecurity, setDataSecurity] = useState<DataSecurity>()
  const fetchDistrict = useCallback(async () => {
    const { response } = await sendRequest(url)
    const district = (await response?.json()) as District | null

    if (district) {
      setDistrict(district)
      setDataSecurity({
        allowIncidentSharing: district.allowIncidentSharing,
        enforceAnonymousLogging: district.enforceAnonymousLogging,
        privateIncidentAccess: district.privateIncidentAccess,
      })
    }
  }, [url])

  useEffect(() => {
    fetchDistrict()
  }, [fetchDistrict])

  const onSave = useCallback(async () => {
    const { success } = await sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/District/Update`,
      {
        method: 'PUT',
        body: JSON.stringify({ ...district, ...dataSecurity }),
      }
    )
    if (success) {
      navigation(-1)
    }
  }, [district, dataSecurity, navigation])

  const goBack = useCallback(() => {
    navigation(-1)
  }, [navigation])

  if (!dataSecurity) return <></>
  return (
    <div>
      <Typography variant="h2">
        Edit Data Security for {district?.name}
      </Typography>

      <Box sx={{ margin: '1rem 0' }}>
        <ValueWithHeader
          header="Private Incident Access"
          value={
            <Checkbox
              checked={dataSecurity?.privateIncidentAccess}
              onChange={(_e, checked) =>
                setDataSecurity({
                  ...dataSecurity,
                  privateIncidentAccess: checked,
                })
              }
            />
          }
        />
        <ValueWithHeader
          header="Enforce Anonymous Logging"
          value={
            <Checkbox
              checked={dataSecurity?.enforceAnonymousLogging}
              onChange={(_e, checked) =>
                setDataSecurity({
                  ...dataSecurity,
                  enforceAnonymousLogging: checked,
                })
              }
            />
          }
        />
        {dataSecurity?.privateIncidentAccess && (
          <ValueWithHeader
            header="Allow Incident to be shared with permission"
            value={
              <Checkbox
                checked={dataSecurity?.allowIncidentSharing}
                onChange={(_e, checked) =>
                  setDataSecurity({
                    ...dataSecurity,
                    allowIncidentSharing: checked,
                  })
                }
              />
            }
          />
        )}
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{ marginRight: '1rem' }}
          onClick={onSave}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={goBack}>
          Cancel
        </Button>
      </Box>
    </div>
  )
}

import { DistrictInfo } from './district-summary-report'
import { User } from '../../core/types'

export function generateDistrictStatusPDF(props: { printInfo: DistrictInfo, sortFunction: (a: User, b: User) => number }) {
   
  
  const content = GeneratePDFContent(props.printInfo, props.sortFunction)
  const header = GeneratePDFHeader()

 
  const all = {
    //watermark = new { text = "Confidential", opacity = 0.2 },
    content,
    header,
    info: {
      title: 'MiSafeStudent District Report',
    },
    styles: {
      header: { fontSize: 18, bold: true, margin: [15, 15, 0, 15] },
      header3: { fontSize: 14, bold: true, margin: [0, 15, 0, 15] },
      largerMarginSides: { margin: [30, 0, 30, 0] },
      mediumMarginTop: { margin: [0, 15, 0, 0] },
      smallMarginTop: { margin: [0, 5, 0, 0] },
      smallMarginBottom: { margin: [0, 0, 0, 5] },
      label: { italics: true, fontSize: 11, color: 'dimgray' },
      tableRow: { fontSize: 10 },
      smallRow: { fontSize: 8 },
      tableHeader: { fontSize: 14, bold: true },
    },
    footer: setFooter,
    pageBreakBefore: pageBreakBefore,
  }
  return all
}
const setFooter = (currentPage: number, pageCount: number) => {
  return {
    columns: [
      {
        text: currentPage.toString() + ' of ' + pageCount,
        alignment: 'right',
      },
    ],
    style: 'margin2',
    fontSize: 11,
  }
}
const pageBreakBefore = (
  currentNode: { headlineLevel: number },
  followingNodesOnPage: object[],
  nodesOnNextPage: number,
  previousNodesOnPage: number
) => {
  return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
}
function GeneratePDFHeader() {
  let columns: object[] = []
  columns.push({
    text: `MiSafeStudent District Report`,
    style: 'header3',
    margin: 20,
  })
  columns.push({
    text: `Date Report Run: ${new Date().toDateString()}`,
    style: 'header3',
    margin: 20,
    alignment: 'right',
  })
  let o = { columns: columns, style: 'margin2' }
  return o
  // return new { text = $"MiSafeStudent District Report", style = "header3" };
}
function GeneratePDFContent(printInfo: DistrictInfo, sortFunction:(a: User, b: User) => number) {
  let content: object[] = []
  content.push({ text: `District Name: ${printInfo.name}`, style: 'tableRow' })
  content.push({ text: `District Code: ${printInfo.code}`, style: 'tableRow' })
  content.push({ text: 'Security Settings', style: 'header3' })
  content.push({
    text: `Enable Data Exchange Integration via MiLaunchPad: ${printInfo.integrationEnabled}`,
    style: 'tableRow',
  })
  content.push({
    text: `Private Incident Access: ${printInfo.privateIncidentAccess}`,
    style: 'tableRow',
  })
  content.push({
    text: `Allow Incidents to be shared with permission: ${printInfo.allowIncidentSharing}`,
    style: 'tableRow',
  })
  content.push({
    text: `Enforce anonymous logging: ${printInfo.enforceAnonymousLogging}`,
    style: 'tableRow',
  })
  content.push({ text: 'Users', style: 'header3' })
  let TableColumns: object[][] = []

  let userHeaders: object[] = []
  userHeaders.push({ text: 'Last Name', style: 'tableHeader' })
  userHeaders.push({ text: 'First Name', style: 'tableHeader' })
  userHeaders.push({ text: 'Email', style: 'tableHeader' })
  userHeaders.push({ text: 'Role', style: 'tableHeader' })
  userHeaders.push({ text: 'Last Login', style: 'tableHeader' })
  userHeaders.push({ text: 'Inactive', style: 'tableHeader' })
  userHeaders.push({ text: 'Schools', style: 'tableHeader' })
  TableColumns.push(userHeaders)
  printInfo.users.sort(sortFunction).map((user) => {
    let userData: object[] = []
    userData.push({ text: user.lastName, style: 'tableRow' })
    userData.push({ text: user.firstName, style: 'tableRow' })
    userData.push({ text: user.email, style: 'tableRow' })
    userData.push({ text: user.roleID, style: 'tableRow' })
    userData.push({
      text:
        user.lastLoginDate != null
          ? new Date(user.lastLoginDate).toDateString()
          : '',
      style: 'tableRow',
    })
    userData.push({ text: user.inactive, style: 'tableRow' })
    userData.push({
      text: user.schools.map((s) => s.name).join(),
      style: 'smallRow',
    })
    TableColumns.push(userData)
  })
  let tableBody = {
    body: TableColumns,
    headerRows: 1,
    dontBreakRows: true,
  }
  let table = { table: tableBody }
  content.push(table)
  return content
}

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { Role, RoleInfo } from '../../../core/types'

export const UserFunctionCheckboxes = (props: {
  selectedRole: Role | undefined
  selectedFunctions: string[]
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  roleInfo: RoleInfo[]
}) => {
  const { selectedFunctions, roleInfo, selectedRole, onChange } = props
  const relevantRoleInfo = roleInfo.find((role) => role.id === selectedRole)
  return (
    <FormControl component="fieldset" sx={{ margin: '10px 0' }}>
      <FormLabel component="legend">User Functions</FormLabel>
      <FormGroup>
        {relevantRoleInfo?.implicitFunctions.map((iFn) => (
          <FormControlLabel
            key={iFn}
            control={<Checkbox checked disabled value={iFn} />}
            label={iFn}
          />
        ))}

        {relevantRoleInfo?.explicitFunctions.map((fn) => (
          <FormControlLabel
            key={fn}
            control={
              <Checkbox
                checked={selectedFunctions.includes(fn)}
                value={fn}
                onChange={onChange}
              />
            }
            label={fn}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

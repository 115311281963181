import { Typography } from '@mui/material'
import { useParams } from 'react-router'
import { AddEditSchoolForm } from '../../components/forms/add-edit-school'

export const AddDistrictSchool = () => {
  const { id: districtId } = useParams()

  if (!districtId) return <></>
  return (
    <div>
      <Typography variant="h2">Add School</Typography>

      <AddEditSchoolForm mode="add" />
    </div>
  )
}

import {
  Box,
  Button,
  SelectChangeEvent,
  SwitchPropsColorOverrides,
  Typography,
} from '@mui/material'
import * as AppRoutes from '../../../routes'

import {
  Gender,
  Incident,
  RaceObject,
  Student,
  YesNoUnknown,
  ActionTaken,
  LearnedFromSource,
  TriggerEvent,
  User,
  School,
  RiskLevel,
  Accommodation,
} from '../../../core/types'
import { CheckList } from '../../check-list'
import { DataHubStudentSearchModal } from '../../data-hub-student-modal'
import { SelectWithLabel, SelectOption } from '../../forms/select-with-label'
import { TextFieldWithLabel } from '../../forms/text-field-with-label'
import {
  getManualStudentFormFields,
  ManualStudenEntryModal,
  ManualStudentFormFields,
} from '../../manual-student-entry-modal.tsx'
import { getRaceDisplay, getRaceFromStudent } from '../../race-select'
import { DatePickerWithLabel } from '../../forms/date-picker-with-label'
import { generatePath, useNavigate } from 'react-router'
import { sendRequest } from '../../../hooks/use-fetch'
import { MouseEvent, useCallback, useState } from 'react'
import { getAge } from './ageCalculation'
import { usePermissions } from '../../../hooks/use-permissions'
import FormLabel from '@mui/material/FormLabel'
import Input from '../../input'
import { TextAreaWithLabel } from '../../forms/text-area-with-label'

const serverUrl = process.env.REACT_APP_SERVER_URL
type Props = {
  incident: Incident
  actionItems: SelectOption<number>[]
  triggerEvents: SelectOption<number>[]
  sourceItems: SelectOption<number>[]
  accommodationOptions: SelectOption<number>[]
  l2ResponderOptions: SelectOption<string>[]
  initialResponderRoleOptions: SelectOption<string>[]
  riskLevelOptions: SelectOption<number>[]
  integrationEnabled: boolean
} & (
  | {
      type: 'Edit' | 'Add'
      schoolOptions: SelectOption<number>[]
    }
  | {
      type: 'View'
    }
)
type errorResut = {
  title: string
  errors?: []
}
export function IncidentDisplay(props: Props) {
  const [formError, setFormError] = useState<string | undefined>()
  const [incident, setIncident] = useState({ ...props.incident })
  const permissions = usePermissions()

  const disabled = props.type === 'View'
  const iDate = new Date(incident.dateOf ?? '')
  let stu: ManualStudentFormFields = {
    race: {} as RaceObject,
    dateOfBirth: '',
    firstName: '',
    gender: '',
    lastName: '',
    uicCode: '',
  }
  if (!props.integrationEnabled) {
    stu = getManualStudentFormFields(incident)
  }
  const handleChange = (newValue: SelectChangeEvent<string | number>) => {
    setIncident({
      ...incident,
      [newValue.target.name]: newValue.target.value,
    })
  }

    const handleL1UserChange = (newValue: SelectChangeEvent<string>) => {
    let roleVal = parseInt(newValue.target.value.toString())
    setIncident({
      ...incident,
      initialResponderRoleID: roleVal !== undefined ? roleVal : undefined,
    })

  }

  const handleL2UserChange = (newValue: SelectChangeEvent<string>) => {
    let x = parseInt(newValue.target.value)
    setIncident({
      ...incident,
      l2ResponderUserID: !isNaN(x)? x : undefined,
      l2Responder: undefined
    })
  }
  const handleDayChanged = (newValue: Date | null) => {
    let dob = new Date(incident.student.dateOfBirth)
    if (newValue && dob) {
      setIncident({
        ...incident,
        dateOf: newValue,
        ageAtTimeOfIncident: getAge(newValue, dob),
      })
    }
  }
  const handleCollectionChanged = useCallback(
    (newList: SelectOption<number>[], target: string) => {
      setIncident({ ...incident, [target]: newList })
    },
    [incident]
  )
  const handleOtherChanged = useCallback(
    (value: string, target: string) => {
      setIncident({ ...incident, [target]: value })
    },
    [incident]
  )
  const handleRiskChanged = (evt: SelectChangeEvent<number>) => {
    let risk = props.riskLevelOptions.find((r) => r.id === evt.target.value)
    if (risk)
      setIncident({ ...incident, riskLevel: risk, riskLevelId: risk.id })
  }

  const handleAccommodationChanged = (evt: SelectChangeEvent<number>) => {
    let acc = props.accommodationOptions.find((r) => r.id === evt.target.value)
    if (acc)
      setIncident({
        ...incident,
        accommodation: acc,
        accommodationId: acc.id,
      })
  }

  const handleStudentLoaded = async (student: Student) => {
    if (student == null) return
    const { response, success } = await sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/School/Get?id=${student.currentSchoolID}`
    )
    if (success) {
      const school: School = await response?.json()
      setIncident({
        ...incident,
        anonymousPreferedGender: student.gender,
        anonymousStudent: false,
        student: student,
        studentID: student.id,
        grade: student.currentGrade?.toString() ?? '0',
        school: school,
        ageAtTimeOfIncident: getAge(
          incident.dateOf,
          new Date(student.dateOfBirth)
        ),
      })
    }
  }
  const handleStudentChanged = (studentData: ManualStudentFormFields) => {
    if (!disabled) {
      setIncident({
        ...incident,
        grade: studentData.grade ?? '',
        anonymousPreferedGender: studentData.gender,
        school: props.schoolOptions.filter(
          (s) => s.id === studentData.enrolledSchool
        )[0],
        ageAtTimeOfIncident: getAge(
          incident.dateOf,
          new Date(studentData.dateOfBirth)
        ),
        student: {
          africanAmericanOrBlack: studentData.race.africanAmericanOrBlack,
          americanIndianOrAlaskan: studentData.race.americanIndianOrAlaskan,
          asian: studentData.race.asian,
          dateOfBirth: studentData.dateOfBirth ?? '',
          firstName: studentData.firstName,
          gender: studentData.gender,
          hispanicOrLatino: studentData.race.hispanicOrLatino,
          id: incident.student.id,
          isAnonymous: false,
          lastName: studentData.lastName,
          nativeHawaiianOrPacificIslander:
          studentData.race.nativeHawaiianOrPacificIslander,
          uic: studentData.uicCode,
          white: studentData.race.white,
        },
      })
    }
  }

  const whenEntered = incident.whenEntered && new Date(incident.whenEntered)
  const raceArray = getRaceFromStudent(incident.student)
  const raceDisplay = getRaceDisplay(raceArray)
  const bday = new Date(incident.student.dateOfBirth)
  const month = bday.getUTCMonth() + 1
  const day = bday.getUTCDate()
  const year = bday.getUTCFullYear()

  const birthdateDisplay =
    !Number.isNaN(month) && !Number.isNaN(day) && !Number.isNaN(year)
      ? `${month}/${day}/${year}`
      : ''
  const yesNoUnknownOptions = [
    { id: '1', name: YesNoUnknown[1] },
    { id: '0', name: YesNoUnknown[0] },
    { id: '2', name: YesNoUnknown[2] },
  ]
  const genderOptions = [
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' },
    { id: 'U', name: 'Unknown' },
  ]

  const navigate = useNavigate()
  const onCancel = () => navigate(-1)

  const onSave = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    if (disabled) return

    const form = e.currentTarget.form
    if (!form?.reportValidity()) return

    let { user, riskLevel, accommodation, school, ...cleaned } = incident
    cleaned.userID = user?.id ?? 0
    cleaned.schoolID = school?.id ?? 0
    cleaned.riskLevelId = riskLevel?.id
    cleaned.accommodationId = accommodation?.id ?? 0
    cleaned.studentID = cleaned.student.id
    let { student, ...noStudent } = cleaned

    let submitValue = props.integrationEnabled ? noStudent : cleaned
    let isNew = props.type === 'Add'
    const url = `${serverUrl}/Incident/${!isNew ? 'Update' : 'Insert'}`
    const { response, success } = await sendRequest(url, {
      method: !isNew ? 'PUT' : 'POST',
      body: JSON.stringify(submitValue),
    })
    if (success) {
      let redirecId = props.incident.id
      if (isNew) redirecId = await response?.json()
      navigate(
        generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
          id: redirecId.toString(),
        }),
        { state: { updated: isNew } }
      )
    } else {
      const result: errorResut = await response?.json()
      let error = result.title + '\r\n'
      for (const property in result.errors) {
        error += `${property}: ${Reflect.get(
          result.errors ?? [],
          property
        )}\r\n`
      }

      result && setFormError(error)
    }
  }

  return (
    <Box component="form" minWidth={'md'}>
      {props.type !== 'View' && (
        <Typography color={'red'}>
          * Please fill in all required fields
        </Typography>
      )}
      {formError && (
        <li style={{ color: 'red', padding: '10px 0' }}>{formError}</li>
      )}

      <Typography variant="h6" fontWeight={'bold'}>
        Student Information
      </Typography>
      <Box
        id="studentInfo"
        minWidth="700px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderBottom: props.type !== 'View' ? '1px solid black' : 'none',
          ml: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Box width={'50%'} sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextFieldWithLabel
              name="name"
              required
              disabled={true}
              label="Name"
              value={
                incident.student?.isAnonymous
                  ? 'Anonymous Student'
                  : `${incident.student?.firstName} ${incident.student?.lastName}`
              }
              onChange={handleChange}
            />
            {!disabled && props.integrationEnabled && (
              <DataHubStudentSearchModal
                selectStudentFunction={handleStudentLoaded}
              />
            )}
            {!disabled && !props.integrationEnabled && (
              <ManualStudenEntryModal
                schoolList={props.schoolOptions}
                student={stu}
                saveButtonFunction={handleStudentChanged}
              />
            )}
          </Box>
          <Box width={'50%'}>
            <TextFieldWithLabel
              name="enrolledSchool"
              disabled={true}
              label="Enrolled School"
              value={incident.school?.name}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Box width={'50%'}>
            <TextFieldWithLabel
              name="anonymousPreferedGender"
              disabled={true}
              label="Gender (State reported preferred)"
              value={
                Gender[
                  incident.anonymousPreferedGender ?? incident.birthGender ?? ''
                ]
              }
              onChange={handleChange}
            />
          </Box>
          <Box width={'50%'}>
            <TextFieldWithLabel
              name="grade"
              disabled={true}
              label="Grade"
              value={incident.grade}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            borderBottom: props.type !== 'View' ? '1px solid black' : 'none',
          }}
        >
          <Box width={'50%'}>
            <TextFieldWithLabel
              name="dateOfBirth"
              disabled={true}
              label="Date of Birth"
              value={birthdateDisplay}
              onChange={handleChange}
            />
          </Box>
          <Box width={'50%'}>
            <TextFieldWithLabel
              name="race"
              disabled={true}
              label="Race/Ethnicity"
              value={raceDisplay}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <SelectWithLabel
              options={genderOptions}
              name="birthGender"
              width="200px"
              disabled={disabled}
              required
              label="Assigned gender at birth, per official document"
              value={incident.birthGender}
              onChange={handleChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
          <Box width={'50%'}>
            <SelectWithLabel
              width="200px"
              required
              options={yesNoUnknownOptions}
              name="preferredGenderDifferent"
              disabled={disabled}
              label="Is preferred gender different than gender assigned at birth"
              value={incident.preferredGenderDifferent?.toString()}
              onChange={handleChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <SelectWithLabel
              width="200px"
              required
              options={yesNoUnknownOptions}
              name="identifiesASLGBTQ"
              disabled={disabled}
              label="Student Identifies as LGBTQ+"
              value={incident.identifiesASLGBTQ?.toString()}
              onChange={handleChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>

          <Box width={'50%'}>
            <SelectWithLabel
              required
              options={yesNoUnknownOptions}
              name="familyInTransition"
              width="200px"
              disabled={disabled}
              label="Family in Transition"
              value={incident.familyInTransition?.toString()}
              onChange={handleChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <SelectWithLabel
              width="200px"
              required
              options={props.accommodationOptions}
              name="accommodationId"
              disabled={disabled}
              label="Has IEP or 504"
              value={incident.accommodationId}
              onChange={handleAccommodationChanged}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
           <Box width={'50%'}>
            <SelectWithLabel
              required
              options={yesNoUnknownOptions}
              name="previousKnowledge"
              width="200px"
              disabled={disabled}
              label="To your knowledge, has this student experienced a Blue Envelope Event already this school year?"
              value={incident.previousKnowledge?.toString()}
              onChange={handleChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <TextFieldWithLabel
              name="ageAtTimeOfIncident"
              disabled={true}
              label="Age at time of Incident"
              value={incident.ageAtTimeOfIncident}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
      <Typography variant="h5" fontWeight={'bold'} sx={{ pt: 2 }}>
        Incident Information
      </Typography>
      <Box
        id="incidentInfo"
        sx={{ display: 'flex', flexDirection: 'column', p: 1 }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <DatePickerWithLabel
              label="Date of Incident"
              name="dateOf"
              onChange={handleDayChanged}
              value={iDate}
              disabled={disabled}
            />
          </Box>
          <Box width={'50%'}>
            <TextFieldWithLabel
              required
              name="location"
              disabled={disabled}
              label="Location of Incident"
              value={incident.location}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
             <SelectWithLabel
              required
              options={props.initialResponderRoleOptions}
              name="initialResponderRoleID"
              width="200px"
              disabled={disabled}
              label="Initial Responder Role"
              value={incident.initialResponderRoleID?.toString()?? ''}
              onChange={handleL1UserChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            /> 
          </Box>
          <Box width={'50%'}>
              <TextFieldWithLabel
              name="initialResponder"
              disabled={disabled}
              label="Initial Responder Name"
              value={incident.initialResponder}
              onChange={handleChange}
            />

          </Box>
        </Box>

        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
              {(incident.initialResponderRoleID === 0 ) && (
                <TextFieldWithLabel
                  name="otherInitialResponderRole"
                  disabled={disabled}
                  label="Other Initial Responder Role"
                  value={incident.otherInitialResponderRole ?? ''}
                  onChange={handleChange}
                /> 
                )}

          
          </Box>
          <Box width={'50%'}>
              <SelectWithLabel
              required
              options={props.l2ResponderOptions}
              name="l2ResponderUserID"
              width="200px"
              disabled={disabled}
              label="Level 2 Responder"
              value={incident.l2ResponderUserID?.toString()}
              onChange={handleL2UserChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />

          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
              <SelectWithLabel
              maxWidth="350px"
              required
              options={props.riskLevelOptions}
              name="riskLevelId"
              disabled={disabled}
              label="CSSRS Risk Level"
              value={incident.riskLevelId}
              onChange={handleRiskChanged}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
          <Box width={'50%'}>
              {(incident.l2ResponderUserID !== undefined && incident.l2ResponderUserID=== 0) && (
              <TextFieldWithLabel
                name="otherL2Responder"
                disabled={disabled}
                label="Other Level 2 Responder"
                value={incident.otherL2Responder ?? ''}
                onChange={handleChange}
              />
            )}

          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <SelectWithLabel
              required
              options={yesNoUnknownOptions}
              name="alcoholDrugUse"
              width="200px"
              disabled={disabled}
              label="Drug/Alcohol Use?"
              value={incident.alcoholDrugUse?.toString()}
              onChange={handleChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
          <Box width={'50%'}>
            <SelectWithLabel
              options={yesNoUnknownOptions}
              width="200px"
              required
              name="outsidePlanInitiated"
              disabled={disabled}
              label="Was suicide intervention initiated in the community (outside of school)?"
              value={incident.outsidePlanInitiated?.toString()}
              onChange={handleChange}
              FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <CheckList
              fullWidth
              disabled={disabled}
              items={props.triggerEvents}
              selectedItems={incident.triggerEvents ?? []}
              setSelectedItems={(newList: SelectOption<number>[]) =>
                handleCollectionChanged(newList, 'triggerEvents')
              }
              otherOption={
                (props.type !== 'View' &&
                  incident.otherTriggerEvent !== null) ||
                (props.type === 'View' &&
                  (incident.otherTriggerEvent ?? '') !== '')
              }
              otherValue={incident.otherTriggerEvent ?? ''}
              setOtherValue={(e: any) =>
                handleOtherChanged(e.target.value, 'otherTriggerEvent')
              }
              label="Trigger Events"
            />
            <CheckList
              disabled={disabled}
              fullWidth
              items={props.sourceItems}
              setSelectedItems={(newList: SelectOption<number>[]) =>
                handleCollectionChanged(newList, 'learnedFromSources')
              }
              selectedItems={incident.learnedFromSources ?? []}
              otherOption={
                (props.type !== 'View' &&
                  incident.otherLearnedFromSource !== null) ||
                (props.type === 'View' &&
                  (incident.otherLearnedFromSource ?? '') !== '')
              }
              otherValue={incident.otherLearnedFromSource ?? ''}
              setOtherValue={(e: any) =>
                handleOtherChanged(e.target.value, 'otherLearnedFromSource')
              }
              label="Learned of Suicidal Thoughts From"
            />
          </Box>
          <Box width={'50%'}>
            <CheckList
              disabled={disabled}
              items={props.actionItems}
              selectedItems={incident.actionsTaken ?? []}
              setSelectedItems={(newList: SelectOption<number>[]) =>
                handleCollectionChanged(newList, 'actionsTaken')
              }
              otherOption={
                (props.type !== 'View' && incident.otherActionTaken !== null) ||
                (props.type === 'View' &&
                  (incident.otherActionTaken ?? '') !== '')
              }
              otherValue={incident.otherActionTaken ?? ''}
              setOtherValue={(e: any) =>
                handleOtherChanged(e.target.value, 'otherActionTaken')
              }
              label="Actions Taken by School"
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'100%'}>
            <TextAreaWithLabel
              name="notes"
              label={'Notes'}
              disabled={disabled}
              value={incident.notes}
              onChange={(e: any) => handleChange(e)}
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box width={'50%'}>
            <TextFieldWithLabel
              name="loggedBy"
              disabled
              label="Logged By"
              value={`${incident.user?.firstName} ${incident.user?.lastName}`}
              onChange={handleChange}
            />
          </Box>
          {props.type === 'View' && (
            <Box width={'50%'}>
              <TextFieldWithLabel
                name="whenEntered"
                disabled
                label="When Entered"
                value={`${whenEntered?.toLocaleDateString()} ${whenEntered?.toLocaleTimeString()}`}
                onChange={handleChange}
              />
            </Box>
          )}
        </Box>
        {permissions?.canViewIncidentDistrictInfo && props.type === 'View' && (
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box width={'50%'}>
              <TextFieldWithLabel
                name="importDate"
                disabled
                label="Import Date"
                value={incident.importDate ?? ''}
                onChange={handleChange}
              />
            </Box>
            <Box width={'50%'}>
              <TextFieldWithLabel
                name="districtName"
                disabled
                label="District"
                value={incident.school?.district?.name ?? ''}
                onChange={handleChange}
              />
            </Box>
          </Box>
        )}

        {!disabled && (
          <>
            <Box sx={{ marginTop: '14px' }}>
              <Button
                onClick={onSave}
                variant="contained"
                sx={{ width: '100px' }}
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                variant="outlined"
                sx={{ ml: 2, width: '100px' }}
              >
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

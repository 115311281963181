import { Box, FormLabel, InputProps, TextField } from '@mui/material'
import { on } from 'events'

type Props = {
  label: string
  name: string
  onChange?: (e: any) => void
  value: unknown
  required?: boolean
  disabled?: boolean
  multiline?: boolean
  maxWidth?: string
  inline?: boolean
}
export const TextFieldWithLabel = (props: Props) => {
  const variant = props.disabled ?? false ? 'standard' : 'outlined'
  let ip: InputProps = {}
  if (props.disabled) {
    ip.readOnly = true
    ip.disableUnderline = true
  }
  return (
    <Box
      sx={{
        pt: '8px',
        flexDirection: props.inline ? 'row' : 'column',
        display: 'flex',
      }}
    >
      <FormLabel
        required={props.required}
        htmlFor={props.name}
        sx={{ alignSelf: props.inline ? 'center' : '', whiteSpace: 'nowrap' }}
      >
        {props.label}
      </FormLabel>
      <TextField
        id={props.name}
        name={props.name}
        value={props.value}
        variant={variant}
        required={props.required}
        onChange={props.onChange}
        multiline={props.multiline}
        fullWidth
        minRows={2}
        sx={{
          border: 'none',
          boxShadow: 'none',
          maxWidth: props.maxWidth ? props.maxWidth : '350px',
          pt: 0,
          pl: 1,
        }}
        InputProps={ip}
      />
    </Box>
  )
}

import { Incident } from '../../../core/types'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../../components/paged-table/simple-paged-table'
import { Button, IconButton } from '@mui/material'
import { useFetch } from '../../../hooks/use-fetch'
import { generatePath, useNavigate } from 'react-router'
import * as AppRoutes from '../../../routes'
import EditIcon from '@mui/icons-material/Edit'
import { dateRenderer } from '../../../components/paged-table/renderers'

type IncidentHistoryResult = {
  dateOf: Date
  id: number
  location: string
  riskLevelName: string
  schoolName: string
  userName: string
}

type IncidentColumn = SimpleColumn<IncidentHistoryResult>

const columns: IncidentColumn[] = [
  {
    field: 'dateOf',
    headerName: 'Incident Date',
    renderField: dateRenderer,
  },
  {
    field: 'schoolName',
    headerName: 'School',
  },
  {
    field: 'location',
    headerName: 'Location',
  },
  {
    field: 'userName',
    headerName: 'Logged By',
  },
  {
    field: 'riskLevelName',
    headerName: 'Risk Level',
  },
]
type Props = {
  id: number
}
export function IncidentHistory(props: Props) {
  const { response: canRequestSharing, isLoading: load1 } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/CanRequestSharing?incidentID=${props.id}`
  )
  const { response: history, isLoading: load2 } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/IncidentHistory?incidentID=${props.id}`
  )
  const nav = useNavigate()
  if (load1 || load2) return <></>
  const records = (history ?? []) as IncidentHistoryResult[]

  const request = () => {
    nav(
      generatePath(`${AppRoutes.INCIDENT_PERMISSION_REQUEST_PATH}`, {
        id: props.id.toString(),
      })
    )
  }
  return (
    <>
      {canRequestSharing && (
        <Button onClick={request} variant="contained">
          Request Access to All Student Incidents
        </Button>
      )}
      <SimplePagedTable
        columns={columns}
        rows={records}
        disableSearch
        rowActionCell={(row) => <ViewIncidentButton id={row.id} />}
      />
    </>
  )
}

const ViewIncidentButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
      id: props.id.toString(),
    })}
  >
    <EditIcon />
  </IconButton>
)

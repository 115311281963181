import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { DataSecurity } from '../../components/district-details/data-security'
import { DistrictInformation } from '../../components/district-details/district-information'
import { EmailLog } from '../../components/district-details/email-log'
import { ImportIncident } from '../../components/district-details/import-incident'
import { DistrictSchools } from '../../components/district-details/schools'
import { DistrictUsers } from '../../components/district-details/users'
import { TabPanel } from '../../components/tab-panel'
import { District } from '../../core/types'
import { sendRequest } from '../../hooks/use-fetch'
import { usePermissions } from '../../hooks/use-permissions'

export const DistrictDetails = () => {
  const [currentTab, setCurrentTab] = React.useState(0)

  const handleChange = (_e: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const { id } = useParams()
  const permissions = usePermissions()
  const [district, setDistrict] = useState<District | null>(null)

  const url = `${process.env.REACT_APP_SERVER_URL}/District/Get?id=${id}&include=Schools.County,Users`

  const fetchDistrict = useCallback(async () => {
    const { response } = await sendRequest(url)
    const district = (await response?.json()) as District | null

    if (district) {
      setDistrict(district)
    }
  }, [url])

  useEffect(() => {
    fetchDistrict()
  }, [fetchDistrict])

  if (!district) return <></>

  return (
    <Box>
      <Typography variant="h2">{district.name}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs onChange={handleChange} value={currentTab}>
          <Tab value={0} label="District Information" />
          <Tab value={1} label="Data Security" />
          <Tab value={2} label="Schools" />
          <Tab value={3} label="Users" />
          <Tab value={4} label="Email Log" />
          {permissions?.canImportIncidents && (
            <Tab value={5} label="Import Incidents" />
          )}
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <DistrictInformation district={district} refetch={fetchDistrict} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <DataSecurity district={district} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <DistrictSchools district={district} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <DistrictUsers district={district} />
      </TabPanel>
      <TabPanel value={currentTab} index={4}>
        <EmailLog />
      </TabPanel>
      {permissions?.canImportIncidents && (
        <TabPanel value={currentTab} index={5}>
          <ImportIncident />
        </TabPanel>
      )}
    </Box>
  )
}

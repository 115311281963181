import VisibilityIcon from '@mui/icons-material/Visibility'
import { Button, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { generatePath } from 'react-router'
import {
  PagedTable,
  Props as PagedTableProps,
} from '../../components/paged-table'
import {
  dateRenderer,
  timeRenderer,
} from '../../components/paged-table/renderers'
import { District, Training, TrainingType } from '../../core/types'
import { usePermissions } from '../../hooks/use-permissions'
import * as AppRoutes from '../../routes'

type TrainingColumns = PagedTableProps<Training>['columns']

export const TrainingList = () => {
  let columns: TrainingColumns = [
    {
      field: 'date',
      headerName: 'Date',
      sortBy: 'Date',
      renderField: dateRenderer,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      sortBy: 'startTime',
      renderField: timeRenderer,
    },
    {
      headerName: 'School District',
      field: 'district',
      renderField: (d: District) => <>{d.name}</>,
      sortBy: 'District.Name',
    },
    {
      headerName: 'Training Type',
      field: 'trainingType',
      renderField: (d: TrainingType) => <>{d.name}</>,
      sortBy: 'TrainingType.Name',
    },
  ]

  return (
    <div>
      <Typography variant="h2">Training Events</Typography>
      <Box sx={{ paddingTop: '1rem' }}>
        <Button variant="contained" href={AppRoutes.TRAINING_ADD_PATH}>
          Add Training Event
        </Button>
      </Box>
      <PagedTable
        columns={columns}
        queryFor="Training"
        rowActionCell={(row) => <ViewTrainingButton id={row.id} />}
      />
    </div>
  )
}

const ViewTrainingButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(AppRoutes.TRAINING_EDIT_PATH, {
      id: props.id.toString(),
    })}
  >
    <VisibilityIcon />
  </IconButton>
)

import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useAuth } from '../../hooks/use-auth'

type Props = {
  title: string
  redirectUrl: string
  details?: string
  src?: string
  altImg?: string
}

export function LoginSection(props: Props) {
  const auth = useAuth()
  return (
    <Box
      sx={{
        width: '50%',
        backgroundColor: '#f9fbfc',
        border: '1px solid #ebedf1',
        mt: 3,
        mb: 3,
        pt: 1,
      }}
    >
      <Container sx={{ fontWeight: 'bold', p: 0 }}>{props.title}</Container>
      <Container sx={{ p: 0 }}>{props.details}</Container>
      <Button
        sx={{ m: 3, textTransform: 'none' }}
        href={props.redirectUrl}
        onClick={async () => {
          window.location.href = props.redirectUrl
          await auth.login()
        }}
        variant="contained"
        autoCapitalize="false"
      >
        Login with {props.title}
      </Button>
      {props.src && <img alt={props.altImg} src={props.src} />}
    </Box>
  )
}

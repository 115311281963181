import { Button, Typography, Box, SelectChangeEvent } from '@mui/material'
import { useState } from 'react'
import { generatePath } from 'react-router'
import { useNavigate, useParams } from 'react-router'
import { TextFieldWithLabel } from '../../../components/forms/text-field-with-label'
import { sendRequest, useFetch } from '../../../hooks/use-fetch'
import * as AppRoutes from '../../../routes'

type errorResut = {
  title: string
  errors?: []
}

export function RequestPermission() {
  const { id } = useParams()
  const [details, setDetails] = useState('')
  const [error, setError] = useState('')
  const nav = useNavigate()
  const { response: studentName, isLoading: loading } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/GetStudentName?incidentID=${id}`
  )
  if (loading) return <></>
  const stuName = (studentName ?? {}) as { name: string }
  const cancel = () => {
    nav(-1)
  }
  const handleChange = (event: SelectChangeEvent<string>) =>
    setDetails(event.target.value)

  const submit = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/Incident/SendShareRequestEmails`
    const { response, success } = await sendRequest(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, name: details }),
    })
    if (success) {
      const requests = await response?.json()
      if (requests == 0)
        setError('There were no incidents available for you to request access')
      else {
        nav(
          generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
            id: id?.toString() ?? '0',
          })
        )
      }
    } else {
      const result: errorResut = await response?.json()
      let error = result.title + '\r\n'

      for (const property in result.errors) {
        error += `${property}: ${Reflect.get(result.errors, property)}\r\n`
      }

      result && setError(error)
    }
  }
  return (
    <>
      {error && <Typography color={'red'}>{error}</Typography>}
      <Typography>Incident Request</Typography>
      <Typography>
        <>
          This action will contact all owners of incident data for this student{' '}
          ({stuName.name}) and request access to previous incidents. Are you
          sure you want to proceed?
        </>
      </Typography>
      <TextFieldWithLabel
        label="Request Details"
        name="details"
        value={details}
        onChange={handleChange}
        required
      />
      <Box>
        <Button variant="contained" onClick={submit}>
          Submit
        </Button>
        <Button variant="outlined" onClick={cancel} sx={{ ml: 2 }}>
          Cancel
        </Button>
      </Box>
    </>
  )
}

import {
  Box,
  Checkbox,
  FormControlLabel,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { FormActionButton } from '../../components/forms/form-action-button'
import { TextFieldWithLabel } from '../../components/forms/text-field-with-label'
import { District } from '../../core/types'
import { sendRequest } from '../../hooks/use-fetch'
import { DISTRICT_DETAILS_PATH } from '../../routes'

const serverUrl = process.env.REACT_APP_SERVER_URL

type DistrictFormFields = Pick<District, 'name' | 'closed'>
export const AddDistrict = () => {
  const initialState: DistrictFormFields = {
    name: '',
    closed: false,
  }
  const [formState, setFormState] = useState<DistrictFormFields>(initialState)
  const [formErrors, setFormErrors] = useState<string[] | undefined>()

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const navigate = useNavigate()
  const onCancel = () => navigate(-1)

  const onSave = async () => {
    setFormErrors(undefined)
    const url = `${serverUrl}/District/Insert`

    const { response, success } = await sendRequest(url, {
      method: 'POST',
      body: JSON.stringify(formState),
    })
    if (success) {
      const districtID = await response?.json()
      const districtPath = generatePath(DISTRICT_DETAILS_PATH, {
        id: districtID?.toString() ?? null,
      })
      navigate(districtPath)
    } else {
      const result = await response?.json()
      if (result) {
        const errors = (result as { errors: { [k: string]: string[] } }).errors
          ? Object.keys(result.errors)
              .map((k) => result.errors[k])
              .flat()
          : [result as string]

        setFormErrors(errors)
      }
    }
  }

  return (
    <div>
      <Typography variant="h2">Add District</Typography>
      {formErrors &&
        formErrors.map((err) => (
          <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
            {err}
          </li>
        ))}
      <Box component="form">
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
          <TextFieldWithLabel
            label="Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
          />
          <FormControlLabel
            label={'Closed'}
            sx={{ fontWeight: 'bold' }}
            labelPlacement="start"
            control={
              <Checkbox
                checked={formState.closed}
                value={formState.closed}
                onChange={(_e, checked) =>
                  setFormState({ ...formState, closed: checked })
                }
              />
            }
          />
        </Box>

        <Box sx={{ marginTop: '14px' }}>
          <FormActionButton onClick={onSave} variant="contained">
            Save
          </FormActionButton>
          <FormActionButton onClick={onCancel}>Cancel</FormActionButton>
        </Box>
      </Box>
    </div>
  )
}

import { useParams } from 'react-router'
import { SafetyPlanDocument } from '../../components/incident/safety-plan/document'
import { UploadSafetyPlan } from '../../core/types'
import { useFetch } from '../../hooks/use-fetch'
import { Typography, Box } from '@mui/material'

const serverUrl = process.env.REACT_APP_SERVER_URL

export function EditSafetyPlanDocument() {
  const { id } = useParams()
  let num: number = parseInt(id ?? '0')

  const { response: resp } = useFetch(
    `${serverUrl}/SafetyPlanDocument/Get?id=${id}`
  )
  const plan = (resp ?? {}) as UploadSafetyPlan
  const { response: studentNameResponse } = useFetch(
    `${serverUrl}/Incident/GetStudentName?incidentID=${plan.incidentID ?? 0}`
  )
  const studentName = (studentNameResponse ?? {}) as { name: string }
  if (plan.id === undefined) return <></>
  return (
    <Box>
      <Typography>
        <>Edit Safety Plan Document - {studentName.name}</>
      </Typography>
      <SafetyPlanDocument isEdit={true} plan={plan} />
    </Box>
  )
}

import { useLocation, useNavigate } from 'react-router'
import { LoginSection } from './loginSection'
import { useState } from 'react'
import miLaunchpadLogo from './poweredby_milaunchpad.png'

export function Login() {
  const { state } = useLocation()
  const [redirect] = useState(state?.returnUrl)
  window.history.replaceState({}, document.title)
  return (
    <>
      <h2>Login</h2>
      <LoginSection
        title="MiLaunchpad"
        details='Using your Local District Login information, select "Login with MiLaunchPad"'
        redirectUrl={`${
          process.env.REACT_APP_SERVER_URL
        }/account/LoginSSO?returnUrl=${process.env.REACT_APP_CLIENT_URL}${
          redirect != null ? redirect : ''
        }`}
        src={miLaunchpadLogo}
        altImg="Powered By MiLaunchpad"
      />
      <LoginSection
        title="MiCredentialsLogin"
        details="If you have been given credentials for MiCredentialsLogin, select Login with MiCredentialsLogin"
        redirectUrl={`${
          process.env.REACT_APP_SERVER_URL
        }/account/MiCredentialsSSO?returnUrl=${
          process.env.REACT_APP_CLIENT_URL
        }${redirect != null ? redirect : ''}`}
      />
    </>
  )
}

import { createTheme, Theme } from '@mui/material/styles'

const primaryActionBlue = '#1EBAE7'
export const mssTheme: Theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
          paddingBottom: '8px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#1ebae7',
          fontWeight: 'bold',
          fontSize: '0.775rem',
          '&.Mui-selected': {
            color: '#003658',
          },
          '&.MuiButtonBase-root': {
            padding: '0px 8px',
            minHeight: '30px',
            minWidth: '40px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: primaryActionBlue,
          color: primaryActionBlue,
        },
        contained: {
          backgroundColor: primaryActionBlue,
          color: 'white',
          borderRadius: '1.25em',
          textTransform: 'none',
        },
        outlined: {
          borderRadius: '1.25em',
          textTransform: 'none',
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          border: '1px solid #ced4da',
          height: '48px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '36px',
        },
        notchedOutline: {
          borderColor: '#ced4da',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          color: '#4a464e',
        },
        head: {
          color: '#454655',
          borderTop: 'none',
          borderBottom: '1px solid #454655',
          fontWeight: 'bold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#454655',
          fontWeight: 'inherit',
        },
        h2: {
          fontSize: '2.0em',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          display: 'contents',
        },
        primary: {
          lineHeight: '0.75',
          alignContent: 'center',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          opacity: 1,
          color: 'black',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiListItemButton-root&.Mui-disabled': {
            opacity: 1,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: '#1e90ff',
          },
          ':focus': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            //backgroundColor: '#1e90ff',
            '&:hover': { background: '#1e90ff' },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
        root: {
          '&.Mui-disabled': {
            '.MuiOutlinedInput-notchedOutline': {
              'border-color': 'transparent !important', //i hate to use important, but I cant for the life of me figure out how to get the mui components to respect this
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            '-webkit-text-fill-color': 'black',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'start',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          color: 'black',
          fontWeight: 'bold',
        },
        asterisk: {
          color: 'red',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
  },
})

import { Box, Typography, FormLabel } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { FollowUp } from '../../../core/types'
import { sendRequest } from '../../../hooks/use-fetch'
import { DatePickerWithLabel } from '../../forms/date-picker-with-label'
import { TextFieldWithLabel } from '../../forms/text-field-with-label'
import * as AppRoutes from '../../../routes'
import { generatePath } from 'react-router'
import Input from '../../input'

type Props = {
  type: 'Add' | 'Edit'
  followUp: FollowUp
  studentName: string
}
const serverUrl = process.env.REACT_APP_SERVER_URL
type errorResut = {
  title: string
  errors?: []
}

export function FollowUpDisplay(props: Props) {
  const [form, setForm] = useState<FollowUp>(props.followUp)
  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  const handleDateChange = (date: Date | null) => {
    if (date) setForm({ ...form, dateOf: date })
  }
  const nav = useNavigate()
  const [formError, setFormError] = useState<string | undefined>()

  const save = async () => {
    let isNew = props.type === 'Add'
    const url = `${serverUrl}/FollowUp/${!isNew ? 'Update' : 'Insert'}`
    const { response, success } = await sendRequest(url, {
      method: !isNew ? 'PUT' : 'POST',
      body: JSON.stringify(form),
    })
    if (success) {
      nav(
        generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
          id: form.incidentID.toString(),
        })
      )
    } else {
      const result: errorResut = await response?.json()
      let error = result.title + '\r\n'

      for (const property in result.errors) {
        error += `${property}: ${Reflect.get(result.errors, property)}\r\n`
      }

      result && setFormError(error)
    }
  }
  const cancel = () => {
    nav(
      generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
        id: form.incidentID.toString(),
      }),
      { state: { page: 2 } }
    )
  }
  const onDelete = async () => {
    const url = `${serverUrl}/FollowUp/Delete?id=${form.id}`
    const { response, success } = await sendRequest(url, {
      method: 'DELETE',
    })
    if (success) {
      nav(
        generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
          id: form.incidentID.toString(),
        })
      )
    } else {
      const result: errorResut = await response?.json()
      let error = result.title + '\r\n'

      for (const property in result.errors) {
        error += `${property}: ${Reflect.get(result.errors, property)}\r\n`
      }

      result && setFormError(error)
    }
  }

  return (
    <Box minWidth={'1000px'}>
      {formError && (
        <li style={{ color: 'red', padding: '10px 0' }}>{formError}</li>
      )}

      <Typography variant="h4">
        {props.type} Follow Up - {props.studentName}
      </Typography>
      <Box component={'form'}>
        <DatePickerWithLabel
          label="Date Of"
          name="dateOf"
          value={form.dateOf ?? new Date()}
          onChange={handleDateChange}
        />
        <Box width={'100%'} sx={{ maxWidth: '700px' }}>
          <FormLabel htmlFor="notes">Notes</FormLabel>
          <Input
            multiline
            name="notes"
            fullWidth
            value={form.notes}
            onChange={(e: any) => handleChange(e)}
          />
        </Box>
        <Box sx={{ pt: 1 }}>
          <Button onClick={save} variant="contained" sx={{ mr: 1 }}>
            Save
          </Button>
          <Button onClick={cancel} variant="outlined" sx={{ mr: 1 }}>
            Cancel
          </Button>
          {props.type == 'Edit' && (
            <Button variant="outlined" onClick={onDelete}>
              Delete
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { generatePath } from 'react-router'
import { District } from '../../core/types'
import { usePermissions } from '../../hooks/use-permissions'
import {
  DISTRICT_ADD_DISTRICT_IMPORT_STAFF_PATH,
  DISTRICT_ADD_DISTRICT_USER_PATH,
  DISTRICT_EDIT_USER_PATH,
} from '../../routes'
import { dateTimeRenderer } from '../paged-table/renderers'
import { SimplePagedTable } from '../paged-table/simple-paged-table'

type Props = {
  district: District
}
export const DistrictUsers = (props: Props) => {
  const { district } = props
  const permissions = usePermissions()

  return (
    <Box sx={{ m: '1rem 0' }}>
      {permissions?.canAddEditUser && (
        <>
          <Button
            variant="contained"
            href={generatePath(DISTRICT_ADD_DISTRICT_USER_PATH, {
              id: district.id.toString(),
            })}
          >
            Add
          </Button>
        </>
      )}

      <SimplePagedTable
        rows={district.users}
        rowActionCell={
          permissions?.canAddEditUser
            ? (row) => <EditUserButton id={row.id} />
            : undefined
        }
        columns={[
          {
            field: 'lastName',
            headerName: 'Last Name',
          },
          {
            field: 'firstName',
            headerName: 'First Name',
          },
          { field: 'email', headerName: 'Email' },
          { field: 'roleID', headerName: 'Role' },
          {
            field: 'lastLoginDate',
            headerName: 'Last Login',
            renderField: dateTimeRenderer,
          },
          {
            field: 'inactive',
            headerName: 'Inactive',
            renderField: (inactive: boolean) =>
              inactive && (
                <CloseIcon sx={{ color: 'red', paddingTop: '7px' }} />
              ),
          },
        ]}
      />
    </Box>
  )
}
const EditUserButton = (props: { id: number }) => (
  <IconButton
    href={generatePath(DISTRICT_EDIT_USER_PATH, {
      id: props.id.toString(),
    })}
  >
    <EditIcon />
  </IconButton>
)

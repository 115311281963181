import { Typography } from '@mui/material'
import { UserForm } from '../../../components/forms/add-edit-user'

export const AddUser = () => {
  return (
    <div>
      <Typography variant="h2">Add User</Typography>

      <UserForm mode="add" />
    </div>
  )
}

import { Typography } from '@mui/material'
import { useParams } from 'react-router'
import { UserForm } from '../../../components/forms/add-edit-user'
import { User } from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'

export const EditUser = () => {
  const { id } = useParams()

  const { response } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/User/Get?id=${id}&include=Schools,AuthorizedFunctions`
  )

  const user = response as User | null
  if (!user) return <></>

  const userToEdit = {
    ...user,
    schools: user.schools.map((sch) => ({ id: sch.id, name: sch.name })),
    functions: user.authorizedFunctions.map((fn) => fn.name),
    districtID: user.districtID ?? undefined,
    picCode: user.picCode ?? undefined,
  }
  return (
    <div>
      <Typography variant="h2">Edit User</Typography>

      <UserForm mode="edit" userToEdit={userToEdit} />
    </div>
  )
}

import { Checkbox, FormControlLabel, SelectChangeEvent } from '@mui/material'
import Box from '@mui/system/Box'
import { useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'
import { County, School } from '../../../core/types'
import { sendRequest, useFetch } from '../../../hooks/use-fetch'
import { DISTRICT_DETAILS_PATH } from '../../../routes'
import { ConfirmationDialog } from '../confirmation-dialog'
import { FormActionButton } from '../form-action-button'
import { SelectWithLabel } from '../select-with-label'
import { TextFieldWithLabel } from '../text-field-with-label'

const serverUrl = process.env.REACT_APP_SERVER_URL
type Props = { mode: 'add' } | { mode: 'edit'; school: School }
type SchoolFormFields = Pick<
  School,
  'code' | 'name' | 'countyID' | 'closed' | 'districtID'
>

export const AddEditSchoolForm = (props: Props) => {
  const { id } = useParams()
  const districtID =
    props.mode === 'add' ? (id ? parseInt(id, 10) : 0) : props.school.districtID
  const districtPath = generatePath(DISTRICT_DETAILS_PATH, {
    id: districtID?.toString() ?? null,
  })
  const initialState =
    props.mode === 'edit'
      ? props.school
      : {
          code: '',
          name: '',
          closed: false,
          countyID: null,
          districtID,
        }

  const [formState, setFormState] = useState<SchoolFormFields>(initialState)
  const [formErrors, setFormErrors] = useState<string[] | undefined>()
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

  const navigate = useNavigate()
  const onCancel = () => navigate(-1)

  const onSave = async () => {
    setFormErrors(undefined)
    const url = `${serverUrl}/School/${
      props.mode === 'edit' ? 'Update' : 'Insert'
    }`

    const { response, success } = await sendRequest(url, {
      method: props.mode === 'edit' ? 'PUT' : 'POST',
      body: JSON.stringify(formState),
    })
    if (success) {
      navigate(districtPath)
    } else {
      const result = await response?.json()
      if (result) {
        const errors = (result as { errors: { [k: string]: string[] } }).errors
          ? Object.keys(result.errors)
              .map((k) => result.errors[k])
              .flat()
          : [result as string]

        setFormErrors(errors)
      }
    }
  }

  const onDelete = async () => {
    if (props.mode === 'edit') {
      setFormErrors(undefined)
      const url = `${serverUrl}/School/Delete?id=${props.school.id}`

      const { response, success } = await sendRequest(url, { method: 'DELETE' })
      if (success) {
        setDeleteConfirmationOpen(false)
        navigate(districtPath)
      } else {
        const result = await response?.json()
        result && setFormErrors([result].flatMap((er) => er))
      }
    }
  }

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const { response: countyResponse } = useFetch(`${serverUrl}/County/GetLookup`)
  const countyOptions = (countyResponse ?? []) as County[]
  return (
    <Box component="form">
      <ul>
        {formErrors &&
          formErrors.map((err) => (
            <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
              {err}
            </li>
          ))}
      </ul>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TextFieldWithLabel
          label="Code"
          name="code"
          value={formState.code}
          onChange={handleChange}
        />
        <TextFieldWithLabel
          label="Name"
          name="name"
          required
          value={formState.name}
          onChange={handleChange}
        />
        <SelectWithLabel
          label="County"
          value={formState.countyID ?? 0}
          name="countyID"
          options={countyOptions}
          width="200px"
          onChange={handleChange}
          SelectProps={{
            renderValue: () =>
              countyOptions.find((c) => formState.countyID === c.id)?.name,
          }}
          FormControlProps={{
            sx: { margin: '10px 0' },
          }}
        />
        <FormControlLabel
          label={'Closed'}
          control={
            <Checkbox
              checked={formState.closed}
              value={formState.closed}
              onChange={(_e, checked) =>
                setFormState({ ...formState, closed: checked })
              }
            />
          }
        />
      </Box>

      <Box sx={{ marginTop: '14px' }}>
        <FormActionButton onClick={onSave} variant="contained">
          Save
        </FormActionButton>
        <FormActionButton onClick={onCancel}>Cancel</FormActionButton>

        {props.mode === 'edit' && (
          <>
            <ConfirmationDialog
              open={deleteConfirmationOpen}
              id="delete-school-confirmation"
              keepMounted={false}
              onClose={() => setDeleteConfirmationOpen(false)}
              onConfirm={onDelete}
              confirmationContent="Are you sure you want to delete this school?"
            />
            <FormActionButton onClick={() => setDeleteConfirmationOpen(true)}>
              Delete
            </FormActionButton>
          </>
        )}
      </Box>
    </Box>
  )
}

import { useParams } from 'react-router'
import { FollowUpDisplay } from '../../../components/incident/follow-up/display'
import { FollowUp } from '../../../core/types'
import { useAuth } from '../../../hooks/use-auth'
import { useFetch } from '../../../hooks/use-fetch'

export function FollowUpAdd() {
  const { id } = useParams()
  const { user } = useAuth()
  let num = 0
  if (id) num = +id
  const { response: nameResponse } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/GetStudentName?incidentID=${num}`
  )
  const foup: FollowUp = {
    id: 0,
    incidentID: num,
    notes: '',
    userId: user?.id ?? 0,
    dateOf: new Date(),
  }

  const studentName = nameResponse as unknown as { name: string }

  if (!foup || !user || !studentName) return <></>

  return (
    <FollowUpDisplay
      type="Add"
      followUp={foup}
      studentName={studentName.name}
    />
  )
}

import { Box, CircularProgress } from '@mui/material'
import { useState } from 'react'
import {
  SelectOption,
  SelectWithLabel,
} from '../../components/forms/select-with-label'
import { DistrictSummaryReport } from '../../components/reports/district-summary-report'
import { useAuth } from '../../hooks/use-auth'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import { usePermissions } from '../../hooks/use-permissions'

export function DistrictSummary() {
  const perms = usePermissions()
  const { user, loading } = useAuth()
  const [districtId, setDistrictId] = useState<number | null>(null)
  const { response, isLoading } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/District/GetLookup`
  )
  if (loading || isLoading) return <CircularProgress />
  const dropdownOptions = (response ?? []) as SelectOption<number>[]
  const canSeeDistrictDropdown = perms?.canSeeOtherDistrictReports ?? false
  if (!canSeeDistrictDropdown && districtId == null) {
    setDistrictId(user?.districtID ?? null)
  }

  return (
    <Box sx={{ width: '100%' }}>
      {canSeeDistrictDropdown && (
        <SelectWithLabel
          maxWidth="500px"
          label="District"
          name="district"
          onChange={(_e: any) => {
            setDistrictId(_e.target.value)
          }}
          options={dropdownOptions}
          value={districtId ?? 0}
          FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
        />
      )}
      {districtId && <DistrictSummaryReport id={districtId} />}
    </Box>
  )
}

import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'

type Props = {
  value: Date | null
  onChange: (newValue: Date | null) => void
  name: string
  label: string
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
}

export function TimePickerWithLabel(props: Props) {
  const { value, name, label, onChange } = props
  function handleChange(newValue: Dayjs | null) {
    if (newValue) {
      let newDate = new Date(2000, 0, 1, newValue.hour(), newValue.minute(), 0)
      props.onChange(newDate)
    }
  }

  return (
    <>
      <InputLabel id={name} required={props.required}>
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          disabled={props.disabled}
          value={value}
          onChange={handleChange}
          renderInput={(props) => (
            <TextField required={props.required} {...props} />
          )}
        />
      </LocalizationProvider>
    </>
  )
}

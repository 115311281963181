import { Typography } from '@mui/material'
import { useParams } from 'react-router'
import { UserForm } from '../../components/forms/add-edit-user'

export const AddDistrictUser = () => {
  const { id: districtId } = useParams()

  if (!districtId) return <></>
  return (
    <div>
      <Typography variant="h2">Add User</Typography>

      <UserForm
        mode="add"
        initialValues={{
          roleID: 'District User' as const,
          districtID: parseInt(districtId, 10),
        }}
      />
    </div>
  )
}

import React, { useCallback, useState } from 'react'
import { useAuth } from '../../hooks/use-auth'
import { useFetch } from '../../hooks/use-fetch'
import {
  PagedTableUI,
  SortDirection,
  toggleSortDirection,
} from './paged-table-ui'

type RecordName =
  | 'Incident'
  | 'User'
  | 'District'
  | 'Training'
  | 'PermissionRequest'
type Query = {
  recordName: RecordName
  searchField?: 'AdminSearchComposite' | 'AdminUserSearchValues' | 'SearchValue'
  queryInclude: string
}

const validQueries: Query[] = [
  {
    recordName: 'Incident',
    searchField: 'AdminSearchComposite',
    queryInclude: 'Student,RiskLevel,School,User,School.District',
  },
  {
    recordName: 'User',
    searchField: 'AdminUserSearchValues',
    queryInclude: 'District',
  },
  {
    recordName: 'District',
    searchField: 'SearchValue',
    queryInclude: 'Users',
  },
  {
    recordName: 'Training',
    queryInclude: 'District,TrainingType',
  },
  {
    recordName: 'PermissionRequest',
    queryInclude: 'User,Incident,Incident.Student',
  },
]

type QueryUrlInput<T> = {
  queryFor: RecordName
  searchValue: string
  page: number
  pageSize: number
  sortField: keyof T
  sortDirection: SortDirection
  currentUserId?: number
}
function buildQueryUrls<T>(
  inputs: QueryUrlInput<T>,
  columns: SortableColumn<T>[]
): { pagedDataUrl: string; recordCountUrl: string } {
  const {
    queryFor,
    page,
    pageSize,
    searchValue,
    sortDirection,
    sortField,
    currentUserId,
  } = inputs
  const queryInfo = validQueries.find((q) => q.recordName === queryFor)

  if (!queryInfo) {
    throw Error(`${queryFor} is not a valid record to query for.`)
  }

  const { queryInclude, recordName, searchField } = queryInfo

  const dbSortField =
    columns.find((col) => col.field === sortField)?.sortBy ?? 'ID'

  const querystring = `?queryInclude=${queryInclude}&sortField=${dbSortField}&sortDirection=${
    sortDirection === 'asc' ? 'A' : 'D'
  }&searchField=${searchField}&searchValue=${searchValue}${
    currentUserId ? `&userId=${currentUserId}` : ''
  }`

  const querystringWithPage = `${querystring}&pageNumber=${
    page + 1
  }&pageSize=${pageSize}`

  return {
    pagedDataUrl: `${process.env.REACT_APP_SERVER_URL}/${recordName}/PagedSearch${querystringWithPage}`,
    recordCountUrl: `${process.env.REACT_APP_SERVER_URL}/${recordName}/RecordCount${querystring}`,
  }
}

type SortableColumn<T> = {
  field: keyof T
  headerName: string
  sortBy: string
  renderField?: (value: any) => React.ReactNode
}
export type Props<T extends { id: number }> = {
  queryFor: RecordName
  columns: SortableColumn<T>[]
  rowActionCell?: (row: T) => React.ReactNode
  disableSearch?: boolean
}

export function PagedTable<T extends { id: number }>(props: Props<T>) {
  const { columns, queryFor } = props

  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortField, setSortField] = useState<keyof T>('id')
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc')

  const auth = useAuth()

  const { pagedDataUrl, recordCountUrl } = buildQueryUrls(
    {
      queryFor,
      page,
      sortField,
      sortDirection,
      searchValue: searchValue ?? '',
      pageSize,
      currentUserId: auth.user?.id,
    },
    columns
  )
  const { response: pagedDataResponse } = useFetch(pagedDataUrl)
  const rows = (pagedDataResponse ?? []) as T[]

  const { response: recordCountResponse } = useFetch(recordCountUrl)
  const recordCount = (recordCountResponse ?? 0) as number

  const handleChangePage = (_e: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onSort = React.useCallback(
    (headerId: keyof T) => {
      const sortingBy = sortField === headerId
      const newOrder = sortingBy ? toggleSortDirection(sortDirection) : 'desc'
      setSortDirection(newOrder)
      setSortField(headerId)
    },
    [sortDirection, sortField]
  )
  const onSearch = useCallback((value: string) => setSearchValue(value), [])

  return (
    <PagedTableUI
      data={rows}
      onPageChange={handleChangePage}
      onPageSizeChange={handleChangeRowsPerPage}
      onSort={onSort}
      onSearch={onSearch}
      sortDirection={sortDirection}
      sortField={sortField}
      page={page}
      pageSize={pageSize}
      recordCount={recordCount}
      name={queryFor}
      {...props}
    />
  )
}

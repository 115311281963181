import { CircularProgress, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { ActionTakenForm } from '../../../components/forms/add-edit-actiontaken'
import { ActionTaken } from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'

export const EditActionTaken = () => {
    const { id } = useParams()

    const { response, isLoading } = useFetch(        
      `${process.env.REACT_APP_SERVER_URL}/ActionTaken/Get?id=${id}`
    )
    const action = (response ?? {}) as ActionTaken
    if (isLoading)
    {
        return(<CircularProgress/>)
    }
    
  return (
    <div>
      <Typography variant="h2">Edit Action Taken</Typography>       
        
      <ActionTakenForm mode="edit" itemToEdit={action} />
    </div>
  )
}

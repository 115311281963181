export type NavigationItemProps = {
  title: string
  path?: string
  onClick?: () => void
}
export const navPaddingStyle = {
  paddingLeft: '25px',
  paddingRight: '25px',
  color: '#1E4393',
  textTransform: 'uppercase' as const,
  fontSize: '14px',
  fontWeight: 'bold' as const,
  textDecoration: 'none' as const,
  alignContent: 'center',
  ':hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
}
export const navItemStyles = {
  color: '#1E4393',
  textTransform: 'uppercase' as const,
  fontSize: '14px',
  fontWeight: 'bold' as const,
  textDecoration: 'none' as const,
  alignContent: 'center',
  ':hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
}

import { useAuth } from '../../../hooks/use-auth'
import { Typography, Box, Button } from '@mui/material'
import { useParams } from 'react-router'
import { sendRequest, useFetch } from '../../../hooks/use-fetch'
import { PermissionRequest } from '../../../core/types'
import { useState } from 'react'
type errorResut = {
  title: string
  errors?: []
}

export function PermissionRequestEdit() {
  const { id } = useParams()
  const { user } = useAuth()
  let error = ''
  let lesserError = ''
  const [message, setMessage] = useState('')
  const { response, isLoading } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/PermissionRequest/Get?id=${id}&include=Incident.Student,User`
  )
  if (isLoading) return <></>
  const reviewRequest = response as PermissionRequest | null
  if (!message) {
    if (reviewRequest === null) error = 'Record Not Found'
    else {
      if (reviewRequest.incident.userID != user?.id)
        //This is checking the original incident creator, not the user who submitted the request
        lesserError =
          'This request is not pertaining to an incident you submitted'
      else if (reviewRequest.granted !== null) {
        lesserError = `The request has already been ${
          reviewRequest.granted ? 'granted' : 'denied'
        }.`
      }
    }
  }

  const save = async (granted: boolean) => {
    if (!reviewRequest) return
    reviewRequest.granted = granted
    const url = `${process.env.REACT_APP_SERVER_URL}/PermissionRequest/Update`
    const { response, success } = await sendRequest(url, {
      method: 'PUT',
      body: JSON.stringify(reviewRequest),
    })
    if (success) {
      setMessage('Thank you for submitting your response.')
    } else {
      const result: errorResut = await response?.json()
      error = result.title + '\r\n'

      for (const property in result.errors) {
        error += `${property}: ${Reflect.get(result.errors, property)}\r\n`
      }
    }
  }
  const approve = () => {
    save(true)
  }
  const deny = () => {
    save(false)
  }
  return (
    <>
      <Typography>Review Data Sharing Request</Typography>
      {error && <Typography color={'red'}>{error}</Typography>}
      {message && <Typography color={'green'}>{message}</Typography>}
      {!error && (
        <>
          {lesserError && <Typography color={'red'}>{lesserError}</Typography>}

          <Box
            id="incidentInfo"
            sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}
          >
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box width={'50%'}>Student</Box>
              <Box width={'50%'}>
                {`${reviewRequest?.incident.student.firstName} ${reviewRequest?.incident.student.lastName}`}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box width={'50%'}>Incident Date</Box>
              <Box width={'50%'}>
                <>{reviewRequest?.incident.dateOf}</>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box width={'50%'}>Requesting User</Box>
              <Box
                width={'50%'}
              >{`${reviewRequest?.user.firstName} ${reviewRequest?.user.lastName}`}</Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box width={'50%'}>Request Date</Box>
              <Box width={'50%'}>
                <>{reviewRequest?.requestedOn}</>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box width={'50%'}>Request Details</Box>
              <Box width={'50%'}>{reviewRequest?.details}</Box>
            </Box>
          </Box>
          {!lesserError && !message && (
            <Box>
              <Button variant="outlined" onClick={approve}>
                Approve
              </Button>
              <Button variant="outlined" onClick={deny} sx={{ ml: 2 }}>
                Deny
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  )
}

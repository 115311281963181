import { TextFieldWithLabel } from '../text-field-with-label'
import {
  Box,
  Checkbox,
  FormLabel,
  SelectChangeEvent,
} from '@mui/material'
import { InitialResponderRole } from '../../../core/types'
import { ChangeEvent, useState } from 'react'
import { FormActionButton } from '../form-action-button'
import { ConfirmationDialog } from '../confirmation-dialog'
import { useNavigate } from 'react-router'
import { sendRequest } from '../../../hooks/use-fetch'
import * as AppRoutes from '../../../routes'

const serverUrl = process.env.REACT_APP_SERVER_URL

type Props =
  | { mode: 'add' }
  | {
      mode: 'edit'
      itemToEdit: InitialResponderRole
    }

export const InitialResponderRoleForm = (props: Props) => {
  const initialState =
    props.mode === 'edit'
      ? props.itemToEdit
      : {
          name: '',
          id: 0,
          closed: false,
          order: 0,
        }

  const [formState, setFormState] = useState<InitialResponderRole>(initialState)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [formErrors, setFormErrors] = useState<string[] | undefined>()

  const handleChange = (event: SelectChangeEvent<string | number>) =>
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })

  const navigate = useNavigate()
  const onCancel = () => navigate(-1)

  const onSave = async () => {
    setFormErrors(undefined)
    const url = `${serverUrl}/InitialResponderRole/${
      props.mode === 'edit' ? 'Update' : 'Insert'
    }`

    const { response, success } = await sendRequest(url, {
      method: props.mode === 'edit' ? 'PUT' : 'POST',
      body: JSON.stringify(formState),
    })
    if (success) {
      navigate(AppRoutes.ADMIN_RESPONDER_ROLES_PATH)
    } else {
      const result = await response?.json()
      if (result) {
        const errors = (result as { errors: { [k: string]: string[] } }).errors
          ? Object.keys(result.errors)
              .map((k) => result.errors[k])
              .flat()
          : [result as string]

        setFormErrors(errors)
      }
    }
  }

  const onDelete = async () => {
    if (props.mode === 'edit') {
      setFormErrors(undefined)
      const url = `${serverUrl}/InitialResponderRole/Delete?id=${formState.id}`

      const { response, success } = await sendRequest(url, { method: 'DELETE' })
      if (success) {
        setDeleteOpen(false)
        navigate(AppRoutes.ADMIN_RESPONDER_ROLES_PATH)
      } else {
        const result = await response?.json()
        result && setFormErrors([result].flatMap((er) => er))
      }
    }
  }

  return (
    <Box component="form">
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TextFieldWithLabel
          label="Name"
          name="name"
          required
          onChange={handleChange}
          value={formState?.name}
        />
      </Box>
      <Box>
        <FormLabel htmlFor="closed">Closed</FormLabel>
        <Checkbox
          sx={{ justifyContent: 'left' }}
          id="closed"
          value={formState.closed}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setFormState({
              ...formState,
              closed: event.target.checked,
            })
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <TextFieldWithLabel
          label="Order"
          name="order"
          required
          onChange={handleChange}
          value={formState?.order}
        />
      </Box>

      {formErrors &&
        formErrors.map((err) => (
          <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
            {err}
          </li>
        ))}

      <Box sx={{ marginTop: '14px' }}>
        <FormActionButton
          onClick={onSave}
          variant="contained"
          //disabled={saveDisabled}
        >
          Save
        </FormActionButton>
        <FormActionButton onClick={onCancel}>Cancel</FormActionButton>

        {props.mode === 'edit' && (
          <>
            <ConfirmationDialog
              open={deleteOpen}
              id="delete-role-confirmation"
              keepMounted={false}
              onClose={() => setDeleteOpen(false)}
              onConfirm={onDelete}
              confirmationContent="Are you sure you want to delete this role?"
            />
            <FormActionButton onClick={() => setDeleteOpen(true)}>
              Delete
            </FormActionButton>
          </>
        )}
      </Box>
    </Box>
  )
}

// @ts-nocheck
import { useEffect } from "react";

export function Accessibe(){

    useEffect(() => {
        const script = document.createElement('script');
        const e = !document.body ? document.querySelector('head') ?? document.body : document.body;
        //var acsbJS = {init:({}) => {}};
        script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
            script.async = true;
            script.onload = function () { 
            acsbJS.init({ statementLink: '', footerHtml: '',
         hideMobile: false, hideTrigger: false, language: 'en', position: 'left', 
         leadColor: '#146ff8', triggerColor: '#1ebae7', triggerRadius: '50%', 
         triggerPositionX: 'right', triggerPositionY: 'bottom', triggerIcon: 'people', 
         triggerSize: 'small', triggerOffsetX: 20, triggerOffsetY: 20, 
         mobile: { triggerSize: 'small', triggerPositionX: 'right', 
         triggerPositionY: 'bottom', triggerOffsetX: 10, triggerOffsetY: 10, 
         triggerRadius: '50%' } }); };

        e.appendChild(script);
      
        return () => {
          e.removeChild(script);
        }
      }, []);
      
    return (<></>);
}
import { User, RoleInfo, Role } from '../../../core/types'
import { SelectOption } from '../select-with-label'

export const permittedRoleOptionsForCurrentUser = (
  user: User | null,
  roleInfo: RoleInfo[]
): SelectOption<Role>[] => {
  if (!user) return []
  const roleOptionsForCurrentUser =
    roleInfo
      .find((role) => role.id === user.roleID)
      ?.manageableRoles.map((r) => r.id) ?? []

  const permittedRoleOptions = [...roleOptionsForCurrentUser, user.roleID]
  return permittedRoleOptions.map((role) => ({
    id: role,
    name: role,
  }))
}

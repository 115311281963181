import { useEffect, useState } from 'react'

export function useFetch(request: RequestInfo, init?: RequestInit) {
  const [response, setResponse] = useState<null | Response>(null)
  const [error, setError] = useState<Error | null>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const abortController = new AbortController()
    setIsLoading(true)
    ;(async () => {
      try {
        const response = await fetch(request, {
          signal: abortController.signal,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          ...init,
        })
        setResponse(await response?.json())
        setIsLoading(false)
      } catch (error) {
        if (isAbortError(error)) {
          return
        }
        setError(error as any)
        setIsLoading(false)
      }
    })()
    return () => {
      abortController.abort()
    }
  }, [init, request])

  return { response, error, isLoading }
}
export async function sendRequest(request: RequestInfo, init?: RequestInit) {
  const abortController = new AbortController()
  try {
    const response = await fetch(request, {
      signal: abortController.signal,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      ...init,
    })

    return { response: response, success: response.ok }
  } catch (error) {
    if (isAbortError(error)) {
      return { response: null, error, success: false }
    }
  }
  return { result: null, success: false }
}

function isAbortError(error: any): error is DOMException {
  if (error && error.name === 'AbortError') {
    return true
  }
  return false
}

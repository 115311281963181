import { UserFunction, User } from '../core/types'

export const hasSomeOfTheseFunctions =
  (desiredFunctions: UserFunction[]) => (actualFunctions: UserFunction[]) =>
    intersection(actualFunctions, desiredFunctions).length > 0

function intersection<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter((value) => arr2.includes(value))
}

const userFunctions = (user: User): UserFunction[] =>
  user?.authorizedFunctions?.map((fn) => fn.name) ?? []

const canConfigureDistricts = (user: User) =>
  userFunctions(user).includes('DistrictConfiguration')
const canManageDistricts = (user: User) =>
  userFunctions(user).includes('DistrictManagement')

export const PermissionsService = {
  canViewAdminPages: (user: User) =>
    userFunctions(user).includes('SiteAdministration'),
  canViewIncidentManagementAndReports: (user: User) =>
    userFunctions(user).includes('IncidentManagement'),
  canViewReports: (user: User) => userFunctions(user).includes('Reports'),
  canViewUserSummaryReport: (user: User) =>
    userFunctions(user).includes('UserSummaryReports'),
  canManageReports: (user: User) =>
    userFunctions(user).includes('ProgramReports'),
  canViewDistricts: (user: User) =>
    userFunctions(user).includes('DistrictView'),

  canManageDistricts,
  canEditDistrictInformation: canManageDistricts,

  canConfigureDistricts,
  canAddEditUser: canConfigureDistricts,
  canEditDistrictsDataSecurity: canConfigureDistricts,
  canEditDistrictsUsers: canConfigureDistricts,
  canEditDistrictsSchools: canConfigureDistricts,
  canAddDistrictsSchools: canConfigureDistricts,

  canImpersonate: (user: User) => userFunctions(user).includes('Impersonation'),
  canViewTraining: (user: User) =>
    userFunctions(user).includes('SiteAdministration'),
}

import { Typography, Dialog, Tabs, Tab } from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { useParams } from 'react-router'
import { IncidentEdit } from '../../components/incident/detailsDisplay/edit-display'
import { Incident } from '../../core/types'
import { useFetch } from '../../hooks/use-fetch'

export function EditIncident() {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [incidentsNotice, setIncidentsNotice] = useState(false)
  const [page, setPage] = useState(0)
  const { id } = useParams()
  const includes =
    'Student,School,School.District,User,RiskLevel,TriggerEvents,ActionsTaken,FollowUps.Disposition,FollowUps.User,Accommodation,L2Responder,LearnedFromSources'
  const { response } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Incident/Get?id=${id}&include=${includes}`
  )
  const incident = response as Incident | null

  if (incident) {
    incident.accommodationId = incident?.accommodation?.id ?? 0
    incident.riskLevelId = incident?.riskLevel?.id ?? 0
    incident.l2ResponderUserID = incident.l2Responder?.id ?? 0
  }

  if (incident == null || (incident?.user?.district?.id ?? 0) === 0)
    return <></>
  return (
    <Box>
      <Typography variant="h4">
        Edit Incident -{' '}
        {incident.anonymousStudent
          ? 'Anonymous Student'
          : `${incident.student?.firstName} ${incident.student?.lastName}`}
      </Typography>
      {error && <Typography>{error}</Typography>}

      <Box sx={{ width: '100%' }}>
        <IncidentEdit incident={incident} type={'Edit'} />
      </Box>
    </Box>
  )
}

import { Box, FormLabel, InputProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import Input from '../input'
type Props = {
  label: string
  name: string
  onChange: (e: any) => void
  value: unknown
  required?: boolean
  disabled?: boolean
  multiline?: boolean
}

export function TextAreaWithLabel(props: Props) {
  const variant = props.disabled ?? false ? 'standard' : 'outlined'
  let ip: InputProps = {}
  if (props.disabled) {
    ip.readOnly = true
    ip.disableUnderline = true
  }
  return (
    <Box
      sx={{
        pt: '8px',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
      <Box sx={{ pl: 1 }}>
        {props.disabled && (
          <Typography>
            <>{props.value}</>
          </Typography>
        )}
        {!props.disabled && (
          <Input
            multiline
            name={props.name}
            fullWidth
            value={props.value}
            onChange={props.onChange}
          />
        )}
      </Box>
    </Box>
  )
}

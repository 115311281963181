import { Typography } from '@mui/material'
import { ActionTakenForm } from '../../../components/forms/add-edit-actiontaken'

export const AddActionTaken = () => {
  return (
    <div>
      <Typography variant="h2">Add Action Taken</Typography>
        
        
     <ActionTakenForm mode="add" />
    </div>
  )
}

import { TextField, Autocomplete, Box, Button } from '@mui/material'
import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import { SelectOption } from '../forms/select-with-label'
import { DISTRICT_DETAILS_PATH, INCIDENT_DETAILS_PATH } from '../../routes'
import CircularProgress from '@mui/material/CircularProgress'

export function DistrictLookup() {
  const { response, isLoading } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/District/AllEEMDistricts`
  )
  const [selected, setSelected] = useState<number | null>(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const nav = useNavigate()
  if (isLoading) return <CircularProgress />
  const districtList = (response ?? []) as SelectOption<number>[]
  const submit = async () => {
    setError('')
    setLoading(true)
    if (selected !== null) {
      try {
        const { response, success } = await sendRequest(
          `${process.env.REACT_APP_SERVER_URL}/District/LoadFromEEM?districtCode=${selected}`
        )
        if (success) {
          const newId = await response?.json()
          nav(
            generatePath(DISTRICT_DETAILS_PATH, {
              id: newId,
            })
          )
        } else {
          setError(await response?.json())
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      setError('You must select a district to import')
    }
    setLoading(false)
  }
  const cancel = () => {
    nav(-1)
  }

  return (
    <>
      {error && (
        <li key={error} style={{ color: 'red', padding: '10px 0 0' }}>
          {error}
        </li>
      )}

      <Autocomplete
        options={districtList}
        getOptionLabel={(option) => option.name}
        onChange={(_e: any, value: { id: number; name: string } | null) => {
          setSelected(value?.id ?? null)
        }}
        sx={{ maxWidth: '300px', pb: 2 }}
        renderInput={(params) => <TextField {...params} label="District" />}
        renderOption={(props, option, state) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Button variant="contained" onClick={submit} sx={{ mr: 1 }}>
            Import
          </Button>
          <Button variant="outlined" onClick={cancel}>
            Cancel
          </Button>
        </Box>
      )}
    </>
  )
}

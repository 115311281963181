import { useParams } from 'react-router'
import { SafetyPlanForm } from '../../components/incident/safety-plan/form'
import { FormSafetyPlan } from '../../core/types'
import { useFetch } from '../../hooks/use-fetch'
import { Typography } from '@mui/material'

const serverUrl = process.env.REACT_APP_SERVER_URL

export function EditSafetyPlan() {
  const { id } = useParams()

  const { response: resp } = useFetch(`${serverUrl}/SafetyPlan/Get?id=${id}`)

  const plan = (resp ?? {}) as FormSafetyPlan
  const { response: studentNameResponse } = useFetch(
    `${serverUrl}/Incident/GetStudentName?incidentID=${plan.incidentID ?? 0}`
  )
  const studentName = (studentNameResponse ?? {}) as { name: string }
  if (plan.id === undefined) return <></>

  return (
    <>
      <Typography variant="h5" fontWeight={'bold'}>
        <>Edit Safety Plan - {studentName.name}</>
      </Typography>
      <SafetyPlanForm plan={plan} isEdit={true} />
    </>
  )
}

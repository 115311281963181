import { CircularProgress, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { InitialResponderRole } from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'
import { InitialResponderRoleForm } from '../../../components/forms/add-edit-responder-role'

export const EditResponderRole = () => {
    const { id } = useParams()

    const { response, isLoading } = useFetch(        
      `${process.env.REACT_APP_SERVER_URL}/InitialResponderRole/Get?id=${id}`
    )
    const role = (response ?? {}) as InitialResponderRole
    if (isLoading)
    {
        return(<CircularProgress/>)
    }
    
  return (
    <div>
      <Typography variant="h2">Edit Initial Responder Role</Typography>       
        
      <InitialResponderRoleForm mode="edit" itemToEdit={role} />
    </div>
  )
}

import { Box, CircularProgress, Typography, Button } from '@mui/material'
import { User } from '../../core/types'
import { useFetch } from '../../hooks/use-fetch'
import {  dateTimeRenderer, disabledRecordRenderer } from '../paged-table/renderers'
import {  useState } from 'react'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../paged-table/simple-paged-table'
import { generateDistrictStatusPDF } from './district-summary-pdf'
import PrintIcon from '@mui/icons-material/Print'
const pdfmake = require('pdfmake/build/pdfmake')
const fonts = require('pdfmake/build/vfs_fonts')

type Props = {
  id: number
}
export type DistrictInfo = {
  code: string
  enforceAnonymousLogging: boolean
  name: string
  integrationEnabled: boolean
  users: User[]
  privateIncidentAccess: boolean
  allowIncidentSharing: boolean
}
type UserResults = Pick<
  User,
  'id' | 'lastName' | 'firstName' | 'roleID' | 'lastLoginDate' | 'inactive'
>
type UserColumn = SimpleColumn<UserResults>

const columns: UserColumn[] = [
  {
    field: 'lastName',
    headerName: 'Last Name',
  },
  {
    field: 'firstName',
    headerName: 'First Name',
  },
  {
    field: 'roleID',
    headerName: 'Role',
  },
  {
    field: 'lastLoginDate',
    headerName: 'Last Login',
    renderField: dateTimeRenderer,
    colType:'date'
  },
  {
    field: 'inactive',
    headerName: 'Inactive',
    renderField:disabledRecordRenderer
  },
]

export function DistrictSummaryReport(props: Props) {
  const url = `${process.env.REACT_APP_SERVER_URL}/District/GetStatusReport?id=${props.id}`
  const { response, isLoading } = useFetch(url)
  const [sortField, setSortField] = useState('lastName')
  const [sortDir, setSortDir] = useState('desc')
  const [sortType, setSortType] = useState('string')
  if (isLoading) return <CircularProgress />
  const data = (response ?? {}) as DistrictInfo

  const sortFunction = (a: User, b: User) => {
      if (Reflect.has(a, sortField)) {
        const aField = Reflect.get(a, sortField)
        const bField = Reflect.get(b, sortField)
        if (sortType === 'date'){
          const aDate = new Date(aField as string)
          const bDate = new Date(bField as string)
          if (sortDir === 'asc') {
            if (aDate < bDate) return 1
            else return -1
          } else {
            if (aDate > bDate) return 1
            else return -1
          }

        }
        else{
          if (sortDir === 'asc') {
            if (aField < bField) return 1
            else return -1
          } else {
            if (aField > bField) return 1
            else return -1
          }
        }
      } else return 0
    }

  const sortedData = data.users.sort(sortFunction)
  const date = new Date()

  const print = () => {
    let body = generateDistrictStatusPDF({ printInfo: data, sortFunction })
    pdfmake.vfs = fonts.pdfMake.vfs

    pdfmake.createPdf(body).open()
  }
  const onSort = ( field:string,direction:string, sortType:string) => {
    setSortField(field)
    setSortDir(direction)
    setSortType(sortType)
  }

  return (
    <>
      <Box component="form">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontWeight={'bold'}>
            MiSafeStudent District Report
          </Typography>
          <Typography fontWeight={'bold'} sx={{ ml: 'auto' }}>
            Date Run: {date.toDateString()}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography fontWeight={'bold'}>District Name:</Typography>
          <Typography>{data.name}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography fontWeight={'bold'}>District Code:</Typography>
          <Typography>{data.code}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography fontWeight={'bold'}>Security Settings</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography fontWeight={'bold'}>
            Enable Data Exchange Integration via MiLaunchPad:
          </Typography>
          <Typography>{data.integrationEnabled ? 'True' : 'False'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography fontWeight={'bold'}>Private Incident Access:</Typography>
          <Typography>
            {data.privateIncidentAccess ? 'True' : 'False'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography fontWeight={'bold'}>
            Allow Incidents to be shared with permission:
          </Typography>
          <Typography>
            {data.allowIncidentSharing ? 'True' : 'False'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography fontWeight={'bold'}>
            Enforce anonymous logging:{' '}
          </Typography>
          <Typography>
            {data.enforceAnonymousLogging ? 'True' : 'False'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography fontWeight={'bold'}>Users:</Typography>
          <SimplePagedTable columns={columns} rows={sortedData} disableSearch
          initialSort='lastName'
          
          customOnSort={onSort}
          />
        </Box>
      </Box>
      <Box>
        <Button onClick={print} sx={{ border: '1px solid black' }}>
          <PrintIcon color="action" />
        </Button>{' '}
      </Box>
    </>
  )
}

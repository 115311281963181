import { Typography, Box } from '@mui/material'
import { DistrictLookup } from '../../components/district-details/lookup'

export const ImportDistrict = () => {
  return (
    <div>
      <Box>
        <Typography variant="h2">Select a School District to Add</Typography>
        <DistrictLookup />
      </Box>
    </div>
  )
}

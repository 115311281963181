import { MenuItem } from '@mui/material'
import { NavigationItemProps, navItemStyles, navPaddingStyle } from './shared'

export const NavigationItem = (props: NavigationItemProps) => {
  return (
    <a style={navItemStyles} href={props.path} onClick={props.onClick}>
      <MenuItem key={props.title} sx={navPaddingStyle}>
        {props.title}
      </MenuItem>
    </a>
  )
}

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'

import { useState } from 'react'
import { User } from '../../../core/types'
import { sendRequest } from '../../../hooks/use-fetch'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../paged-table/simple-paged-table'

type ShareUserResult = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
type ShareUserColumn = SimpleColumn<ShareUserResult>
type Props = {
  districtId: number
  incidentId: number
}
type ShareUser = {
  id: number
  selected: boolean
  userID: number
  firstName: string
  lastName: string
  email: string
  hasRequest: boolean
  searchField: string
}

export function ShareIncident(props: Props) {
  const [open, setOpen] = useState(false)
  const [userList, setUserList] = useState<ShareUser[] | undefined>()
  const [loading, setLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const handleClickOpen = () => {
    setOpen(true)
    if (!hasLoaded && !loading) getRequests()
  }
  const handleClose = () => {
    setOpen(false)
  }

  const columns: ShareUserColumn[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
  ]

  const onShare = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_URL

    const url = `${serverUrl}/PermissionRequest/Share/`

    const { response, success } = await sendRequest(url, {
      method: 'POST',
      body: JSON.stringify({
        incidentID: props.incidentId,
        userIds: selectedIds,
      }),
    })
    if (success) {
      setOpen(false)
    } else {
      const result = await response?.json()
      if (result) {
        const errors = (result as { errors: { [k: string]: string[] } }).errors
          ? Object.keys(result.errors)
              .map((k) => result.errors[k])
              .flat()
          : [result as string]
      }
    }
  }

  const getRequests = async () => {
    setLoading(true)
    try {
      const { response, success } = await sendRequest(
        `${process.env.REACT_APP_SERVER_URL}/User/ShareableUsers?districtID=${props.districtId}&incidentID=${props.incidentId}`
      )
      if (success) {
        const data: ShareUser[] = await response?.json()
        const cleanData = data
          .map((d) => {
            return {
              ...d,
              id: d.userID,
            }
          })
          .filter((d) => d.hasRequest === false)
        setUserList(cleanData)
      } else {
        setUserList([])
      }
    } catch {
      setUserList([])
    }

    setLoading(false)
    setHasLoaded(true)
  }
  return (
    <>
      <Button onClick={handleClickOpen} variant="contained">
        Share
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
        <DialogTitle sx={{ display: 'flex' }}>
          Share Incident
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            {loading ? (
              <CircularProgress />
            ) : (
              <Collapse orientation="horizontal" in={hasLoaded}>
                {userList && userList.length > 0 ? (
                  <SimplePagedTable
                    enableSelect
                    setSelectedIds={setSelectedIds}
                    selectedIds={selectedIds}
                    disableSearch
                    columns={columns}
                    rows={userList}
                  />
                ) : (
                  <Typography>
                    No one was found with that search criteria
                  </Typography>
                )}
              </Collapse>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onShare}>
            Share
          </Button>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  Box,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'

type Props = {
  value: Date | null
  onChange: (newValue: Date | null) => void
  name: string
  label: string
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
}

export function DatePickerWithLabel(props: Props) {
  const { value, name, label, onChange } = props
  function handleChange(newValue: Dayjs | null) {
    if (newValue) {
      let newDate = new Date(newValue.year(), newValue.month(), newValue.date())
      props.onChange(newDate)
    }
  }

  return (
    <>
      <InputLabel id={name} required={props.required}>
        {label}
      </InputLabel>
      <Box sx={{ p: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={props.disabled}
            value={value}
            onChange={handleChange}
            renderInput={(props) => (
              <TextField required={props.required} {...props} />
            )}
          />
        </LocalizationProvider>
      </Box>
    </>
  )
}

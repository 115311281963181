export type Role =
  | 'District User'
  | 'District Admin'
  | 'Program Manager'
  | 'Site Admin'
export type Race =
  | 'American Indian or Alaskan Native'
  | 'Asian'
  | 'African American or Black'
  | 'Native Hawaiian or Pacific Islander'
  | 'White'
  | 'Hispanic or Latino'
  | 'Unknown'
  | 'Two or more races'

export type UserFunction =
  | 'DistrictView'
  | 'DistrictManagement'
  | 'DistrictConfiguration'
  | 'Impersonation'
  | 'IncidentManagement'
  | 'Reports'
  | 'ProgramReports'
  | 'SiteAdministration'
  | 'UserSummaryReports'

export type RoleInfo = {
  id: Role
  manageableRoles: RoleInfo[]
  implicitFunctions: UserFunction[]
  explicitFunctions: UserFunction[]
}

export type AuthorizedFunction = { id: number; name: UserFunction }

export type User = {
  id: number
  firstName: string
  lastName: string
  email: string
  districtID?: number
  district: District | null
  roleID: Role
  authorizedFunctions: AuthorizedFunction[]
  inactive: boolean
  integrationEnabled: boolean
  isImpersonating: boolean
  lastLoginDate: string // Date
  whenAgreedToTerms: string // Date
  schools: { id: number; name: string }[]
  picCode?: string | null
}

export type School = {
  id: number
  code: string
  name: string
  closed: boolean
  county: string | null
  countyID: number | null
  districtID: number | null
  district?: District
}

export type County = {
  id: number
  name: string
}

export type RaceObject = {
  asian: number
  white: number
  africanAmericanOrBlack: number
  hispanicOrLatino: number
  americanIndianOrAlaskan: number
  nativeHawaiianOrPacificIslander: number
  raceDescriptor?: string | null
}
export type Student = {
  id: number
  firstName: string
  lastName: string
  dateOfBirth: string
  uic: string
  gender: string
  americanIndianOrAlaskan: number
  asian: number
  africanAmericanOrBlack: number
  nativeHawaiianOrPacificIslander: number
  white: number
  hispanicOrLatino: number
  isAnonymous: boolean
  currentGrade?: number
  currentSchoolID?: number
}
export type Accommodation = { name: string; id: number }
type BaseSafetyPlan = {
  id: number
  incidentID: number
  dateCompleted: Date | null
  status: string
  copyKeptAtSchool?: boolean | null
  fileName: string
  givenToGuardian?: boolean | null
  givenToStudent?: boolean | null
}

export type UploadSafetyPlan = BaseSafetyPlan & {
  type: 'Upload'
  notes?: string
  fileObject: BinaryData | null
}

export type FormSafetyPlan = BaseSafetyPlan & {
  type: 'Form'
  warningSign1: string
  warningSign2: string
  warningSign3: string
  copingStrategy1: string
  copingStrategy2: string
  copingStrategy3: string
  distractionPerson1: string
  distractionPerson2: string
  distractionPersonPhone1: string
  distractionPersonPhone2: string
  distractionPlace1: string
  distractionPlace2: string
  askForHelpPerson1: string
  askForHelpPerson2: string
  askForHelpPerson3: string
  askForHelpPersonPhone1: string
  askForHelpPersonPhone2: string
  askForHelpPersonPhone3: string
  clinician1: string
  clinician2: string
  clinicianPhone1: string
  clinicianPhone2: string
  clinicianEmergencyNumber1: string
  clinicianEmergencyNumber2: string
  localUrgentCareServices: string
  urgentCareServicesAddress: string
  urgentCareServicesPhone: string
  makeEnvironmentSafe1: string
  makeEnvironmentSafe2: string
  referralForOngoingBehavioralServices?: string
  oneThingThatsMostImportant?: string
}
export type SafetyPlan = FormSafetyPlan | UploadSafetyPlan

export type FollowUp = {
  id: number
  incidentID: number
  dateOf: Date
  notes: string
  userId: number
}
export type Incident = {
  id: number
  student: Student
  studentID: number
  ageAtTimeOfIncident: number | null
  anonymousStudent: boolean
  school: Partial<School> | null
  schoolID: number
  anonymousPreferedGender?: string
  birthGender?: string
  preferredGenderDifferent?: number
  identifiesASLGBTQ?: number
  familyInTransition?: number
  accommodationId?: number
  accommodation?: Accommodation | null
  dateOf: Date
  location?: string
  initialResponder?: string
  initialResponderRoleID?: number
  initialResponderRole?: InitialResponderRole | null
  otherInitialResponderRole?: string
  l2ResponderUserID?: number
  l2Responder?: Partial<User>
  otherL2Responder?: string
  riskLevelId?: number
  riskLevel?: RiskLevel | null
  otherRiskLevel?: string
  alcoholDrugUse?: number
  outsidePlanInitiated?: number
  triggerEvents: TriggerEvent[]
  otherTriggerEvent: string
  actionsTaken: ActionTaken[]
  otherActionTaken: string
  learnedFromSources: LearnedFromSource[]
  otherLearnedFromSource: string
  notes: string
  user: User | null
  userID: number
  whenEntered: Date
  importDate?: Date
  grade: string
  imported?: boolean
  followUps: FollowUp[],
  previousKnowledge?: number
}
export const Gender: { [key: string]: string } = {
  M: 'Male',
  F: 'Female',
  N: 'Unknown',
}
export const YesNoUnknown: { [key: number]: string } = {
  0: 'No',
  1: 'Yes',
  2: 'Unknown',
}
export type District = {
  id: number
  isdid: number | null
  code: string
  name: string
  privateIncidentAccess: boolean
  enforceAnonymousLogging: boolean
  allowIncidentSharing: boolean
  integrationEnabled: boolean
  useIntegratedStudentData: boolean
  closed: boolean
  isd: null
  schools: School[]
  trainings: unknown[]
  users: User[]
  adminUsers: string
  usersOnboard: number
  searchValue: string
  districtNumber: number
}
export type InitialResponderRole = { name: string; id: number ,closed: boolean, order:number}
export type LearnedFromSource = { name: string; id: number }
export type ActionTaken = {
  name: string
  id: number
  closed: boolean
  order: number
}
export type TriggerEvent = { name: string; id: number; closed: boolean, order:number }
export type RiskLevel = { id: number; name: string }

export type EmailSentLog = {
  id: number
  userID: number
  whenSent: string
  type:
    | 'District Admin Invite'
    | 'District Invite'
    | 'Incident Shared'
    | 'Permission Request'
  user: User
}

export type PermissionRequest = {
  id: number
  incidentID: number
  userID: number
  user: User
  details: string
  requestedOn: Date
  granted: boolean | null
  incident: Incident
}

export type Training = {
  id: number
  date: Date
  startTime: string //the db field type is time(0)
  districtID: number
  district: District | null
  location: string
  trainingTypeID: number
  trainingType: TrainingType | null
  avNeeds: string
  food: string
  notes: string
}

export type TrainingType = {
  id: number
  name: string
}

import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Box,
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export type SelectOption<T> = { id: T; name: string }
type Props<T extends string | number> = {
  options: SelectOption<T>[]
  value: T | undefined
  onChange: (event: SelectChangeEvent<T>) => void
  name: string
  label: string
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
  width?: string
  maxWidth?: string
  SelectProps?: Omit<SelectProps<T>, 'onChange' | 'value' | 'name' | 'required'>
}

export function SelectWithLabel<T extends string | number>(props: Props<T>) {
  const { value, name, options, label, onChange } = props
  return (
    <Box sx={{ pt: 1 }}>
      <InputLabel
        id={name}
        required={props.required}
        disableAnimation
        shrink={false}
        variant="standard"
        sx={{whiteSpace:'normal'}}
      >
        {label}
      </InputLabel>
      <FormControl
        variant="filled"
        {...props.FormControlProps}
        disabled={props.disabled}
        sx={{ pt: 0, pl: 1, width: props.width, maxWidth: props.maxWidth }}
      >
        <Select
          {...props.SelectProps}
          labelId={name}
          id={`${name}-select`}
          name={name}
          value={value || ''}
          onChange={onChange}
          required={props.required}
          variant="outlined"
          IconComponent={KeyboardArrowDownIcon}
        >
          {options.map((opt) => (
            <MenuItem key={opt.id} value={opt.id}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

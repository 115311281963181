import { ThemeProvider } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { SafetyPlanForm } from './components/incident/safety-plan/form'
import { Layout } from './components/layout'
import { AboutPage } from './pages/about'
import { TriggerEventList } from './pages/admin/global-configuration/trigger-event'
import { AddTriggerEvent } from './pages/admin/global-configuration/add-trigger-event'
import { EditTriggerEvent } from './pages/admin/global-configuration/edit-trigger-event'
import { ActionTakenList } from './pages/admin/global-configuration/actions-taken'
import { AddActionTaken } from './pages/admin/global-configuration/add-actions-taken'
import { EditActionTaken } from './pages/admin/global-configuration/edit-actions-taken'
import { UserManagement } from './pages/admin/user-management'
import { AddUser } from './pages/admin/user-management/add-user'
import { EditUser } from './pages/admin/user-management/edit-user'
import { Districts } from './pages/districts'
import { AddDistrict } from './pages/districts/add-district'
import { AddDistrictAdmin } from './pages/districts/add-district-admin'
import { AddDistrictSchool } from './pages/districts/add-district-school'
import { AddDistrictUser } from './pages/districts/add-district-user'
import { DistrictDetails } from './pages/districts/district-details'
import { EditDistrictDataSecurity } from './pages/districts/edit-district-data-security'
import { EditDistrictSchool } from './pages/districts/edit-district-school'
import { ImportDistrict } from './pages/districts/import-district'
import { AddIncident } from './pages/incident/add'
import { ViewIncident } from './pages/incident/details'
import { EditIncident } from './pages/incident/edit'
import { FollowUpAdd } from './pages/incident/follow-up/add'
import { FollowUpEdit } from './pages/incident/follow-up/edit'
import { PermissionRequestList } from './pages/incident/permission-request'
import { RequestPermission } from './pages/incident/permission-request/add'
import { PermissionRequestEdit } from './pages/incident/permission-request/edit'
import { Incidents } from './pages/incidents'
import { IncidentAggregateReport } from './pages/reports/incident-aggregate'
import { DistrictSettingsReport } from './pages/reports/district-settings'
import { DistrictSummary } from './pages/reports/district-status'
import { UserSettingsReport } from './pages/reports/user-settings'
import { AddSafetyPlan } from './pages/safety-plan/add'
import { AddSafetyPlanDocument } from './pages/safety-plan/document-add'
import { EditSafetyPlanDocument } from './pages/safety-plan/document-edit'
import { EditSafetyPlan } from './pages/safety-plan/edit'
import * as AppRoutes from './routes'
import { ProtectedRoute } from './routes/protected-route'
import { PermissionsService as PS } from './services/permissions-service'
import { mssTheme } from './theme'
import { useAuth } from './hooks/use-auth'
import { useEffect } from 'react'
import { IncidentExport } from './pages/reports/incident-export'
import { TrainingList } from './pages/training'
import { AddTraining } from './pages/training/add'
import { EditTraining } from './pages/training/edit'
import Container from '@mui/system/Container'
import { TermsAndConditions } from './pages/terms-and-conditions'
import { AddResponderRole } from './pages/admin/global-configuration/add-responder-role'
import { EditResponderRole } from './pages/admin/global-configuration/edit-responder-role'
import { InitialResponderRolesList } from './pages/admin/global-configuration/initial-responder-role'

function App() {
  const auth = useAuth()
  if (auth.loading) return <></>
  return (
    <ThemeProvider theme={mssTheme}>
      <Layout auth={auth}>
        <Routes>
          <Route
            index={true}
            path={AppRoutes.LANDING_PATH}
            element={
              <Container maxWidth={'lg'}>
                <AboutPage auth={auth} />
              </Container>
            }
          />
          <Route
            index={true}
            path={AppRoutes.TERMS_AND_CONDITIONS}
            element={
              <Container maxWidth={'lg'}>
                <TermsAndConditions />
              </Container>
            }
          />
          <Route
            element={
              <ProtectedRoute allow={PS.canViewIncidentManagementAndReports} />
            }
          >
            <Route path={AppRoutes.INCIDENT_PATH} element={<Incidents />} />
            <Route
              path={AppRoutes.INCIDENT_DETAILS_PATH}
              element={<ViewIncident />}
            />
            <Route
              path={AppRoutes.INCIDENT_EDIT_PATH}
              element={<EditIncident />}
            />
            <Route
              path={AppRoutes.SAFETY_PLAN_ADD_PATH}
              element={<AddSafetyPlan />}
            />
            <Route
              path={AppRoutes.SAFETY_PLAN_EDIT_PATH}
              element={<EditSafetyPlan />}
            />
            <Route
              path={AppRoutes.SAFETY_PLAN_DOCUMENT_ADD_PATH}
              element={<AddSafetyPlanDocument />}
            />
            <Route
              path={AppRoutes.SAFETY_PLAN_DOCUMENT_EDIT_PATH}
              element={<EditSafetyPlanDocument />}
            />
            <Route
              path={AppRoutes.ADD_INCIDENT_PATH}
              element={<AddIncident />}
            />
            <Route
              path={AppRoutes.INCIDENT_FOLLOW_UP_ADD_PATH}
              element={<FollowUpAdd />}
            />
            <Route
              path={AppRoutes.INCIDENT_FOLLOW_UP_EDIT_PATH}
              element={<FollowUpEdit />}
            />
            <Route
              path={AppRoutes.INCIDENT_PERMISSION_LIST_PATH}
              element={<PermissionRequestList />}
            />
            <Route
              path={AppRoutes.INCIDENT_PERMISSION_REVIEW_PATH}
              element={<PermissionRequestEdit />}
            />
            <Route
              path={AppRoutes.INCIDENT_PERMISSION_REQUEST_PATH}
              element={<RequestPermission />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canViewTraining} />}>
            <Route
              path={AppRoutes.TRAINING_LIST_PATH}
              element={<TrainingList />}
            />
            <Route
              path={AppRoutes.TRAINING_ADD_PATH}
              element={<AddTraining />}
            />
            <Route
              path={AppRoutes.TRAINING_EDIT_PATH}
              element={<EditTraining />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canViewAdminPages} />}>
            <Route
              path={AppRoutes.ADMIN_USER_LIST_PATH}
              element={<UserManagement />}
            />

            <Route path={AppRoutes.ADMIN_USER_ADD_PATH} element={<AddUser />} />
            <Route
              path={AppRoutes.ADMIN_USER_EDIT_PATH}
              element={<EditUser />}
            />
            <Route
              path={AppRoutes.ADMIN_RESPONDER_ROLES_PATH}
              element={<InitialResponderRolesList />}
            />
            <Route
              path={AppRoutes.ADMIN_ACTIONS_TAKEN_PATH}
              element={<ActionTakenList />}
            />
            <Route
              path={AppRoutes.ADMIN_ACTIONS_TAKEN_ADD}
              element={<AddActionTaken />}
            />
            <Route
              path={AppRoutes.ADMIN_ACTIONS_TAKEN_EDIT}
              element={<EditActionTaken />}
            />     
             <Route
              path={AppRoutes.ADMIN_RESPONDER_ROLES_PATH}
              element={<InitialResponderRolesList />}
            />
            <Route
              path={AppRoutes.ADMIN_RESPONDER_ROLES_ADD}
              element={<AddResponderRole />}
            />
            <Route
              path={AppRoutes.ADMIN_RESPONDER_ROLES_EDIT}
              element={<EditResponderRole />}
            />
            <Route
              path={AppRoutes.ADMIN_TRIGGER_EVENT_PATH}
              element={<TriggerEventList />}
            />
            <Route
              path={AppRoutes.ADMIN_TRIGGER_EVENT_ADD}
              element={<AddTriggerEvent />}
            />
            <Route
              path={AppRoutes.ADMIN_TRIGGER_EVENT_EDIT}
              element={<EditTriggerEvent />}
            />
            <Route path={AppRoutes.ADMIN_USER_ADD_PATH} element={<AddUser />} />
            <Route
              path={AppRoutes.ADMIN_USER_EDIT_PATH}
              element={<EditUser />}
            />
          </Route>

          <Route element={<ProtectedRoute allow={PS.canViewDistricts} />}>
            <Route path={AppRoutes.DISTRICT_PATH} element={<Districts />} />
            <Route
              path={AppRoutes.DISTRICT_DETAILS_PATH}
              element={<DistrictDetails />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canConfigureDistricts} />}>
            <Route
              path={AppRoutes.DISTRICT_IMPORT_SCHOOL_PATH}
              element={<ImportDistrict />}
            />
            <Route
              path={AppRoutes.DISTRICT_ADD_DISTRICT_SCHOOL_PATH}
              element={<AddDistrictSchool />}
            />
            <Route
              path={AppRoutes.DISTRICT_EDIT_SCHOOL_PATH}
              element={<EditDistrictSchool />}
            />
            <Route
              path={AppRoutes.DISTRICT_EDIT_SECURITY_OPTIONS_PATH}
              element={<EditDistrictDataSecurity />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canManageDistricts} />}>
            <Route
              path={AppRoutes.DISTRICT_ADD_PATH}
              element={<AddDistrict />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canAddEditUser} />}>
            <Route
              path={AppRoutes.DISTRICT_EDIT_USER_PATH}
              element={<EditUser />}
            />
            <Route
              path={AppRoutes.DISTRICT_ADD_DISTRICT_ADMIN_PATH}
              element={<AddDistrictAdmin />}
            />
            <Route
              path={AppRoutes.DISTRICT_ADD_DISTRICT_USER_PATH}
              element={<AddDistrictUser />}
            />
            <Route
              path={AppRoutes.DISTRICT_ADD_DISTRICT_SCHOOL_PATH}
              element={<AddDistrictSchool />}
            />
            <Route
              path={AppRoutes.DISTRICT_EDIT_SCHOOL_PATH}
              element={<EditDistrictSchool />}
            />
            <Route
              path={AppRoutes.DISTRICT_EDIT_SECURITY_OPTIONS_PATH}
              element={<EditDistrictDataSecurity />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canManageDistricts} />}>
            <Route
              path={AppRoutes.DISTRICT_ADD_PATH}
              element={<AddDistrict />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canViewReports} />}>
            <Route
              path={AppRoutes.DISTRICT_SETTINGS_REPORT_PATH}
              element={<DistrictSettingsReport />}
            />
            <Route
              path={AppRoutes.REPORT_INCIDENT_EXPORT_PATH}
              element={<IncidentExport />}
            />
            <Route
              path={AppRoutes.DISTRICT_STATUS_REPORT_PATH}
              element={<DistrictSummary />}
            />
          </Route>
          <Route
            element={<ProtectedRoute allow={PS.canViewReports} noContainer />}
          >
            <Route
              path={AppRoutes.INCIDENT_AGGREGATE_REPORT_PATH}
              element={<IncidentAggregateReport />}
            />
          </Route>
          <Route
            element={<ProtectedRoute allow={PS.canViewUserSummaryReport} />}
          >
            <Route
              path={AppRoutes.USER_SETTINGS_REPORT_PATH}
              element={<UserSettingsReport />}
            />
          </Route>
          <Route element={<ProtectedRoute allow={PS.canAddEditUser} />}>
            <Route
              path={AppRoutes.DISTRICT_EDIT_USER_PATH}
              element={<EditUser />}
            />
            <Route
              path={AppRoutes.DISTRICT_ADD_DISTRICT_ADMIN_PATH}
              element={<AddDistrictAdmin />}
            />
            <Route
              path={AppRoutes.DISTRICT_ADD_DISTRICT_USER_PATH}
              element={<AddDistrictUser />}
            />
          </Route>
        </Routes>
      </Layout>
    </ThemeProvider>
  )
}

export default App

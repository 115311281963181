import Button, { ButtonProps } from '@mui/material/Button'

export const FormActionButton = (props: ButtonProps) => {
  return (
    <Button
      size="large"
      variant="outlined"
      sx={{
        marginRight: '28px',
        paddingRight: '3rem',
        paddingLeft: '3rem',
      }}
      {...props}
    >
      {props.children}
    </Button>
  )
}

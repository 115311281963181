import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { MouseEvent, useState } from 'react'
import { Incident, RaceObject } from '../../core/types'
import { SelectWithLabel } from '../forms/select-with-label'
import { TextFieldWithLabel } from '../forms/text-field-with-label'
import { getRaceFromStudent, RaceSelect } from '../race-select'
import { DatePickerWithLabel } from '../forms/date-picker-with-label'

type Props = {
  saveButtonFunction?: (studentData: ManualStudentFormFields) => void
  student: ManualStudentFormFields
  schoolList: Option[]
}

export type ManualStudentFormFields = {
  firstName: string
  lastName: string
  uicCode: string
  enrolledSchool?: number
  gender: string
  grade?: string
  dateOfBirth: string
  race: RaceObject
}

export function getManualStudentFormFields(incident: Incident) {
  const formFields: ManualStudentFormFields = {
    dateOfBirth: incident.student?.dateOfBirth ?? '',
    firstName: incident.student?.firstName ?? '',
    gender: incident.student?.gender ?? '',
    lastName: incident.student?.lastName ?? '',
    race: getRaceFromStudent(incident.student),
    uicCode: incident.student?.uic ?? '',
    grade: incident.grade ?? 0,
  }
  if (incident.school) formFields.enrolledSchool = incident.school?.id
  return formFields
}

type Option = { id: number; name: string }

export function ManualStudenEntryModal(props: Props) {
  const [open, setOpen] = useState(false)
  const [formError, setFormError] = useState<string | undefined>()

  const handleClickOpen = () => {
    reset()
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const reset = () => {
    setFirstName(props.student.firstName)
    setLastName(props.student.lastName)
    setUic(props.student.uicCode)
    setGender(props.student.gender)
    setGrade(props.student.grade)
    setSchool(props.student.enrolledSchool)
    setDateOfBirth(props.student.dateOfBirth)
    setRaceObject(props.student.race)
  }
  const [firstName, setFirstName] = useState(props.student.firstName)
  const [lastName, setLastName] = useState(props.student.lastName)
  const [uic, setUic] = useState(props.student.uicCode)
  const [gender, setGender] = useState(props.student.gender)
  const [dateOfBirth, setDateOfBirth] = useState(props.student.dateOfBirth)
  const [grade, setGrade] = useState(props.student.grade)
  const [school, setSchool] = useState(props.student.enrolledSchool)
  const [raceObject, setRaceObject] = useState(props.student.race)
  const StateReportedGenderList = [
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' },
    { id: 'N', name: 'Prefer not to say' },
  ]
  const GradeList = [
    { id: 'KG', name: 'KG' },
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
    { id: '6', name: '6' },
    { id: '7', name: '7' },
    { id: '8', name: '8' },
    { id: '9', name: '9' },
    { id: '10', name: '10' },
    { id: '11', name: '11' },
    { id: '12', name: '12' },
  ]
  const schoolOptions = props.schoolList
  const handleDayChanged = (newValue: Date | null) => {
    if (newValue) {
      setDateOfBirth(newValue.toDateString())
    }
  }

  function validate(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    const form = e.currentTarget.form
    const isValid = form?.reportValidity()
    if (dateOfBirth === '' || dateOfBirth === undefined) {
      setFormError('Date of Birth is required')
      return
    } else {
      setFormError('')
    }
    if (isValid && props.saveButtonFunction) {
      const newStudentData: ManualStudentFormFields = {
        dateOfBirth: dateOfBirth.toString(),
        grade: grade,
        enrolledSchool: school,
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        race: raceObject,
        uicCode: uic,
      }
      props.saveButtonFunction(newStudentData)
      handleClose()
    }
  }
  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        sx={{ fontWeight: 'bold', height: '40px', width: '120px' }}
      >
        Enter Student Information
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <DialogTitle sx={{ display: 'flex' }}>
          <Typography sx={{ display: 'flex', mr: 4 }}>
            Add Student Information
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 8,
              right: 0,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            id="manualStudentForm"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {formError && (
              <li style={{ color: 'red', padding: '10px 0' }}>{formError}</li>
            )}

            <Grid container>
              <Grid item width={'50%'} sx={{ pl: 2, pr: 2 }}>
                <TextFieldWithLabel
                  required
                  label={'Legal First Name'}
                  name={'legalFirstName'}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                <TextFieldWithLabel
                  label={'UIC'}
                  name={'uic'}
                  onChange={(e) => setUic(e.target.value)}
                  value={uic}
                />
                <SelectWithLabel
                  required
                  options={StateReportedGenderList}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  name={'gender'}
                  label={'Gender'}
                  width="200px"
                  FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
                />
                <DatePickerWithLabel
                  required
                  name={'dateOfBirth'}
                  label={'DateOfBirth'}
                  onChange={handleDayChanged}
                  value={new Date(dateOfBirth)}
                  FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
                />
              </Grid>
              <Grid item width={'50%'} sx={{ pl: 2, pr: 2 }}>
                <TextFieldWithLabel
                  required
                  label={'Legal Last Name'}
                  name={'legalLastName'}
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
                <SelectWithLabel
                  required
                  options={schoolOptions}
                  value={school}
                  onChange={(e) => setSchool(e.target.value as number)}
                  name={'enrolledSchool'}
                  label={'Enrolled School'}
                  width="200px"
                  FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
                />
                <SelectWithLabel
                  required
                  options={GradeList}
                  value={grade}
                  onChange={(e) => setGrade(e.target.value as string)}
                  name={'grade'}
                  label={'Grade'}
                  width="200px"
                  FormControlProps={{ fullWidth: true, sx: { mt: 2 } }}
                />
                <Box sx={{ mt: 2 }}>
                  <RaceSelect
                    raceObject={raceObject}
                    setRaceObject={setRaceObject}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button form="manualStudentForm" autoFocus onClick={validate}>
            Save
          </Button>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import { FormSafetyPlan, YesNoUnknown } from '../../../core/types'
import { sendRequest } from '../../../hooks/use-fetch'
import { DatePickerWithLabel } from '../../forms/date-picker-with-label'
import { SelectWithLabel } from '../../forms/select-with-label'
import { TextFieldWithLabel } from '../../forms/text-field-with-label'
import * as AppRoutes from '../../../routes'
import { generatePath, useNavigate } from 'react-router'
const serverUrl = process.env.REACT_APP_SERVER_URL

type Props = {
  plan: FormSafetyPlan
  isEdit: boolean
}

export function SafetyPlanForm(props: Props) {
  const [form, setForm] = useState(props.plan)
  const [formError, setFormError] = useState<string | undefined>()

  const yesNoUnknownOptions = [
    { id: '1', name: YesNoUnknown[1] },
    { id: '0', name: YesNoUnknown[0] },
    { id: '2', name: YesNoUnknown[2] },
  ]

  const yesNoChanged = (e: any) => {
    let newVal: Boolean | undefined = undefined
    let val = e.target.value
    if (val === '1') newVal = true
    if (val === '0') newVal = false
    setForm({ ...form, [e.target.name]: newVal })
  }
  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  const handleDayChanged = (newValue: Date | null) => {
    if (newValue) {
      setForm({ ...props.plan, dateCompleted: newValue })
    }
  }

  const navigate = useNavigate()
  const onCancel = () => navigate(-1)
  const onDelete = async () => {
    const url = `${serverUrl}/SafetyPlan/Delete?id=${form.id}`
    let id = form.incidentID.toString()
    const { response, success } = await sendRequest(url, {
      method: 'DELETE',
      body: JSON.stringify(form),
    })
    if (success) {
      navigate(
        generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
          id: id,
        })
      )
    } else {
      const result: string = await response?.json()
      result && setFormError(result)
    }
  }

  const onSave = async () => {
    let errors:string[] = []
    if (form.copyKeptAtSchool === undefined)
      errors.push('Please select a value for Copy Kept at School')
    if (form.givenToGuardian === undefined)
        errors.push('Please select a value for copy givent to parent/guardian')
    if (form.givenToStudent === undefined)
        errors.push('Please select a value for copy given to student')
    if (errors.length > 0)
    {
      setFormError('Please fill out all required fields')
      return
    }
    setFormError(undefined)
    const url = `${serverUrl}/SafetyPlan/${props.isEdit ? 'Update' : 'Insert'}`
    let id = form.incidentID.toString()
    const { response, success } = await sendRequest(url, {
      method: props.isEdit ? 'PUT' : 'POST',
      body: JSON.stringify(form),
    })
    if (success) {
      navigate(
        generatePath(AppRoutes.INCIDENT_DETAILS_PATH, {
          id: id,
        })
      )
    } else {
      const result: string = await response?.json()
      result && setFormError(result)
    }
  }

  return (
    <Box component="form">
      {formError && (
        <li style={{ color: 'red', padding: '10px 0' }}>{formError}</li>
      )}
      <DatePickerWithLabel
        label="Date Completed"
        name="dateCompleted"
        value={form.dateCompleted}
        onChange={handleDayChanged}
      />
      <Typography fontWeight={'bold'}>
        Step 1: Warning signs (thoughts, images, mood, situation, behavior) that
        a crisis may be developing
      </Typography>
      <TextFieldWithLabel
        label="1."
        inline
        maxWidth="650px"
        name="warningSign1"
        value={form.warningSign1}
        onChange={handleChange}
      />{' '}
      <TextFieldWithLabel
        label="2."
        inline
        name="warningSign2"
        maxWidth="650px"
        value={form.warningSign2}
        onChange={handleChange}
      />{' '}
      <TextFieldWithLabel
        label="3."
        inline
        name="warningSign3"
        maxWidth="650px"
        value={form.warningSign3}
        onChange={handleChange}
      />
      <Typography fontWeight={'bold'}>
        Step 2: Internal coping strategies - things I can do to take my mind off
        my problems without contacting another person (relaxation technique,
        physical activity)
      </Typography>
      <TextFieldWithLabel
        label="1."
        inline
        name="copingStrategy1"
        maxWidth="650px"
        value={form.copingStrategy1}
        onChange={handleChange}
      />{' '}
      <TextFieldWithLabel
        label="2."
        inline
        name="copingStrategy2"
        maxWidth="650px"
        value={form.copingStrategy2}
        onChange={handleChange}
      />{' '}
      <TextFieldWithLabel
        label="3."
        inline
        name="copingStrategy3"
        maxWidth="650px"
        value={form.copingStrategy3}
        onChange={handleChange}
      />
      <Typography fontWeight={'bold'}>
        Step 3: People and social settings that provide distraction
      </Typography>
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="1. Name"
          maxWidth="350px"
          inline
          name="distractionPerson1"
          value={form.distractionPerson1}
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          label="Phone"
          name="distractionPersonPhone1"
          inline
          maxWidth="350px"
          value={form.distractionPersonPhone1}
          onChange={handleChange}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="2. Name"
          name="distractionPerson2"
          inline
          maxWidth="350px"
          value={form.distractionPerson2}
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          label="Phone"
          maxWidth="350px"
          inline
          name="distractionPersonPhone2"
          value={form.distractionPersonPhone2}
          onChange={handleChange}
        />
      </Box>
      <TextFieldWithLabel
        label="3.Place"
        maxWidth="650px"
        inline
        name="distractionPlace1"
        value={form.distractionPlace1}
        onChange={handleChange}
      />
      <TextFieldWithLabel
        inline
        label="4.Place"
        maxWidth="650px"
        name="distractionPlace2"
        value={form.distractionPlace2}
        onChange={handleChange}
      />
      <Typography fontWeight={'bold'}>
        Step 4: People whom I can ask for help
      </Typography>
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="1. Name"
          inline
          name="askForHelpPerson1"
          maxWidth="350px"
          value={form.askForHelpPerson1}
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          label="Phone"
          maxWidth="350px"
          inline
          name="askForHelpPersonPhone1"
          value={form.askForHelpPersonPhone1}
          onChange={handleChange}
        />
      </Box>{' '}
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="2. Name"
          maxWidth="350px"
          inline
          name="askForHelpPerson2"
          value={form.askForHelpPerson2}
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          inline
          label="Phone"
          maxWidth="350px"
          name="askForHelpPersonPhone2"
          value={form.askForHelpPersonPhone2}
          onChange={handleChange}
        />
      </Box>{' '}
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="3. Name"
          name="askForHelpPerson3"
          maxWidth="350px"
          inline
          value={form.askForHelpPerson3}
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          label="Phone"
          name="askForHelpPersonPhone3"
          inline
          maxWidth="350px"
          value={form.askForHelpPersonPhone3}
          onChange={handleChange}
        />
      </Box>
      <Typography fontWeight={'bold'}>
        Step 5: Professionals or agencies I can contact during a crisis
      </Typography>
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="1. Clinician Name"
          name="clinician1"
          value={form.clinician1}
          inline
          maxWidth="350px"
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          label="Phone"
          inline
          name="clinicianPhone1"
          value={form.clinicianPhone1}
          maxWidth="350px"
          onChange={handleChange}
        />
      </Box>
      <TextFieldWithLabel
        label="Clinician pager or emergency contact # "
        name="clinicianEmergencyNumber1"
        maxWidth="350px"
        inline
        value={form.clinicianEmergencyNumber1}
        onChange={handleChange}
      />
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="1. Clinician Name"
          name="clinician2"
          maxWidth="350px"
          value={form.clinician2}
          inline
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          label="Phone"
          name="clinicianPhone2"
          maxWidth="350px"
          inline
          value={form.clinicianPhone2}
          onChange={handleChange}
        />
      </Box>
      <TextFieldWithLabel
        label="Clinician pager or emergency contact # "
        name="clinicianEmergencyNumber2"
        maxWidth="350px"
        inline
        value={form.clinicianEmergencyNumber2}
        onChange={handleChange}
      />
      <Box display={'flex'} flexDirection={'row'}>
        <TextFieldWithLabel
          label="3. Local urgent care services"
          name="localUrgentCareServices"
          maxWidth="350px"
          inline
          value={form.localUrgentCareServices}
          onChange={handleChange}
        />{' '}
        <TextFieldWithLabel
          label="Urgent care services address"
          name="urgentCareServicesAddress"
          maxWidth="350px"
          inline
          value={form.urgentCareServicesAddress}
          onChange={handleChange}
        />
      </Box>
      <TextFieldWithLabel
        label="Urgent care services phone"
        name="urgentCareServicesPhone"
        maxWidth="350px"
        inline
        value={form.urgentCareServicesPhone}
        onChange={handleChange}
      />
      <Typography fontWeight={'bold'}>
        4. Provide Suicide Prevention Lifeline phone: 1-800-273-TALK (8255) ox`r
        text “HELP” to 741-741
      </Typography>
      <Typography fontWeight={'bold'}>
        Step 6: Making the environment safe (lock or eliminate lethal means)
      </Typography>
      <TextFieldWithLabel
        label="1."
        inline
        maxWidth="650px"
        name="makeEnvironmentSafe1"
        value={form.makeEnvironmentSafe1}
        onChange={handleChange}
      />
      <TextFieldWithLabel
        label="2."
        inline
        maxWidth="650px"
        name="makeEnvironmentSafe2"
        value={form.makeEnvironmentSafe2}
        onChange={handleChange}
      />
      <Typography fontWeight={'bold'}>
        Step 7: For referral information regarding ongoing behavioral health
        services
      </Typography>
      <TextFieldWithLabel
        label=""
        maxWidth="650px"
        name="referralForOngoingBehavioralServices"
        value={form.referralForOngoingBehavioralServices}
        onChange={handleChange}
      />
      <Typography fontWeight={'bold'}>
        Step 6: Making the environment safe (lock or eliminate lethal means)
      </Typography>
      <TextFieldWithLabel
        label="1."
        inline
        maxWidth="650px"
        name="makeEnvironmentSafe1"
        value={form.makeEnvironmentSafe1}
        onChange={handleChange}
      />
      <TextFieldWithLabel
        label="2."
        inline
        maxWidth="650px"
        name="makeEnvironmentSafe2"
        value={form.makeEnvironmentSafe2}
        onChange={handleChange}
      />
      <Typography fontWeight={'bold'}>
        Step 7: For referral information regarding ongoing behavioral health
        services
      </Typography>
      <TextFieldWithLabel
        label=""
        maxWidth="650px"
        name="referralForOngoingBehavioralServices"
        value={form.referralForOngoingBehavioralServices}
        onChange={handleChange}
      />{' '}
      <Typography fontWeight={'bold'}>
        Step 8: The one thing that is most important to me and worth living for
        is
      </Typography>
      <TextFieldWithLabel
        label=""
        maxWidth="650px"
        name="oneThingThatsMostImportant"
        value={form.oneThingThatsMostImportant}
        onChange={handleChange}
      />
      <SelectWithLabel
        label="Copy Kept At School"
        name="copyKeptAtSchool"
        width="200px"
        required
        FormControlProps={{ fullWidth: true }}
        value={
          form.copyKeptAtSchool !== undefined
            ? form.copyKeptAtSchool
              ? '1'
              : '0'
            : ''
        }
        onChange={yesNoChanged}
        options={yesNoUnknownOptions}
      />
      <SelectWithLabel
        label="Given to Parent/Guardian"
        name="givenToGuardian"
        width="200px"        
        required
        FormControlProps={{ fullWidth: true }}
        value={
          form.givenToGuardian !== undefined
            ? form.givenToGuardian
              ? '1'
              : '0'
            : ''
        }
        onChange={yesNoChanged}
        options={yesNoUnknownOptions}
      />
      <SelectWithLabel
        label="Given to Student"
        name="givenToStudent"
        width="200px"        
        required
        value={
          form.givenToStudent !== undefined ? (form.givenToStudent ? '1' : '0') : ''
        }
        FormControlProps={{ fullWidth: true }}
        onChange={yesNoChanged}
        options={yesNoUnknownOptions}
      />
      <Box sx={{ marginTop: '14px' }}>
        <Button onClick={onSave} variant="contained">
          Save
        </Button>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        {props.isEdit && (
          <Button onClick={onDelete} variant="outlined">
            Delete
          </Button>
        )}
      </Box>
    </Box>
  )
}

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { Box } from '@mui/material'
import { Login } from '../components/login'
import miSafeStudentLogo from '../assets/MiSafeStudent.png'
import corewellLogo from '../assets/CorewellHealthLogo.png'
import { AuthType, useAuth } from '../hooks/use-auth'

export const AboutPage = (props: { auth: AuthType }) => {
  return (
    <Box sx={{ maxWidth: '1000px' }}>
      <Typography variant="h2">About</Typography>

      <Grid container spacing={1} alignItems="center" marginBottom="1rem">
        <Grid item md={3}>
          <img
            style={{ height: '50px', paddingRight: '10px' }}
            alt="Corewell Health Logo"
            src={corewellLogo}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography>
            Corewell Health's School Blue Envelope program is designed to
            empower school administrators, teachers and support staff to step in
            and intervene with confidence, knowledge and competence in the
            mission to prevent suicide. Through the SAFE steps program, schools
            are taught to use nationally recognized and evidence-based tools and
            methods. To learn more about this program, read{' '}
            <Link href="https://www.spectrumhealth.org/patient-care/behavioral-health/suicide-prevention/blue-envelope">
              Blue Envelope
            </Link>{' '}
            on Corewell's website.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <img
            style={{ height: '47px', paddingRight: '10px' }}
            alt="MiSafeStudent logo"
            src={miSafeStudentLogo}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography>
            Mi SAFE Student is a software system built in collaboration between
            Corewell Health and the Michigan Association of Intermediate School
            Administrators' (MAISA) Michigan Collaboration Hub (MiCH). This
            application supports the logistical and reporting needs of the
            School Blue Envelope program.
          </Typography>
        </Grid>
      </Grid>

      {props.auth.user === null && <Login />}
    </Box>
  )
}

import { CircularProgress, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { useParams } from 'react-router'
import { TrainingForm } from '../../components/training'
import { Training } from '../../core/types'
import { useAuth } from '../../hooks/use-auth'
import { useFetch } from '../../hooks/use-fetch'

export function EditTraining() {
  const { id } = useParams()

  const { response, isLoading } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/Training/Get?id=${id}`
  )
  const training = (response ?? {}) as Training
  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <Typography variant="h4">Edit Training Event</Typography>

      <Box sx={{ width: '100%' }}>
        <TrainingForm mode={'edit'} itemToEdit={training} />
      </Box>
    </>
  )
}

import { Typography } from '@mui/material'
import { TriggerEventForm } from '../../../components/forms/add-edit-triggerevent'

export const AddTriggerEvent = () => {
  return (
    <div>
      <Typography variant="h2">Add Trigger Event</Typography>

      <TriggerEventForm mode="add" />
    </div>
  )
}
import { CircularProgress } from '@mui/material'
import { SelectOption } from '../../components/forms/select-with-label'
import { IncidentExportView } from '../../components/reports/IncidentExportView'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import { Typography } from '@mui/material'

const serverUrl = process.env.REACT_APP_SERVER_URL

export function IncidentExport() {
  const { response: districts, isLoading: districtLoading } = useFetch(
    `${serverUrl}/District/GetLookup`
  )
  var districtOptions = (districts ?? []) as SelectOption<number>[]

  if (districtLoading) {
    return <CircularProgress></CircularProgress>
  } else {
    return <IncidentExportView districts={districtOptions} />
  }
}

import { AppBar, Box, Toolbar, Avatar } from '@mui/material'
import { useNavigate } from 'react-router'
import { usePermissions } from '../../hooks/use-permissions'
import * as AppRoutes from '../../routes'
import { NavigationDropdown } from '../navigation-dropdown'
import { NavigationItem } from '../navigation-dropdown/navigation-item'
import miSafeStudentLogo from '../../assets/MiSafeStudent.png'
import { AuthType } from '../../hooks/use-auth'
import { sendRequest, useFetch } from '../../hooks/use-fetch'
import { NavigationItemProps } from '../navigation-dropdown/shared'
import { useState } from 'react'

export const NavigationBar = (props: { auth: AuthType }) => {
  const permissions = usePermissions()
  const [totalNotifications, setTotalNotifications] = useState(0)
  const [requestCount, setRequestCount] = useState(0)
  if (props.auth.loading) return <></>
  const logout = async () => {
    await props.auth.logout()
  }
  if (props.auth.user !== null) {
    sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/PermissionRequest/HasRequests`
    ).then(async ({ response }) => {
      let x = await response?.json()
      setRequestCount((x ?? 0) as number)
    })
    sendRequest(
      `${process.env.REACT_APP_SERVER_URL}/PermissionRequest/TotalRequestCount`
    ).then(async ({ response }) => {
      let x = await response?.json()
      setTotalNotifications((x ?? 0) as number)
    })
  }
  let navItems: NavigationItemProps[] = []
  if (totalNotifications)
    navItems.push({
      title: 'Permission Requests',
      path: AppRoutes.INCIDENT_PERMISSION_LIST_PATH,
    })
  if (props.auth.isImpersonating())
    navItems.push({
      title: 'End Impersonation',
      onClick: () => {
        props.auth.endImpersonation()
      },
    })

  navItems.push({
    title: 'Logout',
    onClick: logout,
  })

  let reportItems: NavigationItemProps[] = []
  if (permissions?.canViewReports) {
    reportItems.push({
      title: 'District Settings',
      path: AppRoutes.DISTRICT_SETTINGS_REPORT_PATH,
    })
    reportItems.push({
      title: 'District Status',
      path: AppRoutes.DISTRICT_STATUS_REPORT_PATH,
    })
    reportItems.push({
      title: 'Incident Report',
      path: AppRoutes.INCIDENT_AGGREGATE_REPORT_PATH,
    })
    reportItems.push({
      title: 'Incident Export',
      path: AppRoutes.REPORT_INCIDENT_EXPORT_PATH,
    })

    if (permissions?.canViewUserSummaryReport)
      reportItems.push({
        title: 'User Settings',
        path: AppRoutes.USER_SETTINGS_REPORT_PATH,
      })
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        top: 0,
        height: 'auto',
        bottom: 'auto',
        alignItems: 'stretch',
        background: 'white',
      }}
    >
      <Toolbar disableGutters sx={{ paddingRight: '8px' }}>
        <Box
          sx={{
            background: '#f4f7f9',
            borderRight: '1px solid #e1eede',
            padding: '0.5rem 2.5rem 0.25rem 1.5rem',
          }}
        >
          <a href={AppRoutes.LANDING_PATH}>
            <img alt="MiSafeStudent logo" src={miSafeStudentLogo} />
          </a>
        </Box>
        {props.auth.user && !props.auth.user?.inactive && permissions && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                padding: '8px',
              }}
            >
              {permissions?.canViewIncidentManagementAndReports && (
                <NavigationDropdown
                  withCaret
                  title="Incidents"
                  navigationItems={[
                    {
                      title: 'Incident Management',
                      path: AppRoutes.INCIDENT_PATH,
                    },
                    {
                      title: 'Report Incident',
                      path: AppRoutes.ADD_INCIDENT_PATH,
                    },
                  ]}
                />
              )}
              {permissions?.canViewAdminPages && (
                <NavigationItem
                  title="Training"
                  path={AppRoutes.TRAINING_LIST_PATH}
                />
              )}
              {permissions?.canViewDistricts && (
                <NavigationItem
                  title="Districts"
                  path={AppRoutes.DISTRICT_PATH}
                />
              )}
              {permissions?.canViewReports && (
                <NavigationDropdown
                  withCaret
                  title="Reports"
                  navigationItems={reportItems}
                />
              )}

              {permissions?.canViewAdminPages && (
                <NavigationDropdown
                  withCaret
                  title="Admin"
                  navigationItems={[
                    {
                      title: 'Actions Taken by School',
                      path: AppRoutes.ADMIN_ACTIONS_TAKEN_PATH,
                    },
                    {
                      title: 'Initial Responder Roles',
                      path: AppRoutes.ADMIN_RESPONDER_ROLES_PATH,
                    },
                    {
                      title: 'Trigger Events',
                      path: AppRoutes.ADMIN_TRIGGER_EVENT_PATH,
                    },
                    {
                      title: 'User Management',
                      path: AppRoutes.ADMIN_USER_LIST_PATH,
                    },
                  ]}
                />
              )}
            </Box>
            <NavigationDropdown
              withCaret
              title={`Hi, ${props.auth.user?.firstName}`}
              navigationItems={navItems}
              notifications={requestCount ? requestCount : undefined}
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

import CircularProgress from '@mui/material/CircularProgress'
import { booleanRenderer } from '../../components/paged-table/renderers'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../components/paged-table/simple-paged-table'
import { useFetch } from '../../hooks/use-fetch'

type DistrictResultType = {
  id: number
  districtName: string
  integrationEnabled: boolean
  incidentSharing: boolean
  privateIncident: boolean
  enforceAnonymous: boolean
}
type UserColumn = SimpleColumn<DistrictResultType>

const columns: UserColumn[] = [
  {
    field: 'districtName',
    headerName: 'District Name',
  },
  {
    field: 'integrationEnabled',
    headerName: 'Integration Enabled',
    renderField: booleanRenderer,
  },
  {
    field: 'privateIncident',
    headerName: 'Private Incident',
    renderField: booleanRenderer,
  },
  {
    field: 'incidentSharing',
    headerName: 'Incident Sharing',
    renderField: booleanRenderer,
  },
  {
    field: 'enforceAnonymous',
    headerName: 'Enforce Anonymous',
    renderField: booleanRenderer,
  },
]

export function DistrictSettingsReport() {
  let path = `${process.env.REACT_APP_SERVER_URL}/District/Settings`

  const { response, isLoading, error } = useFetch(path)
  if (isLoading) return <CircularProgress />
  const rows = (response ?? []) as DistrictResultType[]
  let i = 1
  const idRows = rows.map((r) => {
    return { ...r, id: i++ }
  })

  return (
    <SimplePagedTable
      columns={columns}
      rows={idRows}
      disableSearch
      enablePrint
    />
  )
}

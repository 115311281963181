export const LANDING_PATH = '/'
export const TERMS_AND_CONDITIONS = '/Home/TermsAndConditions'
export const INCIDENT_PATH = '/Incident'
export const ADD_INCIDENT_PATH = '/Incident/Add'
export const VIEW_INCIDENT_PATH = '/Incident/:id'
export const ADMIN_USER_LIST_PATH = '/AdminUserList'
export const ADMIN_USER_ADD_PATH = '/AdminUserList/New'
export const ADMIN_USER_EDIT_PATH = '/AdminUserEdit/:id'
export const ADMIN_RESPONDER_ROLES_PATH = '/Configuration/InitialResponderRoleList'
export const ADMIN_RESPONDER_ROLES_EDIT = '/Configuration/InitialResponderRoleEdit/:id'
export const ADMIN_RESPONDER_ROLES_ADD = '/Configuration/InitialResponderRoleAdd'
export const ADMIN_ACTIONS_TAKEN_PATH = '/Configuration/ActionTakenList'
export const ADMIN_ACTIONS_TAKEN_EDIT = '/Configuration/ActionTakenEdit/:id'
export const ADMIN_ACTIONS_TAKEN_ADD = '/Configuration/ActionTakenAdd'
export const ADMIN_TRIGGER_EVENT_PATH = '/Configuration/TriggerEventList'
export const ADMIN_TRIGGER_EVENT_EDIT = '/Configuration/TriggerEventEdit/:id'
export const ADMIN_TRIGGER_EVENT_ADD = '/Configuration/TriggerEventAdd'
export const TRIGGER_EVENTS_PATH = '/TriggerEvents'
export const VIEW_TRIGGER_EVENT_PATH = '/TriggerEvents/:id'
export const ACTION_TAKEN_PATH = '/ActionTaken'
export const VIEW_ACTION_TAKEN_PATH = '/ActionTaken/:id'
export const DISTRICT_PATH = '/District'
export const DISTRICT_DETAILS_PATH = '/District/Details/:id'
export const DISTRICT_ADD_PATH = '/District/AddDistrict'
export const DISTRICT_IMPORT_SCHOOL_PATH = '/District/ImportPublic'
export const INCIDENT_DETAILS_PATH = '/Incident/Details/:id'
export const INCIDENT_EDIT_PATH = '/Incident/Edit/:id'
export const DISTRICT_EDIT_USER_PATH = '/District/EditUser/:id'
export const DISTRICT_ADD_DISTRICT_USER_PATH = '/District/AddUser/:id'
export const DISTRICT_ADD_DISTRICT_SCHOOL_PATH = '/District/AddSchool/:id'
export const DISTRICT_EDIT_SCHOOL_PATH = '/District/EditSchool/:id'
export const DISTRICT_ADD_DISTRICT_IMPORT_STAFF_PATH =
  '/District/ImportStaff/:id'
export const DISTRICT_ADD_DISTRICT_ADMIN_PATH = '/District/AddDistrictAdmin/:id'
export const DISTRICT_EDIT_SECURITY_OPTIONS_PATH =
  '/District/EditSecurityOptions/:id'
export const INCIDENT_FOLLOW_UP_ADD_PATH = '/Incident/AddFollowUp/:id'
export const INCIDENT_FOLLOW_UP_EDIT_PATH = '/Incident/EditFollowUp/:id'
export const INCIDENT_PERMISSION_LIST_PATH = '/Incident/PermissionRequests'
export const INCIDENT_PERMISSION_REVIEW_PATH = '/Incident/ReviewRequest/:id'
export const INCIDENT_PERMISSION_REQUEST_PATH =
  '/Incident/RequestPermission/:id'
export const SAFETY_PLAN_EDIT_PATH = '/Incident/EditSafetyPlan/:id' //ID of SAFETY PLAN
export const SAFETY_PLAN_ADD_PATH = '/Incident/AddSafetyPlan/:id' //ID of INCIDENT
export const SAFETY_PLAN_DOCUMENT_EDIT_PATH =
  '/Incident/EditSafetyPlanDocument/:id' //ID of SAFETY PLAN
export const SAFETY_PLAN_DOCUMENT_ADD_PATH =
  '/Incident/AddSafetyPlanDocument/:id' //ID of INCIDENT
export const TRAINING_LIST_PATH = '/Training'
export const TRAINING_ADD_PATH = '/Training/Add'
export const TRAINING_EDIT_PATH = '/Training/Edit/:id'
export const USER_SETTINGS_REPORT_PATH = '/Report/UserSettings'
export const DISTRICT_SETTINGS_REPORT_PATH = '/Report/DistrictSettings'
export const INCIDENT_AGGREGATE_REPORT_PATH = '/Report/IncidentAggregate'
export const REPORT_INCIDENT_EXPORT_PATH = '/Report/IncidentExport'
export const DISTRICT_STATUS_REPORT_PATH = '/Report/DistrictStatus'

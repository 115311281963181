import { Button, Menu, Avatar } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import React from 'react'
import { NavigationItem } from './navigation-item'
import { NavigationItemProps, navPaddingStyle } from './shared'

type Props = {
  title: string
  navigationItems: NavigationItemProps[]
  withCaret?: boolean
  className?: string
  notifications?: number
}
export const NavigationDropdown = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={props.className}>
      <Button
        id="dropdown-button"
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={navPaddingStyle}
      >
        {props.title}
        {props.notifications && props.notifications > 0 && (
          <Avatar
            sx={{ bgcolor: 'blue', width: 18, height: 18, ml: 1 }}
            variant="rounded"
          >
            {props.notifications}
          </Avatar>
        )}
        {props.withCaret && <KeyboardArrowDownIcon />}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {props.navigationItems.map((opt) => (
          <NavigationItem key={opt.title} {...opt} />
        ))}
      </Menu>
    </div>
  )
}

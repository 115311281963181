import { useParams } from 'react-router'
import { SafetyPlanForm } from '../../components/incident/safety-plan/form'
import { FormSafetyPlan } from '../../core/types'
import { useFetch } from '../../hooks/use-fetch'
import { Typography, Box } from '@mui/material'

export function AddSafetyPlan() {
  const { id } = useParams()

  const serverUrl = process.env.REACT_APP_SERVER_URL

  const { response: studentNameResponse } = useFetch(
    `${serverUrl}/Incident/GetStudentName?incidentID=${id}`
  )
  const studentName = (studentNameResponse ?? {}) as { name: string }

  if (id === undefined) return <></>
  let num: number = +id
  const newFormValues: FormSafetyPlan = {
    incidentID: num,
    fileName: 'none',
    dateCompleted: null,
    id: 0,
    status: '',
    askForHelpPerson2: '',
    askForHelpPerson3: '',
    askForHelpPersonPhone1: '',
    askForHelpPerson1: '',
    askForHelpPersonPhone2: '',
    askForHelpPersonPhone3: '',
    clinician1: '',
    clinician2: '',
    clinicianEmergencyNumber1: '',
    clinicianEmergencyNumber2: '',
    clinicianPhone1: '',
    clinicianPhone2: '',
    copingStrategy1: '',
    copingStrategy2: '',
    copingStrategy3: '',
    distractionPerson1: '',
    distractionPerson2: '',
    distractionPersonPhone1: '',
    distractionPersonPhone2: '',
    distractionPlace1: '',
    distractionPlace2: '',
    localUrgentCareServices: '',
    makeEnvironmentSafe1: '',
    makeEnvironmentSafe2: '',
    type: 'Form',
    urgentCareServicesAddress: '',
    urgentCareServicesPhone: '',
    warningSign1: '',
    warningSign2: '',
    warningSign3: '',
    oneThingThatsMostImportant: '',
    referralForOngoingBehavioralServices: '',
  }
  return (
    <Box>
      <Typography variant="h4" fontWeight={'bold'}>
        <>Add Safety Plan - {studentName.name}</>
      </Typography>
      <SafetyPlanForm plan={newFormValues} isEdit={false} />
    </Box>
  )
}

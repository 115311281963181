import { Button, IconButton, Typography, Box } from '@mui/material'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import {
  SimpleColumn,
  SimplePagedTable,
} from '../../../components/paged-table/simple-paged-table'
import { InitialResponderRole } from '../../../core/types'
import { useFetch } from '../../../hooks/use-fetch'
import * as AppRoutes from '../../../routes'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
type RoleColumn = SimpleColumn<InitialResponderRole>

export function InitialResponderRolesList() {
  const serverUrl = process.env.REACT_APP_SERVER_URL

  let { response: resp } = useFetch(`${serverUrl}/InitialResponderRole/GetAll`)
  const plans = (resp ?? []) as InitialResponderRole[]
  plans.sort((a, b) => a.order - b.order)
  const columns: RoleColumn[] = [
    { field: 'name', headerName: 'Name' },
    {
      field: 'closed',
      headerName: 'Closed',

      renderField: (closed: boolean) => (closed ? 'Yes' : 'No'),
    },

    { field: 'order', headerName: 'Order' },
  ]
  const nav = useNavigate()
  const createRole = () => {
    nav(generatePath(AppRoutes.ADMIN_RESPONDER_ROLES_ADD))
  }
  const editRole = (id: number) => {
    nav(
      generatePath(AppRoutes.ADMIN_RESPONDER_ROLES_EDIT, {
        id: id.toString(),
      })
    )
  }

  return (
    <Box>
      <Typography variant="h2">Initial Responder Role List</Typography>
      <Button variant="contained" onClick={createRole}>
        Add
      </Button>

      <SimplePagedTable
        columns={columns}
        rows={plans}
        disableSearch
        rowActionCell={(row) => (
          <EditRoleButton id={row.id} onClick={editRole} />
        )}
      />
    </Box>
  )
}
const EditRoleButton = (props: {
  id: number
  onClick: (id: number) => void
}) => {
  const handleClick = React.useCallback(() => props.onClick(props.id), [props])
  return (
    <IconButton onClick={handleClick}>
      <EditIcon />
    </IconButton>
  )
}
